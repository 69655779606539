/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useRef } from 'react';
import {
  Form, InputNumber, Button, notification,
} from 'antd';
import PropTypes from 'prop-types';
import getRow from './getRow';
import { editApplicationSmeta, getApplicationSmeta } from '../../../../../utils/http';


const fields = ['fot', 'material', 'equipment', 'other', 'paper', 'people'];
const yearList = ['2022', '2023', '2024', '2025', '2026'];
function SmetaModule({ applicationId, isAppDisabled }) {
  const [smeta, setSmeta] = useState({});
  const [disabled, setDisabled] = useState(true);
  const [projectYears, setProjectYears] = useState([]);
  const formRef = useRef();

  useEffect(() => {
    getApplicationSmeta({ id: applicationId })
      .then((result) => {
        console.log(result)
        setSmeta(result);
        let term = result.projectTerm;
        // Сведения об оплатах
        if (result.isGrants2023 || result.projectTerm === 1) {
          term = result.projectTerm;
        } else {
          term = Number(Number(result.projectTerm) - 1).toFixed(0);
        }
        if (['711bc555-58e2-4390-9d3c-c0fe53f9b6c9'].includes(result.applicationId)) {
          term = 3;
        }
        setProjectYears(
          yearList.splice(result.isGrants2023 ? 1 : 0, term),
        );
        formRef.current.setFieldsValue(result);
        setDisabled(isAppDisabled);
      });
  }, []);

  const onSumChange = () => {
    const values = formRef.current.getFieldsValue();
    const objToSave = {
      financeTotalPercent: 100,
    };
    fields.forEach((f) => {
      let sum = 0;
      projectYears.forEach((year) => {
        sum += parseFloat(`${values[`${f}${year}Major`] || 0}.${(`${values[`${f}${year}Minor`]}`).length === 1 ? `0${values[`${f}${year}Minor`]}` : values[`${f}${year}Minor`]}`) * 100;
      });

      objToSave[`${f}TotalMajor`] = Math.trunc(sum / 100) === 0 && sum < 0 ? `-0${Math.trunc(sum / 100)}` : Math.trunc(sum / 100);
      objToSave[`${f}TotalMinor`] = Number((sum % 100).toFixed(0));
    });

    const financeSum = parseFloat(`${objToSave.fotTotalMajor || 0}.${(`${objToSave.fotTotalMinor}`).length === 1 ? `0${objToSave.fotTotalMinor}` : objToSave.fotTotalMinor}`) * 100
    + parseFloat(`${objToSave.materialTotalMajor || 0}.${(`${objToSave.materialTotalMinor}`).length === 1 ? `0${objToSave.materialTotalMinor}` : objToSave.materialTotalMinor}`) * 100
    + parseFloat(`${objToSave.equipmentTotalMajor || 0}.${(`${objToSave.equipmentTotalMinor}`).length === 1 ? `0${objToSave.equipmentTotalMinor}` : objToSave.equipmentTotalMinor}`) * 100
    + parseFloat(`${objToSave.otherTotalMajor || 0}.${(`${objToSave.otherTotalMinor}`).length === 1 ? `0${objToSave.otherTotalMinor}` : objToSave.otherTotalMinor}`) * 100
    + parseFloat(`${objToSave.paperTotalMajor || 0}.${(`${objToSave.paperTotalMinor}`).length === 1 ? `0${objToSave.paperTotalMinor}` : objToSave.paperTotalMinor}`) * 100
    + parseFloat(`${objToSave.peopleTotalMajor || 0}.${(`${objToSave.peopleTotalMinor}`).length === 1 ? `0${objToSave.peopleTotalMinor}` : objToSave.peopleTotalMinor}`) * 100;

    objToSave.financeTotalMajor = Math.trunc(financeSum / 100) === 0 && financeSum < 0 ? `-0${Math.trunc(financeSum / 100)}` : Math.trunc(financeSum / 100);
    objToSave.financeTotalMinor = Number((financeSum % 100).toFixed(0));

    fields.forEach((f) => {
      objToSave[`${f}Percent`] = ((objToSave[`${f}TotalMajor`] / objToSave.financeTotalMajor) * 100).toFixed(0);
    });

    projectYears.forEach((y) => {
      let financeTotalYearSum = 0;
      fields.forEach((f) => {
        financeTotalYearSum += parseFloat(`${values[`${f}${y}Major`] || 0}.${(`${values[`${f}${y}Minor`]}`).length === 1 ? `0${values[`${f}${y}Minor`]}` : values[`${f}${y}Minor`]}`) * 100;
      });
      objToSave[`financeTotal${y}Major`] = Math.trunc(financeTotalYearSum / 100) === 0 && financeTotalYearSum < 0 ? `-0${Math.trunc(financeTotalYearSum / 100)}` : Math.trunc(financeTotalYearSum / 100);
      objToSave[`financeTotal${y}Minor`] = Number((financeTotalYearSum % 100).toFixed(0));
    });

    formRef.current.setFieldsValue(objToSave);
  };

  const onSave = () => {
    setDisabled(true);
    const values = formRef.current.getFieldsValue();
    editApplicationSmeta(values)
      .then(() => {
        setDisabled(false);
        notification.success({ message: 'Успех', description: 'Изменения сохранены' });
      });
  };

  console.log(smeta)

  if (!smeta) {
    return <></>
  }

  return (
    <div className="financeTab-wrapper" style={{ backgroundColor: '#fff', padding: '20px' }}>
      <Form ref={formRef}>
        <Form.Item
          name="id"
          initialValue={smeta?.id}
          hidden
        />

        <div style={{
          display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center',
        }}
        >
          <div className="common-subTitle">6. СМЕТА РАСХОДОВ ПРОЕКТА</div>
        </div>
        <span className="common-field-hint">В колонке «Доля затрат» происходит автоматический расчет того, какой объем (в %) занимает статья расходов в общем объеме финансирования.</span>

        <div className="common-table-wrapper" style={{ marginTop: '20px' }}>
          <div className="common-table-row">
            <div className="common-table-row-cell" style={{ width: '5%', textAlign: 'center', fontWeight: 'bold' }}>№ п/п</div>
            <div className="common-table-row-cell" style={{ width: '25%', textAlign: 'center', fontWeight: 'bold' }}>Наименование статей расходов</div>
            <div
              className="common-table-row-cell"
              style={{
                display: 'flex', flexDirection: 'column', width: '70%', padding: '4px 0px 0px 0px', borderRight: '1px solid #d9d9d9',
              }}
            >
              <div
                className="common-table-row-cell"
                style={{
                  width: '100%', textAlign: 'center', border: 'none', fontWeight: 'bold',
                }}
              >
                Средства финансирования
              </div>
              <div className="common-table-row">
                <div
                  className="common-table-row-cell"
                  style={{
                    width: '30%', textAlign: 'center', borderLeft: '0', fontWeight: 'bold',
                  }}
                >
                  Всего
                </div>
                {projectYears.map((e) => (
                  <div className="common-table-row-cell" style={{ width: `${60 / projectYears.length}%`, textAlign: 'center', fontWeight: 'bold' }} key={e}>{e}</div>
                ))}
                <div className="common-table-row-cell" style={{ width: '10%', textAlign: 'center', fontWeight: 'bold' }}>Доля затрат</div>
              </div>
            </div>
          </div>

          {new Array(5).fill(0).map((e, i) => getRow(i + 1, smeta, disabled, onSumChange, projectYears))}

          <div className="common-table-row" style={{ borderBottom: '1px solid #d9d9d9' }}>
            <div className="common-table-row-cell" style={{ width: '5%', textAlign: 'center' }} />
            <div className="common-table-row-cell" style={{ width: '25%', textAlign: 'right', fontWeight: 'bold' }}>ИТОГО</div>
            <div className="common-table-row" style={{ width: '70%' }}>
              <div
                className="common-table-row-cell"
                style={{
                  width: '30%', textAlign: 'center', display: 'flex', flexDirection: 'row', alignItems: 'center',
                }}
              >
                <Form.Item
                  style={{ width: '55%' }}
                  name="financeTotalMajor"
                  initialValue={smeta.financeTotalMajor}
                >
                  <InputNumber disabled />
                </Form.Item>
                <span>руб.</span>
                <Form.Item
                  style={{ width: '25%' }}
                  name="financeTotalMinor"
                  initialValue={smeta.financeTotalMinor}
                >
                  <InputNumber disabled />
                </Form.Item>
                <span>коп.</span>
              </div>
              {projectYears.map((e) => (
                <div
                  key={e}
                  className="common-table-row-cell"
                  style={{
                    width: `${60 / projectYears.length}%`, textAlign: 'center', display: 'flex', flexDirection: 'row', alignItems: 'center',
                  }}
                >
                  <Form.Item
                    style={{ width: '55%' }}
                    name={`financeTotal${e}Major`}
                    initialValue={smeta[`financeTotal${e}Major`]}
                  >
                    <InputNumber disabled />
                  </Form.Item>
                  <span>руб.</span>
                  <Form.Item
                    style={{ width: '25%' }}
                    name={`financeTotal${e}Minor`}
                    initialValue={smeta[`financeTotal${e}Minor`]}
                  >
                    <InputNumber disabled />
                  </Form.Item>
                  <span>коп.</span>
                </div>
              ))}
            </div>
          </div>
        </div>

      </Form>
      <Button type="primary" onClick={onSave}>Сохранить</Button>
    </div>
  );
}

SmetaModule.propTypes = {
  applicationId: PropTypes.string.isRequired,
  isAppDisabled: PropTypes.bool.isRequired,
};

export default SmetaModule;

const isEmpty = (value) => {
  if (value === null || value === undefined || value === '') {
    return true;
  }
  return false;
};


export const approveValidationAgreement = (values, application) => {
  let isError = false;
  const tabs = [
    { name: 'info', label: 'Краткие сведения о Грантополучателе для генерации Соглашения на предоставление гранта:', errorFields: [] },
    { name: 'base', label: 'Основания для заключения Соглашения с Грантополучателем:', errorFields: [] },
    {
      name: 'bank', label: 'Банковские реквизиты Грантополучателя', errorFields: [],
    },
    { name: 'plan', label: 'План-график Грантополучателя:', errorFields: [] },
    { name: 'tz', label: 'Техническое задание Грантополучателя на выполнение работ в рамках проекта:', errorFields: [] },
    { name: 'pep', label: 'Планируемые показатели эффективности проекта:', errorFields: [] },
    { name: 'persons', label: 'Техническое задание на выполнение научно-исследовательских работ (НИР):', errorFields: [] },
  ];

  // eslint-disable-next-line no-restricted-syntax
  for (const tab of tabs) {
    if (tab.name === 'info') {
      if (isEmpty(values.osnovanie)) {
        isError = true;
        tab.errorFields.push('Укажите документ на право подписи уполномоченного лица со стороны Грантополучателя');
      }

      if (values.dolznost_rod === application.directorPosition) {
        isError = true;
        tab.errorFields.push('Должность руководителя должна быть в родительном падеже');
      }

      if (values.fullOrgName === application.organisationFullName) {
        isError = true;
        tab.errorFields.push('Полное наименование организации должно быть в предложном падеже');
      }

      if (values.fio_rod === application.directorFullName) {
        isError = true;
        tab.errorFields.push('ФИО руководителя должно быть в родительном падеже');
      }
    }

    if (tab.name === 'plan') {
      if (!isEmpty(values.etap1_comment)) {
        isError = true;
        tab.errorFields.push('Обратите внимание на комментарий в первом этапе');
      }

      if (!isEmpty(values.etap2_comment)) {
        isError = true;
        tab.errorFields.push('Обратите внимание на комментарий во втором этапе');
      }

      if (!isEmpty(values.etap3_comment)) {
        isError = true;
        tab.errorFields.push('Обратите внимание на комментарий в третьем этапе');
      }
    }

    if (tab.name === 'base') {
      if (isEmpty(values.protokol_date)) {
        isError = true;
        tab.errorFields.push('Укажите дату протокола СГВ');
      }

      if (isEmpty(values.prikaz_no)) {
        isError = true;
        tab.errorFields.push('Укажите номер приказа Медтеха');
      }

      if (isEmpty(values.prikaz_date)) {
        isError = true;
        tab.errorFields.push('Укажите дату приказа Медтеха');
      }
    }

    if (tab.name === 'bank') {
      if (isEmpty(values.ogrn)) {
        isError = true;
        tab.errorFields.push('Укажите ОГРН');
      }
      if (isEmpty(values.inn)) {
        isError = true;
        tab.errorFields.push('Укажите ИНН');
      }
      if (isEmpty(values.kpp)) {
        isError = true;
        tab.errorFields.push('Укажите КПП');
      }
      if (isEmpty(values.ur_address)) {
        isError = true;
        tab.errorFields.push('Укажите юридический адрес');
      }
      if (isEmpty(values.poluchatel)) {
        isError = true;
        tab.errorFields.push('Укажите получателя');
      }
      if (isEmpty(values.bank)) {
        isError = true;
        tab.errorFields.push('Укажите название банка');
      }
      if (isEmpty(values.ks)) {
        isError = true;
        tab.errorFields.push('Укажите корреспондентский счет');
      }
      if (isEmpty(values.bik)) {
        isError = true;
        tab.errorFields.push('Укажите БИК');
      }
      if (isEmpty(values.rs)) {
        isError = true;
        tab.errorFields.push('Укажите расчетный счет');
      }
    }

    if (tab.name === 'tz') {
      if (isEmpty(values.tz_total_target)) {
        isError = true;
        tab.errorFields.push('Укажите цель, которая должна быть достигнута по завершении реализации Проекта');
      }

      if (!isEmpty(values.tz_total_target_comment)) {
        isError = true;
        tab.errorFields.push('Обратите внимание на комментарий к пункту "цель, которая должна быть достигнута по завершении реализации Проекта"');
      }

      if (isEmpty(values.tz_total_works)) {
        isError = true;
        tab.errorFields.push('Укажите подробное описание работ, подлежащих выполнению, в рамках реализации Проекта');
      }

      if (!isEmpty(values.tz_total_works_comment)) {
        isError = true;
        tab.errorFields.push('Обратите внимание на комментарий к пункту "Укажите подробное описание работ, подлежащих выполнению, в рамках реализации Проекта"');
      }

      if (isEmpty(values.tz_total_trebovania_1)) {
        isError = true;
        tab.errorFields.push('Укажите требования (в том числе технические) к работам, выполняемым в рамках реализации Проекта');
      }

      if (!isEmpty(values.tz_total_trebovania_1_comment)) {
        isError = true;
        tab.errorFields.push('Обратите внимание на комментарий к пункту "Укажите требования (в том числе технические) к работам, выполняемым в рамках реализации Проекта"');
      }

      if (isEmpty(values.tz_total_perechen_1)) {
        isError = true;
        tab.errorFields.push('Укажите перечень результатов работ, которые должны быть получены по завершении реализации Проекта');
      }

      if (!isEmpty(values.tz_total_perechen_1_comment)) {
        isError = true;
        tab.errorFields.push('Обратите внимание на комментарий к пункту "Укажите перечень результатов работ, которые должны быть получены по завершении реализации Проекта"');
      }

      if (isEmpty(values.tz_total_trebovania_2)) {
        isError = true;
        tab.errorFields.push('Укажите требования к результатам работ по Проекту');
      }

      if (!isEmpty(values.tz_total_trebovania_2_comment)) {
        isError = true;
        tab.errorFields.push('Обратите внимание на комментарий к пункту "Укажите требования к результатам работ по Проекту"');
      }

      if (isEmpty(values.tz_total_perechen_2)) {
        isError = true;
        tab.errorFields.push('Укажите перечень разрабатываемых документов');
      }

      if (!isEmpty(values.tz_total_perechen_2_comment)) {
        isError = true;
        tab.errorFields.push('Обратите внимание на комментарий к пункту "Укажите перечень разрабатываемых документов"');
      }

      if (isEmpty(values.tz_total_trebovania_3)) {
        isError = true;
        tab.errorFields.push('Укажите требования к разрабатываемой документации');
      }

      if (!isEmpty(values.tz_total_trebovania_3_comment)) {
        isError = true;
        tab.errorFields.push('Обратите внимание на комментарий к пункту "Укажите требования к разрабатываемой документации"');
      }
    }

    if (tab.name === 'pep') {
      const total = parseInt(application?.numberOfPublicationsTotal || '0', 10);
      const levelVAC1_2 = parseInt(values?.numberOfPublicationsLevelVAC1_2 || '0', 10);
      const levelRINC3_4 = parseInt(values?.numberOfPublicationsRINCLevel3_4 || '0', 10);

      if (levelRINC3_4 + levelVAC1_2 !== total) {
        isError = true;
        tab.errorFields.push('Сумма п.1.2 и п.1.1 должна быть равна п.1');
      }
    }

    if (tab.name === 'persons') {
      if (application.applications2PersonsTabPeople_ids.length > 0) {
        let isPersonsError = false;
        application.applications2PersonsTabPeople_ids.forEach((person, index) => {

          for (let i = 0; i < 6; i += 1) {
            if (!isEmpty(values.meri_podderjki?.[index]?.[`step${i + 1}Comment`])) {
              isPersonsError = true;
              tab.errorFields.push(`${person.fullName} - обратите внимание на комментарий у этапа ${i + 1} в таблице "Наименование работ, выполняемых в рамках этапа"`);
            }
          }

          if (isEmpty(values.meri_podderjki?.[index].tz_target)) {
            isPersonsError = true;
            tab.errorFields.push(`${person.fullName} - Укажите цель выполнения работ`);
          }

          if (!isEmpty(values.meri_podderjki?.[index].tz_target_comment)) {
            isPersonsError = true;
            tab.errorFields.push(`${person.fullName} - обратите внимание на комментарий к пункту "Укажите цель выполнения работ"`);
          }

          if (isEmpty(values.meri_podderjki?.[index].tz_work_requirements)) {
            isPersonsError = true;
            tab.errorFields.push(`${person.fullName} - Укажите требования к выполняемым работам`);
          }

          if (!isEmpty(values.meri_podderjki?.[index].tz_work_requirements_comment)) {
            isPersonsError = true;
            tab.errorFields.push(`${person.fullName} - обратите внимание на комментарий к пункту "Укажите требования к выполняемым работам"`);
          }

          if (isEmpty(values.meri_podderjki?.[index].tz_technical_requirements)) {
            isPersonsError = true;
            tab.errorFields.push(`${person.fullName} - укажите технические требования к научно-техническим результатам работ`);
          }

          if (!isEmpty(values.meri_podderjki?.[index].tz_technical_requirements_comment)) {
            isPersonsError = true;
            tab.errorFields.push(`${person.fullName} - обратите внимание на комментарий к пункту "укажите технические требования к научно-техническим результатам работ"`)
          }

          if (isEmpty(values.meri_podderjki?.[index].tz_documents_requirements)) {
            isPersonsError = true;
            tab.errorFields.push(`${person.fullName} - укажите требования к разрабатываемой документации`);
          }

          if (!isEmpty(values.meri_podderjki?.[index].tz_documents_requirements_comment)) {
            isPersonsError = true;
            tab.errorFields.push(`${person.fullName} - обратите внимание на комментарий к пункту "укажите требования к разрабатываемой документации"`);
          }

          if (isEmpty(values.meri_podderjki?.[index].tz_stages)) {
            isPersonsError = true;
            tab.errorFields.push(`${person.fullName} - укажите этапы работ и сроки их выполнения`);
          }

          if (!isEmpty(values.meri_podderjki?.[index].tz_stages_comment)) {
            isPersonsError = true;
            tab.errorFields.push(`${person.fullName} - обратите внимание на комментарий к пункту "укажите этапы работ и сроки их выполнения"`);
          }
        });

        if (isPersonsError) {
          isError = true;
        }
      }
    }
  }

  return { isError, tabs };
};

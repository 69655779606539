/* eslint-disable react/destructuring-assignment */
import React from 'react';
import {
  Form, Input, Select, InputNumber,
} from 'antd';
import PropTypes, { func } from 'prop-types';
import getUserRole from '../../../../utils/localeStorage/getUserRole';


const { TextArea } = Input;

function parseJsonResponse(key, json) {
  if (!json || typeof json !== 'string') return null;

  const data = JSON.parse(json);
  return data?.[key];
}

const ProjectInfoPerson = ({ item, index }) => {
  const steps = [
    item.isStep1,
    item.isStep2,
    item.isStep3,
    item.isStep4,
    item.isStep5,
    item.isStep6,
  ];

  const realisationIndex = (i) => {
    switch (i) {
      case 1:
      case 2:
        return 1;
      case 3:
      case 4:
        return 2;
      case 5:
      case 6:
        return 3;
      default:
        return null;
    }
  };


  return (
    <div key={item.fullName}>
      <Form.Item
        hidden
        name={['meri_podderjki', index, 'id']}
        initialValue={item.id}
      >
        <Input disabled />
      </Form.Item>
      <div className="common-sub-title" style={{ fontSize: '20px' }}>
        {item.fullName}
      </div>

      <div
        className="common-sub-title"
        style={{ fontSize: '18px', marginBottom: '20px' }}
      >
        <b>Наименование работ, выполняемых в рамках этапа</b>
      </div>

      <div className="common-table-wrapper">
        <div className="common-table-row">
          <div
            className="common-table-row-cell"
            style={{ width: '10%', textAlign: 'center', fontWeight: 'bold' }}
          >
            Номер этапа выполнения работ
          </div>
          <div
            className="common-table-row-cell"
            style={{ width: '10%', textAlign: 'center', fontWeight: 'bold' }}
          >
            Номер этапа реализации проекта
          </div>
          <div
            className="common-table-row-cell"
            style={{ width: '40%', textAlign: 'center', fontWeight: 'bold' }}
          >
            Наименование работ, выполняемых в рамках этапа
          </div>
          <div
            className="common-table-row-cell"
            style={{
              width: '10%',
              textAlign: 'center',
              fontWeight: 'bold',
              borderRight: '1px solid #d9d9d9',
            }}
          >
            Объем финансирования, выполняемых в рамках этапа работ (в рублях)
          </div>
          <div
            className="common-table-row-cell"
            style={{
              width: '30%',
              textAlign: 'center',
              fontWeight: 'bold',
              borderRight: '1px solid #d9d9d9',
            }}
          >
            Комментарий
          </div>
        </div>

        {steps.map((step, i) => (step ? (
          <div className="common-table-row">
            <Form.Item style={{ width: '10%' }} initialValue={i + 1}>
              <Input disabled value={i + 1} />
            </Form.Item>

            <Form.Item
              style={{ width: '10%' }}
              initialValue={realisationIndex(i + 1)}
            >
              <Input disabled value={realisationIndex(i + 1)} />
            </Form.Item>

            <Form.Item
              style={{ width: '40%' }}
              name={['meri_podderjki', index, `step${i + 1}Work`]}
              initialValue={parseJsonResponse(`step${i + 1}Work`, item.meri_podderjki) || item[`step${i + 1}Work`]}
            >
              <TextArea
                placeholder="Введите текст"
                autoSize
              />
            </Form.Item>

            <Form.Item
              style={{ width: '10%' }}
              initialValue={item[`step${i + 1}Sum`]}
            >
              <InputNumber
                formatter={(value) => (parseInt(value, 10) || 0).toLocaleString('ru-RU')}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                disabled
                value={item[`step${i + 1}Sum`]}
              />
            </Form.Item>

            <Form.Item
              style={{ width: '30%' }}
              name={['meri_podderjki', index, `step${i + 1}Comment`]}
              initialValue={parseJsonResponse(`step${i + 1}Comment`, item.meri_podderjki)}
            >
              <TextArea
                style={{ color: 'red' }}
                disabled={getUserRole() !== 'bigAdmin'}
                placeholder="Комментарий"
                autoSize
              />
            </Form.Item>
          </div>
        ) : null))}

        <div className="common-table-row" style={{ borderBottom: '2px solid #d9d9d9' }}>
          <div
            className="common-table-row-cell"
            style={{
              fontWeight: 'bold', width: '90%', paddingRight: 15, borderRight: '1px solid #d9d9d9', textAlign: 'right',
            }}
          >
            Итого
          </div>
          <Form.Item
            initialValue={item.stepTotal}
            style={{ width: '10%' }}
          >
            <InputNumber
              formatter={(value) => (parseInt(value, 10) || 0).toLocaleString('ru-RU')}
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              disabled
              value={item.stepTotal}
              style={{ width: '100%' }}
            />
          </Form.Item>
        </div>
      </div>

      <div
        className="common-sub-title"
        style={{ fontSize: '18px', marginBottom: '20px' }}
      >
        <b>
          Техническое задание на выполнение научно-исследовательских работ (НИР)
          и/или научно-исследовательских, опытно-конструкторских и
          технологических работ (НИОКР)
        </b>
      </div>
      <div className="common-table-wrapper">
        <div className="common-table-row">
          <div
            className="common-table-row-cell"
            style={{ borderRight: '1px solid #d9d9d9' }}
          >
            Цель выполнения работ
          </div>
          <Form.Item
            style={{ width: '50%' }}
            name={['meri_podderjki', index, 'tz_target']}
            initialValue={parseJsonResponse('tz_target', item.meri_podderjki)}
          >
            <TextArea autoSize />
          </Form.Item>
          <Form.Item
            style={{ width: '50%' }}
            name={['meri_podderjki', index, 'tz_target_сomment']}
            initialValue={parseJsonResponse('tz_target_сomment', item.meri_podderjki)}
          >
            <TextArea
              style={{ color: 'red' }}
              disabled={getUserRole() !== 'bigAdmin'}
              placeholder="Комментарий администратора"
              autoSize
            />
          </Form.Item>
        </div>

        <div className="common-table-row">
          <div
            className="common-table-row-cell"
            style={{ borderRight: '1px solid #d9d9d9' }}
          >
            Перечень результатов, подлежащих получению по факту завершения
            выполнения работ
          </div>
          <Form.Item
            style={{ width: '50%' }}
            name={['meri_podderjki', index, 'tz_results']}
            initialValue={parseJsonResponse('tz_results', item.meri_podderjki)}
          >
            <TextArea autoSize />
          </Form.Item>
          <Form.Item
            style={{ width: '50%' }}
            name={['meri_podderjki', index, 'tz_results_сomment']}
            initialValue={parseJsonResponse('tz_results_сomment', item.meri_podderjki)}
          >
            <TextArea
              style={{ color: 'red' }}
              disabled={getUserRole() !== 'bigAdmin'}
              placeholder="Комментарий администратора"
              autoSize
            />
          </Form.Item>
        </div>
        <div className="common-table-row">
          <div
            className="common-table-row-cell"
            style={{ borderRight: '1px solid #d9d9d9' }}
          >
            Требования к выполняемым работам
          </div>
          <Form.Item
            style={{ width: '50%' }}
            name={['meri_podderjki', index, 'tz_work_requirements']}
            initialValue={parseJsonResponse('tz_work_requirements', item.meri_podderjki)}
          >
            <TextArea autoSize />
          </Form.Item>
          <Form.Item
            style={{ width: '50%' }}
            name={['meri_podderjki', index, 'tz_work_requirements_сomment']}
            initialValue={parseJsonResponse('tz_work_requirements_сomment', item.meri_podderjki)}
          >
            <TextArea
              style={{ color: 'red' }}
              disabled={getUserRole() !== 'bigAdmin'}
              placeholder="Комментарий администратора"
              autoSize
            />
          </Form.Item>
        </div>
        <div className="common-table-row">
          <div
            className="common-table-row-cell"
            style={{ borderRight: '1px solid #d9d9d9' }}
          >
            Технические требования к научно-техническим результатам работ
          </div>
          <Form.Item
            style={{ width: '50%' }}
            name={['meri_podderjki', index, 'tz_technical_requirements']}
            initialValue={parseJsonResponse('tz_technical_requirements', item.meri_podderjki)}
          >
            <TextArea autoSize />
          </Form.Item>
          <Form.Item
            style={{ width: '50%' }}
            name={['meri_podderjki', index, 'tz_technical_requirements_сomment']}
            initialValue={parseJsonResponse('tz_technical_requirements_сomment', item.meri_podderjki)}
          >
            <TextArea
              style={{ color: 'red' }}
              disabled={getUserRole() !== 'bigAdmin'}
              placeholder="Комментарий администратора"
              autoSize
            />
          </Form.Item>
        </div>
        <div className="common-table-row">
          <div
            className="common-table-row-cell"
            style={{ borderRight: '1px solid #d9d9d9' }}
          >
            Требования к разрабатываемой документации
          </div>
          <Form.Item
            style={{ width: '50%' }}
            name={['meri_podderjki', index, 'tz_documents_requirements']}
            initialValue={parseJsonResponse('tz_documents_requirements', item.meri_podderjki)}
          >
            <TextArea autoSize />
          </Form.Item>
          <Form.Item
            style={{ width: '50%' }}
            name={['meri_podderjki', index, 'tz_documents_requirements_сomment']}
            initialValue={parseJsonResponse('tz_documents_requirements_сomment', item.meri_podderjki)}
          >
            <TextArea
              style={{ color: 'red' }}
              disabled={getUserRole() !== 'bigAdmin'}
              placeholder="Комментарий администратора"
              autoSize
            />
          </Form.Item>
        </div>
        <div className="common-table-row">
          <div
            className="common-table-row-cell"
            style={{ borderBottom: '1px solid #d9d9d9' }}
          >
            Этапы работ и сроки их выполнения
          </div>
          <Form.Item
            style={{ width: '50%' }}
            name={['meri_podderjki', index, 'tz_stages']}
            initialValue={parseJsonResponse('tz_stages', item.meri_podderjki)}
          >
            <TextArea autoSize />
          </Form.Item>
          <Form.Item
            style={{ width: '50%' }}
            name={['meri_podderjki', index, 'tz_stages_сomment']}
            initialValue={parseJsonResponse('tz_stages_сomment', item.meri_podderjki)}
          >
            <TextArea
              style={{ color: 'red' }}
              disabled={getUserRole() !== 'bigAdmin'}
              placeholder="Комментарий администратора"
              autoSize
            />
          </Form.Item>
        </div>
      </div>
    </div>
  );
};

export default ProjectInfoPerson;

ProjectInfoPerson.propTypes = {
  item: PropTypes.shape().isRequired,
  index: PropTypes.number.isRequired,
  // updateOriginalObject: PropTypes.func.isRequired,
};

/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import {
  Tag, Button, Col, Row,
} from 'antd';
// import moment from 'moment';
import getUserRole from '../../../../../utils/localeStorage/getUserRole';
import { routeGenerator } from '../../../../../utils/routes';
import customAppNumbers from './customAppNumbers';


const getButtonName = () => {
  const name = 'Скачать';
  return name;
};

const getApplicationsColumns = (history, isShowPersonsName, isExpert) => {
  if (getUserRole() === 'user') {
    return [
      {
        title: '№ заявки',
        dataIndex: 'displayID',
        key: 'displayID',
        render: (_, object) => (
          <Row justify="center">
            <a onClick={() => window.open(routeGenerator.applicationLink(object.id), '_blank')}>{object.displayID}</a>
          </Row>
        ),
        width: '9%',
      },
      {
        title: 'Статус заявки',
        dataIndex: 'status',
        key: 'status',
        render: (textInput, object) => {
          let color = 'gold';
          let text = 'Без статуса';
          if (object.isPersonApplication) {
            return (
              <Row justify="center">
                <Tag color={color}>Соисполнитель</Tag>
              </Row>
            );
          }
          switch (textInput) {
            case 'Черновик':
              color = 'purple';
              text = 'Черновик';
              break;
            case 'Согласован':
              color = 'green';
              text = 'Согласован';
              break;
            case 'Согласован 2 Этап':
              color = 'green';
              text = 'Согласован 2 Этап';
              break;
            case 'Отклонен':
              color = 'red';
              text = 'Отклонен';
              break;
            case 'На рассмотрении':
              color = 'blue';
              text = 'На рассмотрении';
              break;
            case 'На рассмотрении. Этап 2':
              color = 'blue';
              text = 'На рассмотрении. Этап 2';
              break;
            case 'На доработке':
              color = 'purple';
              text = 'На доработке';
              break;
            case 'Поддержана':
              color = 'green';
              text = 'Поддержана';
              break;
            case 'Не поддержана':
              color = 'red';
              text = 'Не поддержана';
              break;
            case 'Расторгнут':
              color = 'red';
              text = 'Расторгнут';
              break;
            case 'Завершен':
              color = 'green';
              text = 'Завершен';
              break;
            default:
              color = 'gold';
              text = 'Без статуса';
          }
          return (
            <Row justify="center">
              <Tag color={color}>{text}</Tag>
            </Row>
          );
        },
        width: '15%',
      },
      {
        title: 'Тема',
        dataIndex: 'theme',
        key: 'theme',
        width: '30%',
        render: (_, object) => (
          <Row justify="center">
            <a onClick={() => window.open(routeGenerator.applicationLink(object.id), '_blank')}>{object.theme}</a>
          </Row>
        ),
      },
      {
        title: 'Грантовое соглашение',
        dataIndex: '',
        key: '',
        render: (text, object) => {
          if (object.status === 'Поддержана' || object.isPersonApplication) {
            if (object.grantAgreementScanLink) {
              return (
                <Row justify="center">
                  <Button type="link" onClick={() => window.open(object.grantAgreementScanLink)}>{getButtonName(object.grantAppStatus)}</Button>
                </Row>
              );
            }
            return '';
            // return (
            //   <Row justify="center">
            //     <Button type="primary" onClick={() => onApplicationSelect(object)}>
            //       {getButtonName(object.grantAppStatus)}
            //     </Button>
            //   </Row>
            // );
          }
          return '';
        },
      },
      {
        title: 'Аннотированный отчет',
        dataIndex: 'annotation',
        key: 'annotation',
        render: (_, object) => {
          let stepNumber = '0'; // 0 это 1
          if (object.isGrants2023) {
            if (object.displayID.includes('С')) {
              stepNumber = '0';
            } else {
              stepNumber = '0';
            }
          } else if (object.displayID.includes('С')) {
            stepNumber = '2';
          } else if (object.displayID.includes('НИР')) {
            stepNumber = '3';
          } else {
            stepNumber = '2';
          }

          if (object.status === 'Поддержана' || object.isPersonApplication) {
            if (!object.isPersonApplication) {
              if (['2312-44', '2312-29', '2212-12', '2312-32', '1912-1', '0403-1', '2708-1'].includes(object.displayID)) {
                return (
                  <Row justify="center">
                    <Button
                      type="primary"
                      style={{ backgroundColor: '#52c41a', borderColor: '#52c41a' }}
                      onClick={() => history.push({
                        pathname: routeGenerator.applicationLink(object.id),
                        state: { isAnnotationReport: true, stepNumber },
                      })}
                    >
                      Подать 1
                    </Button>
                  </Row>
                );
              }
              return <div />;
            }
            const step = object.applicationStepReport_ids.find((a) => a.stepNumber === '1');
            if (step && step.annotationReportPDF && step.annotationStatus === 'Согласован') {
              if (step.annotationReportPDFSigned) {
                return (
                  <Button onClick={() => window.open(step.annotationReportPDFSigned)} type="link">
                    Скачать
                  </Button>
                );
              }
              return (
                <Button onClick={() => window.open(step.annotationReportPDF)} type="link">
                  Скачать
                </Button>
              );
            }
            return (
              <Row justify="center">
                <Button
                  type="primary"
                  style={{ backgroundColor: '#52c41a', borderColor: '#52c41a' }}
                  onClick={() => history.push({
                    pathname: routeGenerator.applicationLink(object.id),
                    state: { isAnnotationReport: true, stepNumber },
                  })}
                >
                  Подать
                </Button>
              </Row>
            );
          }
          return '';
        },
      },
      {
        title: 'Финансовый отчет',
        dataIndex: 'finance',
        key: 'finance',
        render: (_, object) => {
          let stepNumber = '1'; // 0 это 1, а 1 это 2. Почему? Хуй знает
          if (object.isGrants2023) {
            if (object.displayID.includes('С')) {
              stepNumber = '1';
            } else {
              stepNumber = '1';
            }
          } else if (object.displayID.includes('С')) {
            stepNumber = '3';
          } else if (object.displayID.includes('НИР')) {
            stepNumber = '4';
          } else {
            stepNumber = '3';
          }

          if (object.status === 'Поддержана' || object.isPersonApplication) {
            return (
              <Row justify="center">
                <Button
                  type="primary"
                  style={{ backgroundColor: '#52c41a', borderColor: '#52c41a' }}
                  onClick={() => history.push({
                    pathname: routeGenerator.applicationLink(object.id),
                    state: { isFinancialReport: true, stepNumber },
                  })}
                >
                  Подать
                </Button>
              </Row>
            );
          }
          return '';
        },
      },
      {
        title: 'Акт сдачи-приемки',
        dataIndex: 'actReport',
        key: 'actReport',
        render: (_, object) => {
          const actReport = object.applicationStepReport_ids.find((a) => a.stepNumber === '2')?.actReport_id?.urlLink;
          if (actReport) {
            return (
              <Col>
                <Button onClick={() => window.open(actReport)} type="link">
                  Скачать
                </Button>
              </Col>
            );
          }
          return '';
        },
      },
    ];
  }
  if (getUserRole() === 'noDSM') {
    return [
      {
        title: '№ заявки',
        dataIndex: 'displayID',
        key: 'displayID',
        render: (_, object) => (
          <Col>
            <Row justify="center">
              <a onClick={() => window.open(routeGenerator.applicationLink(object.id), '_blank')}>{object.displayID}</a>
            </Row>
            <Row justify="center">
              <Tag color="gold">Соисполнитель</Tag>
            </Row>
          </Col>
        ),
        width: '10%',
      },
      {
        title: 'Тема',
        dataIndex: 'theme',
        key: 'theme',
        render: (_, object) => (
          <Row justify="center">
            <a onClick={() => window.open(routeGenerator.applicationLink(object.id), '_blank')}>{object.theme}</a>
          </Row>
        ),
      },
      {
        title: 'Договор на НИР',
        dataIndex: '',
        key: 'nir',
        render: (_, object) => (object.nirFile_id ? (
          <Row justify="center">
            <Button onClick={() => window.open(object.nirFile_id.urlLink)} shape="round">
              Скачать
            </Button>
          </Row>
        ) : ''),

      },
      {
        title: 'Общая стоимость работ по Договору',
        dataIndex: 'allStepsSum',
        key: 'allStepsSum',
        render: (text) => (
          <Row justify="center">
            {text}
          </Row>
        ),
      },
      {
        title: 'Доведено средств на текущий момент',
        dataIndex: 'currentTransferedSum',
        key: 'currentTransferedSum',
        render: (text) => (
          <Row justify="center">
            {text}
          </Row>
        ),
      },
    ];
  }
  if (getUserRole() === 'expertFederal' || getUserRole() === 'expertDZM' || isExpert) {
    const columns = [
      {
        title: '№',
        dataIndex: 'displayID',
        key: 'displayID',
        render: (_, object) => (
          <a onClick={() => window.open(routeGenerator.projectLink(object.id), '_blank')}>{object.displayID}</a>
        ),
        width: '12%',
      },
      {
        title: 'Тема',
        dataIndex: 'theme',
        key: 'theme',
        width: '35%',
        render: (_, object) => (
          <a onClick={() => window.open(routeGenerator.projectLink(object.id), '_blank')}>{object.theme}</a>
        ),
      },
      {
        title: 'Руководитель проекта',
        dataIndex: 'directorFirstName',
        key: 'directorFirstName',
        width: '20%',
      },
      {
        title: 'Направление',
        dataIndex: 'scientificDirection_id',
        key: 'scientificDirection_id',
        width: '15%',
      },
      {
        title: 'Организация',
        dataIndex: 'webUserShortName',
        key: 'webUserShortName',
        width: '15%',
      },
    ];
    if (isExpert) {
      columns.push({
        title: 'Эксперт',
        dataIndex: 'expert',
        key: 'expert',
        width: '15%',
      });
      columns.push({
        title: 'СОПД',
        dataIndex: 'sopd',
        key: 'sopd',
        render: (_, object) => (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div>{object.sopdStatus}</div>
            <div>{object.sopdDate}</div>
          </div>
        ),
        width: '10%',
      });
    }
    return columns;
  }
  return [
    {
      title: '№',
      dataIndex: 'displayID',
      key: 'displayID',
      render: (_, object) => {
        let tag = <Tag color="gold">Без статуса</Tag>;
        if (object.isPersonApplication) {
          return (
            <Col>
              <Row justify="center">
                <a onClick={() => window.open(routeGenerator.applicationLink(object.id), '_blank')}>{object.displayID}</a>
              </Row>
              <Row justify="center">
                <Tag color="gold">Соисполнитель</Tag>
              </Row>
            </Col>
          );
        }
        switch (object.status) {
          case 'Черновик':
            tag = <Tag color="purple">Черновик</Tag>;
            break;
          case 'Согласован':
            tag = <Tag color="green">Согласован</Tag>;
            break;
          case 'Согласован 2 Этап':
            tag = <Tag color="green">Согласован 2 Этап</Tag>;
            break;
          case 'Отклонен':
            tag = <Tag color="red">Отклонен</Tag>;
            break;
          case 'На рассмотрении':
            tag = <Tag color="blue">На рассмотрении</Tag>;
            break;
          case 'На рассмотрении. Этап 2':
            tag = <Tag color="blue">На рассмотрении. Этап 2</Tag>;
            break;
          case 'На доработке':
            tag = <Tag color="purple">На доработке</Tag>;
            break;
          case 'Поддержана':
            tag = <Tag color="green">Поддержана</Tag>;
            break;
          case 'Не поддержана':
            tag = <Tag color="red">Не поддержана</Tag>;
            break;
          case 'Расторгнут':
            tag = <Tag color="red">Расторгнут</Tag>;
            break;
          case 'Завершен':
            tag = <Tag color="green">Завершен</Tag>;
            break;
          default: break;
        }
        return (
          <Col>
            <Row justify="center">
              <a onClick={() => window.open(routeGenerator.applicationLink(object.id), '_blank')}>{object.displayID}</a>
            </Row>
            <Row justify="center">
              {tag}
            </Row>
          </Col>
        );
      },
      width: '12%',
    },
    {
      title: 'Тема',
      dataIndex: 'theme',
      key: 'theme',
      width: '25%',
      render: (_, object) => (
        <Row justify="center">
          <a onClick={() => window.open(routeGenerator.applicationLink(object.id), '_blank')}>{object.theme}</a>
        </Row>
      ),
    },
    {
      title: 'Организация',
      dataIndex: 'webUserShortName',
      key: 'webUserShortName',
      width: '20%',
      render: (text) => {
        const number = customAppNumbers.find((e) => e.name === text)?.number;
        if (number) {
          return `${text} (том ${number})`;
        }
        return text;
      },
    },
    {
      title: 'Сумма',
      dataIndex: 'projectFinance',
      key: 'projectFinance',
      render: (text) => `${text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}  ₽`,
    },
    {
      title: 'Соглашение',
      dataIndex: 'grantAppStatus',
      key: 'grantAppStatus',
      render: (text, object) => (isShowPersonsName && object.grantAgreementScanLink
        ? <a href={object.grantAgreementScanLink} target="_blank" rel="noreferrer">скачать</a>
        : (object.status === 'Поддержана' && text === '' ? 'Не загружено' : text)),
    },
    {
      title: 'Соисполнители',
      dataIndex: 'agreementPersonsStatus',
      key: 'agreementPersonsStatus',
      render: (text, object) => (isShowPersonsName ? <ul>{object.persons.map((p) => <li>{p.name}</li>)}</ul>
        : (text === 'На заполнении' ? '' : text)),
    },
  ];
};

export default getApplicationsColumns;

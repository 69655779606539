/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Col, Tabs, Row, Button, Popconfirm, notification, Modal, Input,
} from 'antd';
import SalaryPayments from './Salary/SalaryPayments';
import Materials from './Materials/Materials';
import Total from './Total/Total';
import getUserRole from '../../../../../../utils/localeStorage/getUserRole';
import { editApplicationsFinancialReportTotal, editApplicationStepReport, getApplicationStepReportById } from '../../../../../../utils/http';
import { approveValidationFinancial } from '../../../../../../utils/approveValidationFinancial';


const { TabPane } = Tabs;
const { TextArea } = Input;
class FinancialReport extends Component {
  state = {
    view: '1',
    financialReportComment: '',
    isShowDeclineFinancialReportModal: false,
    financialReportStatus: this.props.application.financialReportStatus,
    financialReportPosition: this.props.application.financialReportPosition,
    financialReportFIO: this.props.application.financialReportFIO,
    financialTotalSmeta: [],
  }


  // componentDidMount() {
  //   const prevFios = this.props.prevStepApplications?.[this.props.prevStepApplications.length - 1]?.financialSalary_ids.map((fio) => fio.fio).sort();
  //   const currentFios = this.props.application.financialSalary_ids.map((fio) => fio.fio).sort();

  //   if (JSON.stringify(prevFios) === JSON.stringify(currentFios)) {
  //     this.setState({ financialReportStatus: 'Согласовано' });
  //   }
  // }

  onSmetaChange = (smeta) => {
    this.setState({ financialTotalSmeta: smeta });
  }

  onSendForApproval = () => {
    const { application, prevStepApplications } = this.props;

    getApplicationStepReportById(application.id)
      .then((app) => {
        const {
          financialReportPosition, financialReportFIO, financialSalary_ids, financialTotalSmeta,
        } = this.state;
        const { isError, tabs } = approveValidationFinancial(app, financialTotalSmeta);

        if (isError) {
          notification.error({
            message: 'Ошибка завершения заполнения. Заполните обязательные поля и исправьте ошибки!',
            style: { width: 1000 },
            duration: 100,
            description:
  <div style={{
    display: 'flex', flexDirection: 'column', overflowY: 'scroll', height: '80vh',
  }}
  >
    {tabs.map((tab) => {
      if (tab.errorFields.length > 0) {
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ fontWeight: 'bold', fontSize: '14px' }}>{tab.label}</div>
            <ul>
              {tab.errorFields.map((f) => <li key={f}>{f}</li>)}
            </ul>
          </div>
        );
      } return null;
    })}
  </div>,
          });
          return false;
        }

        editApplicationStepReport({
          id: application.id,
          financialReportStatus: 'На согласовании',
          financialReportPosition,
          financialReportFIO,
        })
          .then(() => {
            this.setState({ financialReportStatus: 'На согласовании' });
            notification.success({ message: 'Статус изменен!' });
            notification.warning({ duration: 10, message: 'Уважаемые грантополучатели! Отчет направлен на проверку, после ее завершения мы направим вам Акт сдачи-приемки выполненных работ по этапу с комментарием по дальнейшим действиям' });
          });

        return true;
      });
  }


  onChangeTextFiled = (fieldName, fieldValue) => {
    this.setState({ [fieldName]: fieldValue });
  }

  onChangeFinancialReportStatus = (financialReportStatus) => {
    const { application } = this.props;
    const { financialReportComment } = this.state;
    editApplicationStepReport({ id: application.id, financialReportStatus, financialReportComment })
      .then(() => {
        this.setState({
          isShowDeclineFinancialReportModal: false,
          financialReportComment: '',
        });
        notification.success({ message: 'Успешно изменен статус' });
      });
  }


  onTotalsSave = async () => {
    const { application } = this.props;
    const { financialReportPosition, financialReportFIO, financialTotalSmeta } = this.state;

    try {
      // eslint-disable-next-line no-restricted-syntax
      for await (const row of financialTotalSmeta) {
        const total = {
          applicationStepReport_id: application.id,
          type: row.type === 'fot' ? 'salary' : row.type,
          comment: row.comment,
          grantSumMajor: row.grantSumMajor,
          grantSumMinor: row.grantSumMinor,
          money2022Major: row.money2022Major,
          money2022Minor: row.money2022Minor,
          moneyLeftPeriodStartMajor: row.moneyLeftPeriodStartMajor,
          moneyLeftPeriodStartMinor: row.moneyLeftPeriodStartMinor,
        };

        editApplicationsFinancialReportTotal(total);
      }

      await editApplicationStepReport({
        id: application.id,
        financialReportPosition,
        financialReportFIO,
      });

      notification.success({ message: 'Сохранено' });
    } catch {
      notification.error({ message: 'Произошла ошибка. Попробуйте еще раз' });
    }
  }

  render() {
    const {
      view, financialReportStatus, isShowDeclineFinancialReportModal, financialReportPosition, financialReportFIO,
    } = this.state;
    const { application, prevStepApplications, step } = this.props;
    let isFormDisabled = !((application.isGrants2023 && step === 1)
      || (!application.isGrants2023 && step === 3) || (application.id === '1c19bdf2-7e5d-4199-b88b-42870627443e')) || financialReportStatus === 'Согласовано';

    if (getUserRole() === 'bigAdmin') {
      isFormDisabled = false;
    }
    if (application.id === '48f8606f-199b-454f-a9f3-d664c29b49c5'
      || application.id === 'dfe6db6e-733a-45d7-a9fb-edd4a9303c20'
      || application.id === 'e8ec3266-15d8-4999-b27e-1633abea18e4'
      || application.id === '953bc804-a3ce-4904-8842-fb9c76d7cbad') {
      isFormDisabled = false;
    }

    if (application.isAllowFinanceEdit) {
      isFormDisabled = false;
    }

    // Костыль! Удалить позже
    isFormDisabled = false;

    console.log(prevStepApplications);

    // || application?.status === 'Завершен'
    // || application?.status === 'Расторгнут';
    // const isFormDisabled = application.displayID !== '1409-1' && application.displayID !== '2412-49';
    return (
      <Col>
        <Tabs defaultActiveKey={view} activeKey={view} onChange={(e) => this.setState({ view: e })}>
          <TabPane tab="Оплата труда членов коллектива специалистов" key="1">
            <SalaryPayments
              financialIsSalaryDone={application.financialIsSalaryDone}
              applicationId={application.id}
              prevStep={prevStepApplications?.[prevStepApplications.length - 1]}
              dataSource={application.financialSalary_ids}
              financialSalaryFiles={application.financialSalaryFiles}
              financialSalaryPPFiles={application.financialSalaryPPFiles}
              isFormDisabled={isFormDisabled}
              isEnpSalaryForm={application.isEnpSalaryForm || application.financialSalary_ids.length === 0}
            />
            <Row justify="end" style={{ marginTop: '20px' }} gutter={10}>
              {/* <Popconfirm
                  title='Просим обратить внимание, нажимая "Продолжить" Вы подтверждаете, что указанные члены коллектива специалистов соответствуют приложенному Приказу о создании коллектива специалистов в рамках реализации Проекта. Примечание: Оплату труда вспомогательного персонала, задействованного в сопровождении Проекта (бухгалтерия, экономическая служба, юридический отдел и проч.) необходимо указать в разделе "Накладные и общехозяйственный расходы"'
                  onConfirm={() => this.setState({ view: '2' })}
                  okText="Да, все верно"
                  cancelText="Назад"
                >
                  <Button type="primary" >Продолжить</Button>
              </Popconfirm> */}
              <Col><Button type="primary" onClick={() => notification.success({ message: 'Сохранено' })}>Сохранить</Button></Col>
              <Button type="primary" onClick={() => this.setState({ view: '2' })}>Продолжить</Button>
            </Row>
          </TabPane>
          <TabPane tab="Расходы на приобретение материалов и комплектующих" key="2">
            <Materials
              applicationId={application.id}
              isMaterial
              title="Расходы на приобретение материалов и комплектующих не проводились в отчетном периоде"
              dataSource={application.financialCosts_ids.filter((c) => c.isMaterial)}
              checkboxName="financialIsMaterialDone"
              checkboxValue={application.financialIsMaterialDone}
              isFormDisabled={isFormDisabled}
            />
            <Row justify="end" style={{ marginTop: '20px' }} gutter={10}>
              <Col><Button type="primary" onClick={() => notification.success({ message: 'Сохранено' })}>Сохранить</Button></Col>
              <Col><Button onClick={() => this.setState({ view: '1' })}>Назад</Button></Col>
              <Col><Button type="primary" onClick={() => this.setState({ view: '3' })}>Продолжить</Button></Col>
            </Row>
          </TabPane>
          <TabPane tab="Расходы на приобретение оборудования и иного имущества" key="3">
            <Materials
              applicationId={application.id}
              isEquipment
              title="Расходы на приобретение оборудования и иного имущества не проводилась в отчетном периоде"
              dataSource={application.financialCosts_ids.filter((c) => c.isEquipment)}
              checkboxName="financialIsEquipmentDone"
              checkboxValue={application.financialIsEquipmentDone}
              isFormDisabled={isFormDisabled}
            />
            <Row justify="end" style={{ marginTop: '20px' }} gutter={10}>
              <Col><Button type="primary" onClick={() => notification.success({ message: 'Сохранено' })}>Сохранить</Button></Col>
              <Col><Button onClick={() => this.setState({ view: '2' })}>Назад</Button></Col>
              <Col><Button type="primary" onClick={() => this.setState({ view: '4' })}>Продолжить</Button></Col>
            </Row>
          </TabPane>
          <TabPane tab="Прочие прямые расходы" key="4">
            <Materials
              applicationId={application.id}
              isOther
              title="Расходы по статье «Прочие прямые расходы» не проводились в отчетном периоде"
              dataSource={application.financialCosts_ids.filter((c) => c.isOther)}
              checkboxName="financialIsOtherDone"
              checkboxValue={application.financialIsOtherDone}
              isFormDisabled={isFormDisabled}
            />
            <Row justify="end" style={{ marginTop: '20px' }} gutter={10}>
              <Col><Button type="primary" onClick={() => notification.success({ message: 'Сохранено' })}>Сохранить</Button></Col>
              <Col><Button onClick={() => this.setState({ view: '3' })}>Назад</Button></Col>
              <Col><Button type="primary" onClick={() => this.setState({ view: '5' })}>Продолжить</Button></Col>
            </Row>
          </TabPane>
          <TabPane tab="Накладные и общехозяйственные расходы" key="5">
            <Materials
              applicationId={application.id}
              isPaper
              title="Расходы по статье «Накладные и общехозяйственные расходы» не проводились в отчетном периоде"
              dataSource={application.financialCosts_ids.filter((c) => c.isPaper)}
              checkboxName="financialIsPaperDone"
              checkboxValue={application.financialIsPaperDone}
              isFormDisabled={isFormDisabled}
              financialPaperPolicyFile={application.financialPaperPolicyFile}
              financialPaperAccountFile={application.financialPaperAccountFile}
            />
            <Row justify="end" style={{ marginTop: '20px' }} gutter={10}>
              <Col><Button type="primary" onClick={() => notification.success({ message: 'Сохранено' })}>Сохранить</Button></Col>
              <Col><Button onClick={() => this.setState({ view: '4' })}>Назад</Button></Col>
              <Col><Button type="primary" onClick={() => this.setState({ view: '6' })}>Продолжить</Button></Col>
            </Row>

          </TabPane>
          <TabPane tab="Сведения о фактических расходах по Проекту" key="6">
            {view === '6' && (
              <Total
                is2023={application.isGrants2023}
                applicationId={application.id}
                isFormDisabled={isFormDisabled}
                financialReportPosition={financialReportPosition}
                financialReportFIO={financialReportFIO}
                onChangeTextFiled={this.onChangeTextFiled}
                onSmetaChange={this.onSmetaChange}
                prevStepApplications={prevStepApplications}
              />
            )}
            <Row justify="end" style={{ marginTop: '20px' }} gutter={10}>
              <Col><Button type="primary" onClick={this.onTotalsSave}>Сохранить</Button></Col>
              <Col><Button onClick={() => this.setState({ view: '5' })}>Назад</Button></Col>
              {financialReportStatus !== 'На согласовании' && financialReportStatus !== 'Согласовано' && !isFormDisabled && (
                <Col>
                  <Popconfirm
                    title="Уверены, что хотите подать отчет?"
                    onConfirm={this.onSendForApproval}
                    okText="Да"
                    cancelText="Нет"
                  >
                    <Button type="primary" style={{ backgroundColor: '#52c41a', borderColor: '#52c41a' }}> Подать отчет</Button>
                  </Popconfirm>
                </Col>
              )}
              {getUserRole() === 'bigAdmin' && application.financialReportStatus === 'На согласовании' && (
                <Row gutter={10}>
                  <Col><Button onClick={() => this.setState({ isShowDeclineFinancialReportModal: true })}>Отклонить отчет</Button></Col>
                  <Col><Button type="primary" onClick={() => this.onChangeFinancialReportStatus('Согласовано')}>Согласовать отчет</Button></Col>
                </Row>
              )}
            </Row>
          </TabPane>
        </Tabs>
        {isShowDeclineFinancialReportModal && (
          <Modal
            title="Отклонение"
            visible
            onCancel={() => this.setState({ isShowDeclineFinancialReportModal: false, financialReportComment: '' })}
            footer={null}
          >
            <Col>
              <Row>Комментарий:</Row>
              <Row>
                <TextArea rows={3} style={{ width: '100%' }} onChange={(e) => this.setState({ financialReportComment: e.target.value })} />
              </Row>
              <Row style={{ marginTop: '20px' }}>
                <Popconfirm
                  title="Вы действительно хотите Отклонить"
                  onConfirm={() => this.onChangeFinancialReportStatus('Отклонено')}
                  okText="Да, отклонить"
                  cancelText="Нет, продолжить редактирование"
                >
                  <Button danger>Отклонить</Button>
                </Popconfirm>
              </Row>
            </Col>
          </Modal>
        )}
      </Col>
    );
  }
}

FinancialReport.propTypes = {
  application: PropTypes.shape().isRequired,
  prevStepApplications: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  step: PropTypes.number.isRequired,
};


export default FinancialReport;

/* eslint-disable no-nested-ternary */
import React from 'react';
import { Form, Input, InputNumber } from 'antd';


const years = ['2023', '2024', '2025'];
const { TextArea } = Input;
const getPlanRow = (index, applicationProp, disabled, total) => (
  <div className="common-table-row">
    <div className="common-table-row-cell" style={{ width: '4%', textAlign: 'center' }}>{index}</div>
    <div className="common-table-row-cell" style={{ width: '16%', textAlign: 'center', fontWeight: 'bold' }}>{`Этап ${index}`}</div>
    <Form.Item
      style={{ width: '16%' }}
      name={`step${index}PlanWorkDescription`}
      initialValue={applicationProp[`step${index}PlanWorkDescription`]}
    >
      <TextArea autoSize disabled={disabled} />
    </Form.Item>
    <div className="common-table-row-cell" style={{ width: '16%', textAlign: 'center' }}>
      {index === 1
        ? 'С даты заключения Соглашения до 20.12.2025' : index === 2
          ? 'С даты подписания Сторонами Акта сдачи-приемки выполненных работ по 1 этапу Соглашения до 20.12.2026' : 'С даты подписания Сторонами Акта сдачи-приемки выполненных работ по 1 этапу Соглашения до 20.12.2027'}
    </div>
    <Form.Item
      style={{ width: '16%' }}
      name={`step${index}PlanWorkResult`}
      initialValue={applicationProp[`step${index}PlanWorkResult`]}
    >
      <TextArea autoSize disabled={disabled} />
    </Form.Item>
    <div className="common-table-row-cell" style={{ width: '16%', textAlign: 'center' }}>
      {total === index ? 'Аннотированный отчет, Финансовый отчет' : 'Финансовый отчет'}
    </div>
    <div
      className="common-table-row-cell"
      style={{
        width: '16%', textAlign: 'center', display: 'flex', flexDirection: 'row', alignItems: 'center',
      }}
    >
      <Form.Item
        style={{ width: '55%' }}
        name={`financeTotal${years[index - 1]}Major`}
        initialValue={applicationProp[`financeTotal${years[index - 1]}Major`]}
      >
        <InputNumber disabled />
      </Form.Item>
      <span>руб.</span>
      <Form.Item
        style={{ width: '25%' }}
        name={`financeTotal${years[index - 1]}Minor`}
        initialValue={applicationProp[`financeTotal${years[index - 1]}Minor`]}
      >
        <InputNumber disabled />
      </Form.Item>
      <span>коп.</span>
    </div>
  </div>
);

export default getPlanRow;

/* eslint-disable prefer-destructuring */
import React, { useState, useEffect } from 'react';
import {
  Input, Table, Button, Row, Col, Select,
} from 'antd';
import md5 from 'md5';
import { groupBy } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import {
  getUsersAdmin, editUser, getAllApplications, editApplication,
} from '../../../../../../utils/http';
import getUsersColumns from './getUsersColumns';
import EditUserDrawer from './EditUserDrawer/EditUserDrawer';
import getUserRole from '../../../../../../utils/localeStorage/getUserRole';
import './Users.less';
import exportUsers from './exportUsers';
import usersRoles from './usersRoles';


const { Search } = Input;
function Users() {
  const [isLoading, setIsLoading] = useState(true);
  const [allUsers, setAllUsers] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [applications, setApplications] = useState([]);
  const [selectedRole, setSelectedRole] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  const onGetData = (text, role, apps) => {
    setIsLoading(true);
    const searchObj = {
      searchText: text,
      role,
    };
    getUsersAdmin({ pageSize: '60', ...searchObj })
      .then((u) => {
        const groupedApplicationsByUser = groupBy(apps, 'webUser_id');
        const dataSource = u.map((user) => ({
          ...user,
          applications2022: groupedApplicationsByUser[user.id]?.filter((app) => !app.isGrants2023) || [],
          applications2023: groupedApplicationsByUser[user.id]?.filter((app) => app.isGrants2023) || [],
        }));
        setAllUsers(dataSource);
        setUsers(dataSource);
        setIsLoading(false);
      });
  };
  useEffect(async () => {
    const appAll = await getAllApplications({ pageSize: 1, status: 'Поддержана' });
    const tableSize = appAll?.pageInfo?.tableSize || 30;
    const apps = await getAllApplications({ pageSize: tableSize, status: 'Поддержана' });
    setApplications(apps.data);
    onGetData(searchValue, selectedRole, apps.data);
  }, []);

  const onSearch = (value) => {
    setSearchValue(value);
    onGetData(value, selectedRole, applications);
  };
  const onFilter = (value) => {
    setSelectedRole(value || []);
    onGetData(searchValue, value || [], applications);
  };
  const onUserSelect = (user) => setSelectedUser(user);

  const onSave = async (values) => {
    // setIsLoading(true);
    let isNewUser = false;
    const userToSave = {
      ...values,
      password: md5(values.passwordView),
      isOrg: true,
      nameInSigniture: `${values.lastName} ${values.firstName?.charAt(0)?.toUpperCase()}. ${values.middleName?.charAt(0)?.toUpperCase()}.`,
    };
    delete userToSave.applications2023;
    delete userToSave.applications2022;
    if (!userToSave.id) {
      userToSave.id = uuidv4();
      isNewUser = true;
    }

    const applicationsToSave = [];
    values?.applications2022?.forEach((app) => {
      applicationsToSave.push({ ...applications.find((a) => a.id === app), webUser_id: userToSave.id });
    });
    values?.applications2023?.forEach((app) => {
      applicationsToSave.push({ ...applications.find((a) => a.id === app), webUser_id: userToSave.id });
    });

    [...selectedUser.applications2023 ? selectedUser.applications2023 : [],
      ...selectedUser.applications2022 ? selectedUser.applications2022 : []].forEach((app) => {
      if (!applicationsToSave.map((a) => a.id).includes(app.id)) {
        applicationsToSave.push({ ...app, webUser_id: null });
      }
    });
    await editUser(userToSave);
    if (applicationsToSave.length > 0) {
      await editApplication(applicationsToSave.map((app) => ({ id: app.id, webUser_id: app.webUser_id })));
    }
    const updatedUser = {
      ...userToSave,
      applications2023: applicationsToSave.filter((app) => app.webUser_id && !app.isGrants2023),
      applications2022: applicationsToSave.filter((app) => app.webUser_id && app.isGrants2023),
    };
    const updatedUsers = isNewUser
      ? [updatedUser, ...allUsers]
      : allUsers.map((user) => (user.id === updatedUser.id ? updatedUser : user));
    setSelectedUser(null);
    setIsLoading(false);
    setSearchValue('');
    setSelectedRole([]);
    setAllUsers(updatedUsers);
    setUsers(updatedUsers);
  };
  const onClose = () => setSelectedUser(null);

  const onExportUsers = () => exportUsers(users);

  return (
    <>
      <div className="users-wrapper">
        <div
          className="users-box-wrapper"
        >
          <Row justify="space-between" style={{ margin: '0px 0px 10px', height: 'auto' }}>
            <Row gutter={12}>
              <Col>
                <Search
                  placeholder="Введите название..."
                  style={{ width: 500 }}
                  onSearch={onSearch}
                  disabled={isLoading}
                />
              </Col>
              <Col>
                <Select
                  allowClear
                  style={{ width: 200 }}
                  value={selectedRole}
                  options={usersRoles}
                  onChange={onFilter}
                  placeholder="Выберите роль..."
                  disabled={isLoading}
                />
              </Col>
            </Row>
            <Col>
              <Row>
                {getUserRole() === 'bigAdmin' && (
                  <Button type="primary" onClick={() => onUserSelect({})} disabled={isLoading}>
                    Добавить пользователя
                  </Button>
                )}
                {getUserRole() === 'bigAdmin' && (
                  <Button onClick={onExportUsers} disabled={isLoading} style={{ marginLeft: '10px' }}>
                    Экспорт
                  </Button>
                )}
              </Row>
            </Col>
          </Row>
          <Table
            dataSource={users}
            columns={getUsersColumns(onUserSelect)}
            loading={isLoading}
            scroll={{ y: '63vh' }}
            pagination={false}
            rowKey="id"
          />
        </div>
        {selectedUser && (
          <EditUserDrawer
            selectedUser={selectedUser}
            onClose={onClose}
            onSave={onSave}
            allApplications={applications}
          />
        )}
      </div>
    </>
  );
}

export default Users;


/* eslint-disable prefer-template */
import applicationFields from '../pages/ApplicationStep2/Common/applicationFields';
import fieldsWithSums from '../pages/ApplicationStep2/Common/FinanceTab/fieldsWithSums';


const isEmpty = (value) => {
  if (value === null || value === undefined || value === '') {
    return true;
  }
  return false;
};

const isNotChecked = (value) => {
  if (!value || isEmpty(value)) {
    return true;
  }

  return false;
};

const salaryFieldLabel = (key) => {
  const labels = [
    {
      label: 'Всего начислено',
      key: 'totalPaidMajor',
    },
    {
      label:
        'в т.ч. НДФЛ',
      key: 'ndflMajor',
    },
    {
      label:
        'ПФР',
      key: 'pfrMajor',
    },
    {
      label: 'ФОМС',
      key: 'fomsMajor',
    },
    {
      label: 'ФСС',
      key: 'fssMajor',
    },
    {
      label: 'ФСС НС',
      key: 'fssnsMajor',
    },
    {
      label: 'ИТОГО по сотруднику',
      key: 'totalPaidMajor',
    },
    {
      label: 'Кассовый расход',
      key: 'actualPaidMajor',
    },
    {
      label: 'Остаток к уплате',
      key: 'remainMajor',
    },
  ];

  return labels.find((el) => el.key === key)?.label;
};


export const approveValidationFinancial = (application, financialTotalSmeta) => {
  let isError = false;

  const tabs = [
    { name: 'salary', label: 'Оплата труда членов коллектива специалистов', errorFields: [] },
    { name: 'materials', label: 'Расходы на приобретение материалов и комплектующих', errorFields: [] },
    {
      name: 'equipment', label: 'Расходы на приобретение оборудования и иного имущества', errorFields: [], isInner: true,
    },
    { name: 'other', label: 'Прочие прямые расходы', errorFields: [] },
    { name: 'paper', label: 'Накладные и общехозяйственные расходы', errorFields: [] },
    { name: 'total', label: 'Сведения о фактических расходах по Проекту', errorFields: [] },
  ];

  tabs.forEach((tab) => {
    if (tab.name === 'salary') {
      // Не отмечен чекбокс "Оплата труда членов коллектива специалистов не проводилась в отчетном периоде" и таблица "Оплата труда членов коллектива специалистов" не заполнено
      if (isNotChecked(application.financialIsSalaryDone) && !application.financialSalary_ids.length) {
        isError = true;
        tab.errorFields.push('Необходимо внести информацию хотя бы по одному из членов коллектива специалиста или отметить чек-бокс "Оплата труда членов коллектива специалистов не проводилась"');
      }

      if (!application.financialSalaryFiles.length) {
        isError = true;
        tab.errorFields.push('Необходимо загрузить файл внутреннего приказа о создании коллектива специалистов в рамках реализации Проекта');
      }

      if (application.financialSalary_ids.length && isNotChecked(application.financialIsSalaryDone) && !application.financialSalaryPPFiles?.length) {
        isError = true;
        tab.errorFields.push('Не загружен файл платежных поручений по выплате заработной платы');
      }

      Object.entries(application.financialSalary_ids).forEach(([key, value], index) => {
        if (key.includes('Major') && !value) {
          const label = salaryFieldLabel(key);
          isError = true;
          tab.errorFields.push(`Не заполнено поле: ${label} у члена коллектива ${application.financialSalary_ids[index].fio}`);
        }
      });
    }

    if (tab.name === 'materials') {
      const materials = application.financialCosts_ids.filter((el) => el.isMaterial);

      if (!materials.length && isNotChecked(application.financialIsMaterialDone)) {
        isError = true;
        tab.errorFields.push('Необходимо внести информацию в таблицу "Расходы на приобретение материалов и комплектующих" или отметить чек-бокс "Расходы на приобретение материалов и комплектующих не проводилась"');
      }
    }

    if (tab.name === 'equipment') {
      const equipment = application.financialCosts_ids.filter((el) => el.isEquipment);
      if (!equipment.length && isNotChecked(application.financialIsEquipmentDone)) {
        isError = true;
        tab.errorFields.push('Необходимо внести информацию в таблицу "Расходы на приобретение оборудования и иного имущества" или отметить чек-бокс "Расходы на приобретение оборудования и иного имущества не проводилась"');
      }
    }

    if (tab.name === 'other') {
      const other = application.financialCosts_ids.filter((el) => el.isOther);
      if (!other.length && isNotChecked(application.financialIsOtherDone)) {
        isError = true;
        tab.errorFields.push('Необходимо внести информацию в таблицу "Прочие прямые расходы" или отметить чек-бокс "Расходы по статье «Прочие прямые расходы» не проводились"');
      }
    }

    if (tab.name === 'paper') {
      const paper = application.financialCosts_ids.filter((el) => el.isPaper);
      if (!paper.length && isNotChecked(application.financialIsPaperDone)) {
        isError = true;
        tab.errorFields.push('Необходимо внести информацию в таблицу "Накладные и общехозяйственные расходы" или отметить чек-бокс "Расходы по статье «Накладные и общехозяйственные расходы» не проводились"');
      }

      if (paper.length > 0) {
        if (isEmpty(application.financialPaperPolicyFile)) {
          isError = true;
          tab.errorFields.push('Необходимо загрузить выписку из учетной политики медицинского учреждения об утвержденном методе распределения указанных расходов между видами деятельности');
        }

        if (isEmpty(application.financialPaperAccountFile)) {
          isError = true;
          tab.errorFields.push('Необходимо загрузить справку бухгалтера о распределении накладных и общехозяйственных расходов между источниками финансирования за отчетный период');
        }
      }
    }

    if (tab.name === 'total') {
      if (isNotChecked(application.isFinancialTotalChecked)) {
        isError = true;
        tab.errorFields.push('Чекбокс "Подтверждаю, что сводная таблица финансовых расходов по проекту заполнена и проверена" обязательно должен быть отмечен');
      }

      if (isEmpty(application.financialReportFIO)) {
        isError = true;
        tab.errorFields.push('Необходимо заполнить поле "ФИО работника организации, уполномоченного на представление отчета о целевом использовании средств гранта"');
      }

      if (isEmpty(application.financialReportPosition)) {
        isError = true;
        tab.errorFields.push('Необходимо заполнить поле "Должность работника организации, уполномоченного на представление отчета о целевом использовании средств гранта"');
      }

      financialTotalSmeta.forEach((el) => {
        if (el.moneyLeftPeriodEndMajor < 0) {
          isError = true;
          tab.errorFields.push(`Остаток на конец отчетного периода в поле ${el.name} не может быть отрицательным`);
        }

        if (el.moneyLeftPeriodEndMajor > 0 && isEmpty(el.comment)) {
          isError = true;
          tab.errorFields.push(`Остаток на конец отчетного периода в поле ${el.name} положительный и отсутствует комментарий`);
        }
      });
    }
  });

  return { isError, tabs };
};

import { planFactFields } from '../Other/planFactFields';


export const additionalFiles = [
  { name: 'Презентация проекта', field: 'presentation_id' },
  { name: 'Итоговый аннотированный отчет', field: 'finalAnnotation_id' },
  { name: 'План-график реализации проекта', field: 'additionalFile1_id' },
  { name: 'Смета расходов на реализацию проекта', field: 'additionalFile3_id' },
  { name: 'Меры поддержки соисполнителей', field: 'additionalFile6_id' },
];

export const checkBoxOptions = [
  { field: 'isValueWorld', name: 'Результаты Проекта имеют высокую практическую значимость и находятся на мировом уровне' },
  { field: 'isValueRussia', name: 'Результаты Проекта значимы для развития данной области медицины и решения прикладных задач на уровне системы здравоохранения Российской Федерации' },
  { field: 'isValueMoscow', name: 'Результаты Проекта имеют важное значение для развития данной медицинской области и решения конкретных практических задач на уровне системы здравоохранениям города Москвы и Московской области' },
  { field: 'isValueMunicipal', name: 'Результаты Проекта имеют потенциал решения прикладных медицинских задач на муниципальном уровне: могут быть внедрены в практическую деятельность одной или нескольких столичных больниц, медицинских центров, поликлиник, научно-исследовательских институтов, лабораторий' },
  { field: 'isValuePractical', name: 'Результаты Проекта имеют потенциал решения прикладных задач для: некоммерческих организаций здравоохранения / частных медицинских учреждений / медицинских образовательных учреждений / медицинских ассоциаций и профессиональных сообществ / фармацевтических компаний / производителей медицинского оборудования / фондов, поддерживающие здравоохранение / медицинских страховых компаний и др.' },
  { field: 'isNoValue', name: 'Результаты Проекта не представляют практической ценности ' },
  { field: 'isValueOther', name: 'Другое' },
];

export const radioOptions = [
  { value: '5 - отлично' },
  { value: '4 - хорошо' },
  { value: '3 - средне' },
  { value: '2 - ниже среднего' },
  { value: '1 - плохо' },
];


export const simpleFields = [
//   {
//   field: 'projectGoal',
//   label: '1.1. Цель Проекта',
// },
// {
//   field: 'projectTasks',
//   label: '1.2. Задачи Проекта',
// },
  {
    field: 'relevance',
    label: '3.1. Актуальность результатов Проекта',
    min: 700,
  },
  {
    field: 'novelty',
    label: '3.2. Научная новизна и значимость полученных результатов Проекта для системы здравоохранения',
    min: 700,
  },
  {
    field: null,
    label: '3.3. Потенциал практического применения результатов Проекта в сфере здравоохранения и медицины',
    fieldArray: checkBoxOptions,
    linkedField: 'isValueOtherText',
    linkedLabel: 'Другое',
  },
  {
    field: 'isValueComment',
    label: 'Аргументация выбранного(-ых) варианта(-ов) практического(-их) потенциала(-ов) внедрения результатов Проекта',
    min: 500,
  },
  {
    field: 'influence',
    label: '3.4. Потенциальное влияние Проекта на существующую клиническую практику в городе Москве и/или в других городах Российской Федерации',
    min: 500,
  },
  // {
  //   field: 'effectiveness',
  //   label: '3.5. Потенциальная медико-экономическая эффективность Проекта',
  //   min: 1,
  // },
  {
    field: 'actions',
    label: '3.5. Действия, объективно необходимые для внедрения / масштабирования внедрения результатов Проекта в практическую деятельность со стороны грантополучателя и/или Департамента здравоохранения города Москвы',
    min: 100,
  },
  // {
  //   field: 'infrastructure',
  //   label: '2.7. Инфраструктурная база, рекомендуемая для масштабирования Проекта',
  //   min: 100,
  // },
  // {
  //   field: 'accordance',
  //   label: '3.1. Соответствие достижения заявленных в Соглашении результатов и показателей эффективности Проекта',
  //   min: 1000,
  // },
  // {
  //   field: 'goalAchieved',
  //   label: '3.2. Была ли в ходе работ достигнута цель Проекта?',
  // },
  // {
  //   field: 'evaluation',
  //   label: '3.3. Оценка выполнения Проекта и достижения целей: критерии и выводы',
  //   min: 2000,
  // },
  {
    field: 'organisations',
    label: '3.6. Предполагаемые организации для осуществления внедрения и масштабирования результатов реализации Проекта',
    min: 100,
  },
  {
    field: 'planInfluence',
    label: '1.2. Оценка влияния возникших в ходе реализации проекта различных объективных обстоятельств на выполнение плана-графика работ по Соглашению.',
    min: 100,
  },
  {
    field: 'planReasonDescription',
    label: 'Обоснование оценки',
    min: 500,
  },
  {
    field: 'planFactEvaluationDescription',
    label: 'Обоснование оценки',
    min: 500,
  },
  // {
  //   field: 'financeOption',
  //   label: 'Обоснование оценки',
  // },
  // {
  //   field: 'expediency',
  //   label: '4. Вывод о целесообразности / нецелесообразности реализации Проекта',
  //   min: 500,
  // },
  {
    field: 'totalResultText',
    label: '4. Интегральная оценка успешности реализации проекта',
  },
];

export const validateForm = (values, application) => {
  const errorFields = [];

  simpleFields.forEach((f) => {
    if (f.field && !values[f.field]) {
      errorFields.push(`${f.label}. Нет значения в поле`);
    } else if (f.field && values[f.field] && f.min && f.field !== 'evaluation') {
      if (values[f.field] && values[f.field].length < f.min) {
        errorFields.push(`${f.label}. Введено ${values[f.field].length} знака при рекомендованном объем ${f.min} знаков (с пробелами)`);
      }
    } else if (!f.field) {
      let isBoolFieldTrue = false;
      f.fieldArray.forEach((bf) => {
        if (values[bf.field]) {
          isBoolFieldTrue = true;
        }
      });
      if (!isBoolFieldTrue) {
        errorFields.push(f.label);
      }
    }
  });

  if (values.isValueOther && !values.isValueOtherText) {
    errorFields.push('3.3. Потенциал практического применения результатов Проекта в сфере здравоохранения и медицины (Другое)');
  }

  let planError = false;
  Array(application.applicationsPlan.steps).fill(0).forEach((el, index) => {
    const field1 = `w${index + 1}`;
    const field2 = `r${index + 1}`;

    if (!values[field1] || !values[field2]) {
      planError = true;
    }
  });

  if (planError) {
    errorFields.push('1. Оценка фактического выполнения плана работ в отчетном периоде');
  }

  if (!values.planResult) {
    errorFields.push('1. Оценка фактического выполнения плана работ в отчетном периоде (вывод)');
  }

  let planFactError = false;
  planFactFields.forEach((f) => {
    if (typeof values[`${f.name}Left`] === 'undefined') {
      planFactError = true;
    }
    Array(application[`${f.name}Fact`] || 1).fill(0).forEach((el, index) => {
      const field = `${f.name}Comment_${index + 1}`;
      if (!values[field]) {
        planFactError = true;
      }
    });
  });

  if (!values.projectPatientsLeft || !values.projectPatientsComment) {
    planFactError = true;
  }
  if (planFactError) {
    errorFields.push('2. Экспертная оценка достижения показателей эффективности реализации проекта, установленных приложением № 3 к Соглашению.');
  }

  if (!values.planFactEvaluation) {
    errorFields.push('2. Экспертная оценка достижения показателей эффективности реализации проекта, установленных приложением № 3 к Соглашению. (выводы)');
  }

  // if (values.financeOption === 'Объём финансового обеспечения проекта явно завышен' && !values.financeSum) {
  //   errorFields.push('Оценка достижения заявленных показателей эффективности реализации проекта в отчетном периоде');
  // }

  return errorFields;
};

import React from 'react';
import {
  Row, Col, Button,
} from 'antd';


const getTotalColumns = (onEditClick, isFormDisabled, isShowOldForm) => {
  if (isShowOldForm) {
    return [
      {
        title: '№',
        dataIndex: 'orderIndex',
        key: 'totalPaid',
        width: '5%',
      },
      {
        title: 'Направления расходования денежных средств Гранта',
        dataIndex: 'name',
        key: 'name',
        width: '25%',
      },
      {
        title: 'Остаток на начало отчетного периода, руб.',
        dataIndex: 'moneyLeftPeriodStart',
        key: 'moneyLeftPeriodStart',
        render: (_, object) => (<div>{`${object.moneyLeftPeriodStartMajor.toString().length === 1 ? `0${object.moneyLeftPeriodStartMajor}` : object.moneyLeftPeriodStartMajor.toLocaleString('ru-RU')} руб. ${object.moneyLeftPeriodStartMinor.toString().length === 1 ? `0${object.moneyLeftPeriodStartMinor}` : object.moneyLeftPeriodStartMinor} коп. `}</div>),
      },
      {
        title: 'По состоянию за отчетный период',
        children: [
          {
            title: 'Сумма перечисленного Гранта, руб.',
            dataIndex: 'grantSum',
            key: 'grantSum',
            render: (_, object) => (<div>{`${object.grantSumMajor.toString().length === 1 ? `0${object.grantSumMajor}` : object.grantSumMajor.toLocaleString('ru-RU')} руб. ${object.grantSumMinor.toString().length === 1 ? `0${object.grantSumMinor}` : object.grantSumMinor} коп. `}</div>),
          },
          {
            title: 'Фактические расходы Гранта, руб.',
            dataIndex: 'actualSum',
            key: 'actualSum',
            render: (_, object) => (<div>{`${object.actualSumMajor.toString().length === 1 ? `0${object.actualSumMajor}` : object.actualSumMajor.toLocaleString('ru-RU')} руб. ${object.actualSumMinor.toString().length === 1 ? `0${object.actualSumMinor}` : object.actualSumMinor} коп. `}</div>),
          },
          {
            title: 'Кассовые расходы Гранта, руб.',
            dataIndex: 'paperSum',
            key: 'paperSum',
            render: (_, object) => (<div>{`${object.paperSumMajor.toString().length === 1 ? `0${object.paperSumMajor}` : object.paperSumMajor.toLocaleString('ru-RU')} руб. ${object.paperSumMinor.toString().length === 1 ? `0${object.paperSumMinor}` : object.paperSumMinor} коп. `}</div>),
          },
        ],
      },
      {
        title: 'Остаток на конец отчетного периода, руб.',
        dataIndex: 'moneyLeftPeriodEnd',
        key: 'moneyLeftPeriodEnd',
        render: (_, object) => (<div>{`${object.moneyLeftPeriodEndMajor.toString().length === 1 ? `0${object.moneyLeftPeriodEndMajor}` : object.moneyLeftPeriodEndMajor.toLocaleString('ru-RU')} руб. ${object.moneyLeftPeriodEndMinor.toString().length === 1 ? `0${object.moneyLeftPeriodEndMinor}` : object.moneyLeftPeriodEndMinor} коп. `}</div>),
      },
      {
        title: 'Причина возникшего остатка (комментарий)',
        dataIndex: 'comment',
        key: 'comment',
      },
      {
        title: '',
        dataIndex: 'actions',
        key: 'actions',
        width: '5%',
        render: (_, object) => (
          <div>
            {!object.isTotal && !isFormDisabled && (
            <Col gutter={5}>
              <Row style={{ cursor: 'pointer' }}><Button type="link" style={{ padding: 0 }} onClick={() => onEditClick(object)}>изменить</Button></Row>
            </Col>
            )}
          </div>
        ),
      },
    ];
  }
  return [
    {
      title: '№',
      dataIndex: 'orderIndex',
      key: 'totalPaid',
      width: '5%',
    },
    {
      title: 'Направления расходования денежных средств Гранта',
      dataIndex: 'name',
      key: 'name',
      width: '25%',
    },
    {
      title: 'Сумма перечисленного гранта, всего на конец отчетного периода, руб',
      dataIndex: 'money2022',
      key: 'money2022',
      render: (_, object) => (<div>{`${object.money2022Major.toString().length === 1 ? `0${object.money2022Major}` : object.money2022Major.toLocaleString('ru-RU')} руб. ${object.money2022Minor.toString().length === 1 ? `0${object.money2022Minor}` : object.money2022Minor} коп. `}</div>),
    },
    {
      title: 'Расходы на начало отчетного периода, руб',
      dataIndex: 'moneySpentLastPeriodStart',
      key: 'moneySpentLastPeriodStart',
      render: (_, object) => (<div>{`${object.moneySpentLastPeriodStartMajor.toString().length === 1 ? `0${object.moneySpentLastPeriodStartMajor}` : object.moneySpentLastPeriodStartMajor.toLocaleString('ru-RU')} руб. ${object.moneySpentLastPeriodStartMinor.toString().length === 1 ? `0${object.moneySpentLastPeriodStartMinor}` : object.moneySpentLastPeriodStartMinor} коп. `}</div>),
    },
    // {
    //   title: 'Остаток на начало отчетного периода, руб',
    //   dataIndex: 'moneyLeftLastPeriodStart',
    //   key: 'moneyLeftLastPeriodStart',
    //   render: (_, object) => (<div>{`${object.moneyLeftLastPeriodStartMajor.toString().length === 1 ? `0${object.moneyLeftLastPeriodStartMajor}` : object.moneyLeftLastPeriodStartMajor} руб. ${object.moneyLeftLastPeriodStartMinor.toString().length === 1 ? `0${object.moneyLeftLastPeriodStartMinor}` : object.moneyLeftLastPeriodStartMinor} коп. `}</div>),
    // },
    {
      title: 'По состоянию за отчетный период',
      children: [
        {
          title: 'Фактические расходы Гранта, руб.',
          dataIndex: 'actualSum',
          key: 'actualSum',
          render: (_, object) => (<div>{`${object.actualSumMajor.toString().length === 1 ? `0${object.actualSumMajor}` : object.actualSumMajor.toLocaleString('ru-RU')} руб. ${object.actualSumMinor.toString().length === 1 ? `0${object.actualSumMinor}` : object.actualSumMinor} коп. `}</div>),
        },
        {
          title: 'Кассовые расходы Гранта, руб.',
          dataIndex: 'paperSum',
          key: 'paperSum',
          render: (_, object) => (<div>{`${object.paperSumMajor.toString().length === 1 ? `0${object.paperSumMajor.toLocaleString('ru-RU')}` : object.paperSumMajor} руб. ${object.paperSumMinor.toString().length === 1 ? `0${object.paperSumMinor}` : object.paperSumMinor} коп. `}</div>),
        },
      ],
    },
    {
      title: 'Остаток на конец отчетного периода, руб.',
      dataIndex: 'moneyLeftPeriodEnd',
      key: 'moneyLeftPeriodEnd',
      render: (_, object) => (<div>{`${object.moneyLeftPeriodEndMajor.toString().length === 1 ? `0${object.moneyLeftPeriodEndMajor}` : object.moneyLeftPeriodEndMajor.toLocaleString('ru-RU')} руб. ${object.moneyLeftPeriodEndMinor.toString().length === 1 ? `0${object.moneyLeftPeriodEndMinor}` : object.moneyLeftPeriodEndMinor} коп. `}</div>),
    },
    {
      title: 'Причина возникшего остатка (комментарий)',
      dataIndex: 'comment',
      key: 'comment',
    },
    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      width: '5%',
      render: (_, object) => (
        <div>
          {!object.isTotal && !isFormDisabled && (
          <Col gutter={5}>
            <Row style={{ cursor: 'pointer' }}><Button type="link" style={{ padding: 0 }} onClick={() => onEditClick(object)}>изменить</Button></Row>
          </Col>
          )}
        </div>
      ),
    },
  ];
};

export default getTotalColumns;

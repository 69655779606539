/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Col, Button, Table, Input, Row, Modal, Form, InputNumber, notification, Checkbox,
} from 'antd';
import {
  editApplicationsFinancialReportTotal, getApplicationStepReportById, editApplicationStepReport,
  getApplicationSmeta,
} from '../../../../../../../utils/http';
import getTotalColumns from './getTotalColumns';
import getUserRole from '../../../../../../../utils/localeStorage/getUserRole';


const getCorrectMinor = (minor) => {
  if (minor?.toString().length === 1) {
    return `0${minor}`;
  }

  return minor || '00';
};

const { TextArea } = Input;
class Total extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowAddModal: false,
      objectInEdit: null,
      dataSource: [],
      loading: true,
      isFinancialTotalChecked: false,
      application: {},
      smeta: {},
    };
    this.formRef = React.createRef();
  }

  async componentDidMount() {
    const { applicationId } = this.props;
    const parentAppId = window.location.pathname.split('/')[2];

    const data = await Promise.all([getApplicationSmeta({ id: parentAppId }), getApplicationStepReportById(applicationId)])
    this.setState({ smeta: data[0] });
    this.getDataSource(data[1]);
  }

  getGrantSumByTypeAndYear = (type, year) => {
    const { smeta } = this.state;

    let major = 0;
    let minor = 0;

    for (let i = 2022; i <= year; i += 1) {
      // eslint-disable-next-line no-continue
      if (!smeta[`${type}${i}Major`]) continue;

      major += parseFloat(smeta[`${type}${i}Major`]);
      minor += parseFloat(smeta[`${type}${i}Minor`]);
    }

    if (minor >= 100) {
      major += Math.floor(minor / 100);
      minor %= 100;
    }

    return {
      grantSumMajor: major,
      grantSumMinor: minor,
    };
  }

  onEditClick = (objectInEdit) => {
    this.setState({ objectInEdit, isShowAddModal: true });
  }

  onCloseModal = () => {
    this.setState({ objectInEdit: null, isShowAddModal: false });
  }

  onSaveClick = () => {
    const { applicationId } = this.props;
    const { objectInEdit, application, dataSource } = this.state;
    this.formRef.current.validateFields()
      .then((values) => {
        editApplicationsFinancialReportTotal({ ...values,
          applicationStepReport_id: applicationId,
          type: objectInEdit.type })
          .then((id) => {
            const totalRow = { ...dataSource.find((d) => d.type === objectInEdit.type), ...values, id };
            let totalSumleft = 0;
            if (application.stepNumber === '1') {
              totalSumleft = parseFloat(`${totalRow.grantSumMajor}.${getCorrectMinor(totalRow.grantSumMinor)}`)
              - parseFloat(`${totalRow.paperSumMajor}.${getCorrectMinor(totalRow.paperSumMinor)}`);
            } else {
              const money2022 = parseFloat(`${totalRow.money2022Major}.${getCorrectMinor(totalRow.money2022Minor)}`);
              const moneyLastPeriod = parseFloat(`${totalRow.moneySpentLastPeriodStartMajor}.${getCorrectMinor(totalRow.moneySpentLastPeriodStartMinor)}`);
              const paperSum = parseFloat(`${totalRow.paperSumMajor}.${getCorrectMinor(totalRow.paperSumMinor)}`);
              const expensesSumm = Math.round(((moneyLastPeriod + paperSum) + Number.EPSILON) * 100) / 100;
              totalSumleft = (money2022 - expensesSumm);
              totalSumleft = Math.round((totalSumleft + Number.EPSILON) * 100) / 100;
            }
            const moneyLeftPeriodEndMajor = totalSumleft.toString().split('.')[0];
            const moneyLeftPeriodEndMinor = totalSumleft.toString().split('.')?.[1] || 0;

            this.setState((prevState) => ({
              objectInEdit: null,
              isShowAddModal: false,
              dataSource: prevState.dataSource.map((d) => (d.type === objectInEdit.type
                ? {
                  ...d,
                  ...values,
                  moneyLeftPeriodEndMajor,
                  moneyLeftPeriodEndMinor: moneyLeftPeriodEndMinor.toString().length === 1 ? `${moneyLeftPeriodEndMinor}0` : moneyLeftPeriodEndMinor,
                  id,
                }
                : d)),
            }));
          });
      })
      .catch(() => {
        this.setState({ loading: false });
        notification.error({ message: 'Не сохранено! Заполните все обязательные поля!' });
      });
  }

  calculateSum = (array, fieldName) => {
    const sum = array.reduce((a, c) => {
      const major = c[`${fieldName}Major`];
      const minor = Math.abs(c[`${fieldName}Minor`]);
      return a + parseFloat((parseFloat(`${major}.${(`${minor}`).length === 1 ? `0${minor}` : minor}`) * 100).toFixed(0));
    }, 0);
    return {
      major: Math.trunc(sum / 100) === 0 && sum < 0 ? `-0${Math.trunc(sum / 100)}` : Math.trunc(sum / 100),
      minor: Number((sum % 100).toFixed(0)),
      sum,
    };
  }


  getTotalRow = (dataSource) => {
    const fields = ['moneyLeftPeriodStart', 'grantSum', 'money2022', 'actualSum', 'paperSum', 'moneyLeftPeriodEnd', 'moneySpentLastPeriodStart', 'moneyLeftLastPeriodStart'];

    const obj = {
      id: 'isTotal',
      isTotal: true,
      name: 'ИТОГО',
    };

    fields.forEach((f) => {
      const sum = this.calculateSum(dataSource, f);
      obj[`${f}Major`] = sum.major;
      obj[`${f}Minor`] = sum.minor;
    });
    return obj;
  }

  getDataSource = (application) => {
    const { prevStepApplications, onSmetaChange } = this.props;
    const dataSource = [];

    const fields = [
      {
        type: 'salary',
        orderIndex: '1',
        name: 'Оплата труда работников (с учетом страховых взносов во небюджетные фонды)',
      },
      {
        type: 'material',
        orderIndex: '2',
        name: 'Расходы на приобретение материалов и комплектующих, необходимых для реализации Проекта',
      },
      {
        type: 'equipment',
        orderIndex: '3',
        name: 'Расходы на приобретение оборудования и иного имущества, необходимых для реализации Проекта',
      },
      {
        type: 'other',
        orderIndex: '4',
        name: 'Прочие прямые расходы',
      },
      {
        type: 'paper',
        orderIndex: '5',
        name: 'Накладные и общехозяйственные расходы',
      },
    ];

    fields.forEach((f) => {
      let array = [];
      let actualSumMajor = 0;
      let actualSumMinor = 0;
      let paperSumMajor = 0;
      let paperSumMinor = 0;

      let prevStepArray = [];
      let prevStepPaperSumMajor = 0;
      let prevStepPaperSumMinor = 0;

      if (f.type === 'salary') {
        array = application.financialSalary_ids;
        actualSumMajor = this.calculateSum(array, 'totalPerson').major;
        actualSumMinor = this.calculateSum(array, 'totalPerson').minor;

        paperSumMajor = this.calculateSum(array, 'actualPaid').major;
        paperSumMinor = this.calculateSum(array, 'actualPaid').minor;

        if (prevStepApplications.length > 0) {
          prevStepApplications.forEach((prevStepApplication) => {
            prevStepArray = prevStepApplication.financialSalary_ids || [];
            const prevStepArraySum = this.calculateSum(prevStepArray, 'actualPaid').sum + parseFloat(`${prevStepPaperSumMajor}.${(`${prevStepPaperSumMinor}`).length === 1 ? `0${prevStepPaperSumMinor}` : prevStepPaperSumMinor}`) * 100;
            prevStepPaperSumMajor = Math.trunc(prevStepArraySum / 100) === 0 && prevStepArraySum < 0 ? `-0${Math.trunc(prevStepArraySum / 100)}` : Math.trunc(prevStepArraySum / 100);
            prevStepPaperSumMinor = Number(Math.abs(prevStepArraySum % 100).toFixed(0));
          });
        }
      } else if (f.type === 'paper') {
        array = application.financialCosts_ids.filter((c) => c[`is${f.type.charAt(0).toUpperCase() + f.type.slice(1)}`]);
        actualSumMajor = this.calculateSum(array, 'actualCosts').major;
        actualSumMinor = this.calculateSum(array, 'actualCosts').minor;

        paperSumMajor = this.calculateSum(array, 'paperCosts').major;
        paperSumMinor = this.calculateSum(array, 'paperCosts').minor;

        if (prevStepApplications.length > 0) {
          prevStepApplications.forEach((prevStepApplication) => {
            prevStepArray = prevStepApplication.financialCosts_ids ? prevStepApplication.financialCosts_ids.filter((c) => c[`is${f.type.charAt(0).toUpperCase() + f.type.slice(1)}`]) : [];
            const prevStepArraySum = this.calculateSum(prevStepArray, 'grantSum').sum + parseFloat(`${prevStepPaperSumMajor}.${(`${prevStepPaperSumMinor}`).length === 1 ? `0${prevStepPaperSumMinor}` : prevStepPaperSumMinor}`) * 100;
            prevStepPaperSumMajor = Math.trunc(prevStepArraySum / 100) === 0 && prevStepArraySum < 0 ? `-0${Math.trunc(prevStepArraySum / 100)}` : Math.trunc(prevStepArraySum / 100);
            prevStepPaperSumMinor = Number(Math.abs(prevStepArraySum % 100).toFixed(0));
          });
        }
      } else {
        array = application.financialCosts_ids.filter((c) => c[`is${f.type.charAt(0).toUpperCase() + f.type.slice(1)}`]);
        actualSumMajor = this.calculateSum(array, 'actualCosts').major;
        actualSumMinor = this.calculateSum(array, 'actualCosts').minor;

        paperSumMajor = this.calculateSum(array, 'paperCosts').major;
        paperSumMinor = this.calculateSum(array, 'paperCosts').minor;

        if (prevStepApplications.length > 0) {
          prevStepApplications.forEach((prevStepApplication) => {
            prevStepArray = prevStepApplication.financialCosts_ids ? prevStepApplication.financialCosts_ids.filter((c) => c[`is${f.type.charAt(0).toUpperCase() + f.type.slice(1)}`]) : [];
            const prevStepArraySum = this.calculateSum(prevStepArray, 'paperCosts').sum + parseFloat(`${prevStepPaperSumMajor}.${(`${prevStepPaperSumMinor}`).length === 1 ? `0${prevStepPaperSumMinor}` : prevStepPaperSumMinor}`) * 100;
            prevStepPaperSumMajor = Math.trunc(prevStepArraySum / 100) === 0 && prevStepArraySum < 0 ? `-0${Math.trunc(prevStepArraySum / 100)}` : Math.trunc(prevStepArraySum / 100);
            prevStepPaperSumMinor = Number(Math.abs(prevStepArraySum % 100).toFixed(0));
          });
        }
      }

      const total = application.financialReportTotal_ids.find((t) => t.type === f.type) || {};
      let prevStepTotal = 0;
      prevStepApplications.forEach((prevStepApplication) => {
        const prevSum = prevStepApplication?.financialReportTotal_ids.find((t) => t.type === f.type);
        if (prevSum) {
          prevStepTotal += parseFloat(`${prevStepTotal.grantSumMajor}.${(`${prevStepTotal.grantSumMinor}`).length === 1 ? `0${prevStepTotal.grantSumMinor}` : prevStepTotal.grantSumMinor}`) * 100;
        }
      });
      let prevStepMoneyLeft = 0;
      if (prevStepTotal > 0) {
        prevStepMoneyLeft = prevStepTotal - parseFloat(`${prevStepTotal.prevStepPaperSumMajor}.${(`${prevStepTotal.prevStepPaperSumMinor}`).length === 1 ? `0${prevStepTotal.prevStepPaperSumMinor}` : prevStepTotal.prevStepPaperSumMinor}`) * 100;
      }

      const smetaEndYear = application.endDate.year();
      // const id = window.location.pathname.split('/')[2];

      // // Исключения-заявки, в которых четвертый этап должен быть первым и брать из сметы 2022
      // const exceptionIds = ['604fe15c-4e53-4f4a-835d-e5f83b1a0ed8', '1b798e9d-0c9d-406f-9729-774330b627e8'];

      // // if (exceptionIds.includes(id)) {
      // //   smetaEndYear -= 2;
      // // }

      const { grantSumMajor, grantSumMinor } = this.getGrantSumByTypeAndYear(f.type === 'salary' ? 'fot' : f.type, smetaEndYear);

      const finalObject = {
        id: total.id,
        type: f.type,
        orderIndex: f.orderIndex,
        name: f.name,
        money2022Major: grantSumMajor || total.money2022Major || 0,
        money2022Minor: grantSumMinor || total.money2022Minor || 0,
        moneySpentLastPeriodStartMajor: prevStepPaperSumMajor,
        moneySpentLastPeriodStartMinor: prevStepPaperSumMinor,
        moneyLeftLastPeriodStartMajor: Math.trunc(prevStepMoneyLeft / 100) === 0 && prevStepMoneyLeft < 0 ? `-0${Math.trunc(prevStepMoneyLeft / 100)}` : Math.trunc(prevStepMoneyLeft / 100),
        moneyLeftLastPeriodStartMinor: Number((prevStepMoneyLeft % 100).toFixed(0)),
        moneyLeftPeriodStartMajor: total.moneyLeftPeriodStartMajor || 0,
        moneyLeftPeriodStartMinor: total.moneyLeftPeriodStartMinor || 0,
        grantSumMajor: grantSumMajor || total.grantSumMajor || 0,
        grantSumMinor: grantSumMinor || total.grantSumMinor || 0,
        actualSumMajor,
        actualSumMinor,
        paperSumMajor,
        paperSumMinor,
        comment: total.comment || '',
      };
      let totalSumleft = 0;
      if (prevStepApplications.length === 0 && application.stepNumber === '1') {
        totalSumleft = parseFloat(`${finalObject.grantSumMajor}.${getCorrectMinor(finalObject.grantSumMinor)}`)
        - parseFloat(`${finalObject.paperSumMajor}.${getCorrectMinor(finalObject.paperSumMinor)}`);
      } else {
        const money2022 = parseFloat(`${finalObject.money2022Major}.${getCorrectMinor(finalObject.money2022Minor)}`);
        const moneyLastPeriod = parseFloat(`${finalObject.moneySpentLastPeriodStartMajor}.${getCorrectMinor(finalObject.moneySpentLastPeriodStartMinor)}`);
        const paperSum = parseFloat(`${finalObject.paperSumMajor}.${getCorrectMinor(finalObject.paperSumMinor)}`);
        const expensesSumm = Math.round(((moneyLastPeriod + paperSum) + Number.EPSILON) * 100) / 100;
        totalSumleft = (money2022 - expensesSumm);
        totalSumleft = Math.round(((totalSumleft) + Number.EPSILON) * 100) / 100;
      }
      // eslint-disable-next-line prefer-destructuring
      finalObject.moneyLeftPeriodEndMajor = totalSumleft.toString().split('.')[0];
      const minor = totalSumleft.toString().split('.')?.[1] || 0 
      finalObject.moneyLeftPeriodEndMinor = minor.toString().length === 1 ? `${minor}0` : minor

      dataSource.push(finalObject);
    });

    onSmetaChange(dataSource);

    this.setState({
      loading: false,
      dataSource,
      isFinancialTotalChecked: application.isFinancialTotalChecked,
      application,
    });
  }

  onCheckboxChange = (e) => {
    this.setState({ loading: true });
    const { applicationId } = this.props;
    editApplicationStepReport({ id: applicationId, isFinancialTotalChecked: e.target.checked })
      .then(() => {
        this.setState({ isFinancialTotalChecked: e.target.checked, loading: false });
        notification.success({ message: 'Сохранено!' });
      });
  }

  render() {
    const {
      dataSource, isShowAddModal, objectInEdit, loading, isFinancialTotalChecked, application,
    } = this.state;
    const {
      isFormDisabled, financialReportPosition, financialReportFIO, onChangeTextFiled,
    } = this.props;
    return (
      <Col>
        <Table
          dataSource={[...dataSource, this.getTotalRow(dataSource)]}
          columns={getTotalColumns(this.onEditClick, isFormDisabled, application.stepNumber)}
          pagination={false}
          rowKey="id"
          bordered
          loading={loading}
        />
        <Checkbox defaultChecked={isFinancialTotalChecked} checked={isFinancialTotalChecked} disabled={isFormDisabled || loading} style={{ marginTop: '5px', color: '#1890ff' }} onChange={this.onCheckboxChange}>Подтверждаю, что сводная таблица финансовых расходов по проекту заполнена и проверена</Checkbox>
        <div className="application-main-box-content-wrapper" style={{ padding: 0, marginTop: '20px' }}>
          <span className="application-main-box-label">ФИО работника организации, уполномоченного на представление отчета о целевом использовании средств гранта:</span>
          <Input value={financialReportFIO} onChange={(e) => onChangeTextFiled('financialReportFIO', e.target.value)} disabled={isFormDisabled || loading} />
        </div>
        <div className="application-main-box-content-wrapper" style={{ padding: 0 }}>
          <span className="application-main-box-label">Должность работника организации, уполномоченного на представление отчета о целевом использовании средств гранта:</span>
          <Input value={financialReportPosition} onChange={(e) => onChangeTextFiled('financialReportPosition', e.target.value)} disabled={isFormDisabled || loading} />
        </div>
        {isShowAddModal && (
        <Modal
          title="Редактирование формы"
          visible
          footer={[<Button type="primary" onClick={this.onSaveClick}>Сохранить</Button>]}
          onCancel={this.onCloseModal}
        >
          <Form
            initialValues={{ remember: true }}
            ref={this.formRef}
          >
            <Col>
              <Form.Item
                name="id"
                hidden
                initialValue={objectInEdit && objectInEdit.id}
              />
              {getUserRole() === 'bigAdmin' && application?.stepNumber !== '1' && (
              <div className="application-main-box-content-wrapper">
                <span className="application-main-box-label">Сумма перечисленного гранта, всего на конец отчетного периода, руб:</span>
                <Row align="bottom" gutter={8}>
                  <Col>
                    <Form.Item
                      name="money2022Major"
                      rules={[{ required: true, message: 'Обязательное поле' }]}
                      initialValue={objectInEdit && objectInEdit.money2022Major}
                    >
                      <InputNumber style={{ width: '200px' }} />
                    </Form.Item>
                  </Col>
                  <Col>руб.</Col>
                  <Col>
                    <Form.Item
                      name="money2022Minor"
                      rules={[{ required: true, message: 'Обязательное поле' }]}
                      initialValue={objectInEdit && objectInEdit.money2022Minor}
                    >
                      <InputNumber />
                    </Form.Item>
                  </Col>
                  <Col>коп.</Col>
                </Row>
              </div>
              )}
              {getUserRole() === 'bigAdmin' && application?.stepNumber === '1' && (
              <div className="application-main-box-content-wrapper">
                <span className="application-main-box-label">Сумма перечисленного Гранта:</span>
                <Row align="bottom" gutter={8}>
                  <Col>
                    <Form.Item
                      name="grantSumMajor"
                      rules={[{ required: true, message: 'Обязательное поле' }]}
                      initialValue={objectInEdit && objectInEdit.grantSumMajor}
                    >
                      <InputNumber style={{ width: '200px' }} />
                    </Form.Item>
                  </Col>
                  <Col>руб.</Col>
                  <Col>
                    <Form.Item
                      name="grantSumMinor"
                      rules={[{ required: true, message: 'Обязательное поле' }]}
                      initialValue={objectInEdit && objectInEdit.grantSumMinor}
                    >
                      <InputNumber />
                    </Form.Item>
                  </Col>
                  <Col>коп.</Col>
                </Row>
              </div>
              )}
              <div className="application-main-box-content-wrapper">
                <span className="application-main-box-label">Комментарий:</span>
                <Form.Item
                  name="comment"
                  initialValue={objectInEdit.comment}
                >
                  <TextArea />
                </Form.Item>
              </div>
            </Col>
          </Form>
        </Modal>
        )}
      </Col>
    );
  }
}

Total.propTypes = {
  onSmetaChange: PropTypes.func.isRequired,
  applicationId: PropTypes.string.isRequired,
  isFormDisabled: PropTypes.bool.isRequired,
  financialReportFIO: PropTypes.string.isRequired,
  financialReportPosition: PropTypes.string.isRequired,
  onChangeTextFiled: PropTypes.func.isRequired,
  prevStepApplications: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};


export default Total;


import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Col, Input, Row, Form, InputNumber, Modal, Button, DatePicker, Upload,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { InboxOutlined, DownloadOutlined, DeleteOutlined } from '@ant-design/icons';


const { Dragger } = Upload;
class MaterialsPOV extends Component {
  render() {
    const {
      object, onClose, onUploadFile, onRemoveFile, onSave,
    } = this.props;
    return (
      <Modal
        title="Приказ организации о выплатах в адрес задействованого(-ых) сотрудника(-ов) в работе по данному грантовому проекту"
        visible
        footer={[<Button type="primary" onClick={() => onSave('materialPOVInEdit')}>Сохранить</Button>]}
        onCancel={onClose}
      >
        <Form
          initialValues={{ remember: true }}
          ref={object.formRef}
        >
          <Col>
            <Form.Item
              name="id"
              hidden
              initialValue={object.id}
            />
            <div className="application-main-box-content-wrapper">
              <span className="application-main-box-label">Номер:</span>
              <Form.Item
                name="name"
                rules={[{ required: true, message: 'Обязательное поле' }]}
                initialValue={object.name}
              >
                <Input />
              </Form.Item>
            </div>
            <div className="application-main-box-content-wrapper">
              <span className="application-main-box-label">Дата:</span>
              <Form.Item
                name="date"
                rules={[{ required: true, message: 'Обязательное поле' }]}
                initialValue={object.date}
              >
                <DatePicker style={{ width: '100%' }} />
              </Form.Item>
            </div>
            <div className="application-main-box-content-wrapper">
              <span className="application-main-box-label">Количество листов:</span>
              <Form.Item
                name="pages"
                rules={[{ required: true, message: 'Обязательное поле' }]}
                initialValue={object.pages}
              >
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
            </div>
            <div className="application-main-box-content-wrapper">
              <span className="application-main-box-label">Комментарий:</span>
              <Form.Item
                name="comment"
                initialValue={object.comment}
              >
                <TextArea />
              </Form.Item>
            </div>
            <Col style={{ marginTop: '20px', padding: '15px 20px' }}>
              <span style={{ color: '#212121' }}>Загрузить скан-копию документа в формате pdf</span>
              {!object.file && (
              <Dragger
                showUploadList={false}
                onChange={(f) => onUploadFile(f, 'materialPOVInEdit')}
                multiple
                action={null}
                style={{ width: '100%' }}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">Выберите или перенесите файл, чтобы загрузить (не более 15мб). Для прикрепления нескольких файлов осуществляйте их загрузку по очереди, а не одновременно</p>
              </Dragger>
              )}
              {object.file && (
              <Row style={{ margin: '5px 0px' }} gutter={12}>
                <Col><DownloadOutlined style={{ cursor: 'pointer' }} onClick={() => window.open(object.file.urlLink)} /></Col>
                <Col><DeleteOutlined style={{ cursor: 'pointer' }} onClick={() => onRemoveFile('materialPOVInEdit')} /></Col>
                <Col>{object.file.name}</Col>
              </Row>
              )}
            </Col>
          </Col>
        </Form>
      </Modal>
    );
  }
}

MaterialsPOV.propTypes = {
  object: PropTypes.shape().isRequired,
  onClose: PropTypes.func.isRequired,
  onRemoveFile: PropTypes.func.isRequired,
  onUploadFile: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default MaterialsPOV;

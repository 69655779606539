const organisations = [
  { fullName: 'Государственное бюджетное учреждение здравоохранения города Москвы «Городская клиническая больница № 15 имени О.М. Филатова Департамента здравоохранения города Москвы»', shortName: 'ГБУЗ «ГКБ № 15 ДЗМ»', inn: '7720001850' },
  { fullName: 'Федеральное государственное бюджетное учреждение здравоохранения «Клиническая больница имени В.В. Виноградова» ', shortName: 'ФГБУЗ «КБ ИМ. В.В. ВИНОГРАДОВА»', inn: '7736048847' },
  { fullName: 'Государственное бюджетное учреждение здравоохранения города Москвы «Городская клиническая больница № 29 им. Н.Э. Баумана Департамента здравоохранения города Москвы»', shortName: 'ГБУЗ «ГКБ № 29 ИМ. Н.Э. БАУМАНА ДЗМ»', inn: '7722086539' },
  { fullName: 'Государственное бюджетное учреждение здравоохранения города Москвы «Детская городская клиническая больница имени Н.Ф. Филатова Департамента здравоохранения города Москвы»', shortName: 'ГБУЗ «ДГКБ ИМ. Н.Ф. ФИЛАТОВА ДЗМ»', inn: '7703111067' },
  { fullName: 'Государственное бюджетное учреждение здравоохранения города Москвы «Научно-исследовательский институт неотложной детской хирургии и травматологии» Департамента здравоохранения города Москвы»', shortName: '«НИИ НДХИТ»', inn: '7706517001' },
  { fullName: 'Государственное бюджетное учреждение здравоохранения города Москвы Московский многопрофильный научно-клинический центр имени С.П. Боткина Департамента здравоохранения города Москвы', shortName: 'ГБУЗ ММНКЦ БОТКИНСКАЯ БОЛЬНИЦА ДЗМ', inn: '7714082636' },
  { fullName: 'Государственное бюджетное учреждение здравоохранения города Москвы «Морозовская детская городская клиническая больница Департамента здравоохранения города Москвы»', shortName: 'ГБУЗ «МОРОЗОВСКАЯ ДГКБ ДЗМ»', inn: '7705039232' },
  { fullName: 'Государственное бюджетное учреждение здравоохранения города Москвы «Городская клиническая больница имени В.М. Буянова Департамента здравоохранения города Москвы»', shortName: 'ГБУЗ «ГКБ ИМЕНИ В.М. БУЯНОВА ДЗМ»', inn: '7724598966' },
  { fullName: 'Государственное бюджетное учреждение здравоохранения города Москвы «Городская клиническая больница имени М.П. Кончаловского Департамента здравоохранения города Москвы»', shortName: 'ГБУЗ «ГКБ ИМ. М.П. КОНЧАЛОВСКОГО ДЗМ»', inn: '7735069192' },
  { fullName: 'Государственное бюджетное учреждение здравоохранения города Москвы «Научно-практический психоневрологический центр имени З.П. Соловьева Департамента здравоохранения города Москвы»', shortName: 'ГБУЗ НПЦ ИМ. СОЛОВЬЕВА ДЗМ', inn: '7725056324' },
  { fullName: 'Государственное бюджетное учреждение здравоохранения города Москвы Городская клиническая больница № 1 им. Н.И. Пирогова Департамента здравоохранения города Москвы', shortName: 'ГКБ № 1 ИМ. Н.И.ПИРОГОВА', inn: '7706055854' },
  { fullName: 'Государственное бюджетное учреждение здравоохранения города Москвы «Научно-исследовательский институт скорой помощи им. Н.В. Склифосовского Департамента здравоохранения города Москвы»', shortName: 'ГБУЗ «НИИ СП ИМ. Н.В. СКЛИФОСОВСКОГО ДЗМ»', inn: '7702036321' },
  { fullName: 'Государственное бюджетное учреждение здравоохранения города Москвы «Городская клиническая больница № 67 имени Л.А. Ворохобова Департамента здравоохранения города Москвы»', shortName: 'ГБУЗ «ГКБ № 67 ИМ. Л. А. ВОРОХОБОВА ДЗМ»', inn: '7734091519' },
  { fullName: 'Государственное бюджетное учреждение здравоохранения города Москвы «Городская клиническая больница имени С.С. Юдина Департамента здравоохранения города Москвы»', shortName: 'ГБУЗ «ГКБ ИМ. С.С. ЮДИНА ДЗМ»', inn: '7724070870' },
  { fullName: 'Государственное бюджетное учреждение здравоохранения города Москвы «Московский клинический научно-практический центр имени А.С. Логинова Департамента здравоохранения города Москвы»', shortName: 'ГБУЗ МКНЦ ИМЕНИ А.С. ЛОГИНОВА ДЗМ', inn: '7720093523' },
  { fullName: 'Государственное бюджетное учреждение здравоохранения города Москвы «Детская городская клиническая больница № 9 им. Г.Н. Сперанского Департамента здравоохранения города Москвы»', shortName: 'ГБУЗ «ДГКБ № 9 ИМ. Г.Н. СПЕРАНСКОГО ДЗМ»', inn: '7703027320' },
  { fullName: 'Государственное бюджетное учреждение здравоохранения города Москвы «Городская клиническая больница № 52 Департамента здравоохранения города Москвы»', shortName: 'ГБУЗ «ГКБ № 52 ДЗМ»', inn: '7734115329' },
  { fullName: 'Государственное бюджетное учреждение здравоохранения города Москвы «Детская городская клиническая больница имени З.А. Башляевой Департамента здравоохранения города Москвы»', shortName: 'ГБУЗ «ДГКБ ИМ. З.А. БАШЛЯЕВОЙ ДЗМ»', inn: '7733024083' },
  { fullName: 'Государственное бюджетное учреждение здравоохранения города Москвы «Городская клиническая больница имени И.В. Давыдовского Департамента здравоохранения города Москвы»', shortName: 'ГБУЗ «ГКБ ИМ. И.В. ДАВЫДОВСКОГО ДЗМ»', inn: '7709129423' },
  { fullName: 'Государственное бюджетное учреждение здравоохранения города Москвы «Психиатрическая клиническая больница № 1 им. Н.А. Алексеева Департамента здравоохранения города Москвы»', shortName: 'ГБУЗ «ПКБ № 1 ДЗМ»', inn: '7726050389' },
  { fullName: 'Государственное бюджетное учреждение здравоохранения города Москвы «Научно-исследовательский клинический институт оториноларингологии им. Л.И. Свержевского» Департамента здравоохранения города Москвы', shortName: 'ГБУЗ НИКИО ИМ. Л.И. СВЕРЖЕВСКОГО ДЗМ', inn: '7726313447' },
  { fullName: 'Государственное бюджетное учреждение здравоохранения города Москвы «Городская клиническая больница имени С.И. Спасокукоцкого Департамента здравоохранения города Москвы»', shortName: 'ГБУЗ «ГКБ ИМ. С.И. СПАСОКУКОЦКОГО ДЗМ"', inn: '7713079503' },
  { fullName: 'Государственное бюджетное учреждение здравоохранения города Москвы "Московский научно-практический центр лабораторных исследований Департамента здравоохранения города Москвы"', shortName: 'ГБУЗ «НПКЦ ДИТ ДЗМ»', inn: '7709064286' },
  { fullName: 'Государственное бюджетное учреждение здравоохранения города Москвы «Московский многопрофильный клинический центр «Коммунарка» Департамента здравоохранения города Москвы»', shortName: 'ГБУЗ «ММКЦ «КОММУНАРКА» ДЗМ»', inn: '7716099554' },
  { fullName: 'Государственное бюджетное учреждение города Москвы «Станция скорой и неотложной медицинской помощи им. А.С. Пучкова» Департамента здравоохранения города Москвы»', shortName: 'СС И НМП ИМ. А.С. ПУЧКОВА', inn: '7702132064' },
  { fullName: 'Государственное автономное учреждение здравоохранения города Москвы «Московский научно-практический центр медицинской реабилитации, восстановительной и спортивной медицины имени С.И. Спасокукоцкого Департамента здравоохранения города Москвы»', shortName: 'ГАУЗ МНПЦ МРВСМ ИМ. С.И. СПАСОКУКОЦКОГО ДЗМ', inn: '770901001' },
  { fullName: 'Государственное бюджетное учреждение здравоохранения города Москвы «Городская клиническая онкологическая больница № 1 Департамента здравоохранения города Москвы»', shortName: 'ГБУЗ «ГКОБ № 1 ДЗМ»', inn: '7701008191' },
  { fullName: 'Государственное бюджетное учреждение здравоохранения города Москвы «Московский научно-практический центр дерматовенерологии и косметологии Департамента здравоохранения города Москвы»', shortName: 'ГБУЗ «МОСКОВСКИЙ ЦЕНТР ДЕРМАТОВЕНЕРОЛОГИИ И КОСМЕТОЛОГИИ»', inn: '7725034169' },
  { fullName: 'Государственное бюджетное учреждение здравоохранения города Москвы «Московская городская онкологическая больница № 62 Департамента здравоохранения города Москвы»', shortName: 'ГБУЗ «МГОБ № 62 ДЗМ»', inn: '5024001482' },
  { fullName: 'Государственное бюджетное учреждение здравоохранения города Москвы «Городская клиническая больница № 13 Департамента здравоохранения города Москвы»', shortName: 'ГБУЗ «ГКБ № 13 ДЗМ»', inn: '7723084929' },
  { fullName: 'Государственное бюджетное учреждение здравоохранения города Москвы «Городская клиническая больница имени В.В. Вересаева Департамента здравоохранения города Москвы»', shortName: 'ГБУЗ «ГКБ ИМ. В.В. ВЕРЕСАЕВА ДЗМ»', inn: '7713034164' },
  { fullName: 'Государственное бюджетное учреждение здравоохранения города Москвы «Городская клиническая больница имени А.К. Ерамишанцева Департамента здравоохранения города Москвы»', shortName: 'ГБУЗ «ГКБ ИМ. А.К. ЕРАМИШАНЦЕВА ДЗМ»', inn: '7716035705' },
  { fullName: 'Государственное бюджетное учреждение здравоохранения г. Москвы «Городская клиническая больница №31 имени академика Г.М.Савельевой Департамента здравоохранения города Москвы»', shortName: 'ГБУЗ «ГКБ № 31 ИМ. АКАДЕМИКА Г.М. САВЕЛЬЕВОЙ ДЗМ»', inn: '7729123204' },
  { fullName: 'Государственное бюджетное учреждение здравоохранения города Москвы «Городская клиническая больница № 17 Департамента здравоохранения города Москвы»', shortName: 'БУЗ ВО «ВГКБ № 17»', inn: '7732044545' },
  { fullName: 'Государственное бюджетное учреждение здравоохранения города Москвы «Психиатрическая клиническая больница № 4 имени П.Б. Ганнушкина Департамента здравоохранения города Москвы»', shortName: 'ГБУЗ «ПКБ № 4 ДЗМ»', inn: '7718084948' },
  { fullName: 'Государственное бюджетное учреждение здравоохранения города Москвы «Городская клиническая больница имени В.П. Демихова Департамента здравоохранения города Москвы»', shortName: 'ГБУЗ «ГКБ ИМЕНИ В.П. ДЕМИХОВА ДЗМ»', inn: '7723084936' },
  { fullName: 'Государственное бюджетное учреждение здравоохранения города Москвы «Консультативно-диагностическая поликлиника № 121 Департамента здравоохранения города Москвы»', shortName: 'ГБУЗ «КДП №121 ДЗМ»', inn: '7727196623' },
  { fullName: 'Государственное бюджетное учреждение здравоохранения города Москвы «Московский научно-практический центр лабораторных исследований Департамента здравоохранения города Москвы»', shortName: 'ГБУЗ «МНПЦЛИ ДЗМ»', inn: '7724015205' },
  { fullName: 'Государственное бюджетное учреждение здравоохранения города Москвы «Госпиталь для ветеранов войн № 3 Департамента здравоохранения города Москвы»', shortName: 'ГБУЗ «ГВВ № 3 ДЗМ»', inn: '7716080994' },
  { fullName: 'Государственное бюджетное учреждение здравоохранения города Москвы «Городская поликлиника № 212 Департамента здравоохранения города Москвы»', shortName: 'ГБУЗ «ГП № 212 ДЗМ»', inn: '7732016040' },
  { fullName: 'Государственное бюджетное учреждение здравоохранения города Москвы «Городская поликлиника № 109 Департамента здравоохранения города Москвы»', shortName: 'ГБУЗ "ГП № 109 ДЗМ" ', inn: '7723811412' },
  { fullName: 'Государственное бюджетное учреждение здравоохранения города Москвы «Научно-исследовательский институт неотложной детской хирургии и травматологии» Департамента здравоохранения города Москвы', shortName: 'НИИ НДХИТ', inn: '7706517001' },
  { fullName: 'Государственное автономное учреждение здравоохранения города Москвы «Стоматологическая поликлиника № 13 Департамента здравоохранения города Москвы»', shortName: 'ГАУЗ «СП № 13 ДЗМ»', inn: '7722000860' },
  { fullName: 'Государственное казенное учреждение города Москвы «Дирекция по координации деятельности медицинских организаций Департамента здравоохранения города Москвы»', shortName: 'ГКУ ДКД МО ДЗМ', inn: '7726020722' },
  { fullName: 'Государственное бюджетное учреждение здравоохранения города Москвы "Детская городская поликлиника № 125 Департамента здравоохранения города Москвы"', shortName: 'ДГП №125 ДЗМ', inn: '7715089391' },
  { fullName: 'Государственное автономное учреждение здравоохранения города Москвы "Стоматологическая поликлиника № 64 Департамента здравоохранения города Москвы"', shortName: 'ГАУЗ "СП № 64 ДЗМ"', inn: '7727268645' },
  { fullName: 'Государственное бюджетное учреждение здравоохранения города Москвы "Инфекционная клиническая больница № 1 Департамента здравоохранения города Москвы"', shortName: 'ГБУЗ "ИКБ № 1 ДЗМ"', inn: '7733051270' },
  { fullName: 'Государственное бюджетное учреждение здравоохранения города Москвы "Научно-практический центр детской психоневрологии Департамента здравоохранения города Москвы"', shortName: 'ГБУЗ "НПЦ ДП ДЗМ"', inn: '7729152967' },
  { fullName: 'Государственное бюджетное учреждение здравоохранения города Москвы "Городская поликлиника № 64 Департамента здравоохранения города Москвы"', shortName: 'ГБУЗ "ГП № 64 ДЗМ"', inn: '7718104190' },
  { fullName: 'Государственное бюджетное учреждение здравоохранения города Москвы "Городская поликлиника № 214 Департамента здравоохранения города Москвы"', shortName: 'ГБУЗ "ГП № 214 ДЗМ"', inn: '7724245600' },
  { fullName: 'Федеральное государственное бюджетное учреждение "Национальный медицинский исследовательский центр сердечно-сосудистой хирургии имени А.Н. Бакулева" Министерства здравоохранения Российской Федерации', shortName: 'ФГБУ "НМИЦ ССХ ИМ. А.Н. БАКУЛЕВА" МИНЗДРАВА РОССИИ', inn: '773607091819' },
  { fullName: 'Государственное бюджетное учреждение здравоохранения города Москвы "Детская городская клиническая больница святого Владимира Департамента Здравоохранения города Москвы"', shortName: 'ГБУЗ "ДГКБ св.Владимира"', inn: '7718113194' },
  { fullName: 'Государственное бюджетное учреждение здравоохранения города Москвы «Центр патологии речи и нейрореабилитации Департамента здравоохранения города Москвы»', shortName: 'ГБУЗ "ЦПРИН ДЗМ"', inn: '7709065709' },
  { fullName: 'Государственное бюджетное учреждение здравоохранения города Москвы "Городская поликлиника № 3 Департамента здравоохранения города Москвы"', shortName: 'ГБУЗ "ГП № 3 ДЗМ"', inn: '7710125487' },
  { fullName: 'Государственное бюджетное учреждение города Москвы "Научно-исследовательский институт организации здравоохранения и медицинского менеджмента Департамента здравоохранения города Москвы"', shortName: 'ГБУ "НИИОЗММ ДЗМ"', inn: '7727105591' },
  { fullName: 'Государственное бюджетное учреждение здравоохранения города Москвы "Городская клиническая больница № 24 Департамента здравоохранения города Москвы"', shortName: 'ГБУЗ "ГКБ № 24 ДЗМ"', inn: '7707011465' },
  { fullName: 'Федеральное государственное бюджетное научное учреждение "Федеральный научно-клинический центр реаниматологии и реабилитологии"', shortName: 'ФГБНУ ФНКЦ РР', inn: '7709938054' },
  { fullName: 'Федеральное государственное бюджетное научное учреждение "Российский научный центр хирургии имени академика Б.В. Петровского"', shortName: 'ФГБНУ “РНЦХ им. акад. Б.В. Петровского”', inn: '7704030124' },
  { fullName: 'Федеральное государственное бюджетное научное учреждение "Научный центр неврологии"', shortName: 'ФГБНУ НЦН', inn: '7733012151' },
  { fullName: 'Федеральное государственное бюджетное учреждение "Государственный научный центр Российской Федерации - Федеральный медицинский биофизический центр имени А.И. Бурназяна"', shortName: 'ФГБУ ГНЦ ФМБЦ им. А.И. Бурназяна ФМБА России', inn: '7734581136' },
  { fullName: 'Федеральное государственное бюджетное учреждение "Национальный медицинский исследовательский центр кардиологии имени академика Е.И. Чазова" Министерства Здравоохранения Российской Федерации', shortName: 'ФГБУ НМИЦ кардиологии им.ак.Е.И.Чазова МЗ РФ', inn: '7731243467' },
  { fullName: 'Федеральное государственное бюджетное учреждение "Национальный медицинский исследовательский центр онкологии имени Н.Н. Блохина" Министерства Здравоохранения Российской Федерации', shortName: 'ФГБУ НМИЦ онкологии им. Н.Н. Блохина МЗ РФ', inn: '7724075162' },
  { fullName: 'Федеральное государственное бюджетное учреждение "Федеральный центр мозга и нейротехнологий" Федерального медико-биологического агентства', shortName: 'ФГБУ ФЦМН ФМБА России', inn: '7728434750' },
  { fullName: 'Федеральное государственное бюджетное учреждение "Национальный медико-хирургический центр имени Н.И.Пирогова" Министерства Здравоохранения Российской Федерации', shortName: 'ФГБУ "НМХЦ им. Н. И. Пирогова" МЗ РФ', inn: '7733108569' },
  { fullName: 'Государственное бюджетное учреждение здравоохранения города Москвы "Городская поликлиника №220 Департамента здравоохранения города Москвы"', shortName: 'ГБУЗ «ГП № 220 ДЗМ»', inn: '7703173546' },
  { fullName: 'Федеральное государственное автономное образовательное учреждение высшего образования "Первый московский государственный медицинский университет имени И.М. Сеченова" Министерства Здравоохранения Российской Федерации (Сеченовский университет)"', shortName: 'ФГАОУ ВО Первый МГМУ им. И.М. Сеченова Минздрава России (Сеченовский Университет)', inn: '7704047505' },
  { fullName: 'Университетская клиническая больница №1 Сеченовского университета', shortName: 'УКБ № 1 ФГАОУ ВО Первый МГМУ им. И. М. Сеченова МЗ РФ', inn: '7704047505' },
  { fullName: 'Университетская клиническая больница №4 Сеченовского университета"', shortName: 'УКБ № 4 ФГАОУ ВО Первый МГМУ им. И. М. Сеченова МЗ РФ', inn: '7704047505' },
  { fullName: 'Федеральное государственное автономное образовательное учреждение высшего образования "Российский университет дружбы народов имени Патриса Лумумбы"', shortName: 'РУДН им. Патриса Лумумбы', inn: '7728073720' },
  { fullName: 'Государственное бюджетное учреждение здравоохранения Московской области «Московский областной научно-исследовательский клинический институт им. М.Ф. Владимирского»', shortName: 'localhost:5173"ГБУЗ МО МОНИКИ им. М.Ф.Владимирского "', inn: '7702066157' },
];

export default organisations;

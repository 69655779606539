/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { groupBy } from 'lodash';
import {
  Col,
  Button,
  Table,
  Input,
  Row,
  Form,
  InputNumber,
  notification,
  Checkbox,
  Upload,
  Drawer,
  Space,
  Divider,
  Popconfirm,
  Spin,
} from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  DownloadOutlined,
  InboxOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import {
  uploadFile,
  editApplicationStepReport,
  editApplicationCostsDocument,
  editApplicationCosts,
} from '../../../../../../../utils/http';
import getMaterialsColumns from './getMaterialsColumns';
import MaterialsContract from './MaterialsContract';
import MaterialsAct from './MaterialsAct';
import MaterialsYPD from './MaterialsYPD';
import MaterialsTN from './MaterialsTN';
import MaterialsSF from './MaterialsSF';
import MaterialsPP from './MaterialsPP';
import MaterialsDS from './MaterialsDS';
import calculateTotal from '../../../../../../../utils/calculateTotal';
import { downloadFile } from '../../../../../../../utils/downloadFile';
import MaterialsTovN from './MaterialsTovN';
import MaterialsBSN from './MaterialsBSN';
import MaterialsVUP from './MaterialsVUP';
import MaterialsPOV from './MaterialPOV';
import MaterialsPOZ from './MaterialsPOZ';


const { Dragger } = Upload;
class Materials extends Component {
  constructor(props) {
    super(props);
    this.state = {
      objectInEdit: null,
      dataSource: this.props.dataSource,
      loading: false,
      materialContractInEdit: null,
      materialActInEdit: null,
      materialYPDInEdit: null,
      materialTovNInEdit: null,
      materialTNInEdit: null,
      materialSFInEdit: null,
      materialPPInEdit: null,
      materialDSInEdit: null,
      materialBSNInEdit: null,
      materialVUPInEdit: null,
      materialPOVInEdit: null,
      materialPOZInEdit: null,
      checkboxValue: this.props.checkboxValue,
      financialPaperPolicyFile: this.props.financialPaperPolicyFile,
      financialPaperAccountFile: this.props.financialPaperAccountFile,
      deliveryBill: null,
      supportStaff: null,
    };
    this.formRef = React.createRef();
  }

  onAddClick = () => {
    this.setState({ objectInEdit: {} });
  };

  onEditClick = (objectInEdit) => {
    this.setState({ objectInEdit });
  };

  onCloseDrawer = () => {
    this.setState({ objectInEdit: null });
  };

  onDeleteClick = (object) => {
    this.setState({ loading: true });
    editApplicationCosts({ id: object.id, isDeleted: true }).then(() => this.setState((prevState) => ({
      loading: false,
      dataSource: prevState.dataSource.filter((d) => d.id !== object.id),
    })));
  };

  getTotalRow = (dataSource) => {
    const { isPaper } = this.props;
    const fields = ['actualCosts', 'paperCosts', 'deviations'];
    if (isPaper) {
      fields.push('grantSum');
    }
    const obj = {
      id: 'isTotal',
      isTotal: true,
      name: 'ОБЩИЙ ИТОГ',
      numberOfPages: 0,
    };
    dataSource.forEach((row) => {
      if (row.materialContract) {
        obj.numberOfPages += row.materialContract.pages;
      }
      if (row.materialPP && row.materialPP.length > 0) {
        obj.numberOfPages += row.materialPP.reduce((a, c) => a + c.pages, 0);
      }
      if (row.materialDS && row.materialDS.length > 0) {
        obj.numberOfPages += row.materialDS.reduce((a, c) => a + c.pages, 0);
      }
      if (row.materialPOV && row.materialPOV.length > 0) {
        obj.numberOfPages += row.materialPOV.reduce((a, c) => a + c.pages, 0);
      }
      if (row.materialPOZ && row.materialPOZ.length > 0) {
        obj.numberOfPages += row.materialPOZ.reduce((a, c) => a + c.pages, 0);
      }
      if (row.materialBSN && row.materialBSN.length > 0) {
        obj.numberOfPages += row.materialBSN.reduce((a, c) => a + c.pages, 0);
      }
      if (row.materialVUP && row.materialVUP.length > 0) {
        obj.numberOfPages += row.materialVUP.reduce((a, c) => a + c.pages, 0);
      }
      if (row.materialAct) {
        obj.numberOfPages += row.materialAct.pages;
      }
      if (row.materialYPD && row.materialYPD.length > 0) {
        obj.numberOfPages += row.materialYPD.reduce((a, c) => a + c.pages, 0);
      }
      if (row.materialTN) {
        obj.numberOfPages += row.materialTN.pages;
      }
      if (row.materialTovN) {
        obj.numberOfPages += row.materialTovN.pages;
      }
      if (row.materialSF) {
        obj.numberOfPages += row.materialSF.pages;
      }
    });
    fields.forEach((f) => {
      const sumArray = dataSource.map((c) => ({
        major: c[`${f}Major`],
        minor: c[`${f}Minor`],
      }));
      const total = calculateTotal(sumArray);

      obj[`${f}Major`] = total.major;
      obj[`${f}Minor`] = total.minor;
    });
    return obj;
  };

  onRemoveFile = (filedWithObjectInState) => {
    this.setState((prevState) => ({
      [filedWithObjectInState]: {
        ...prevState[filedWithObjectInState],
        file: null,
      },
    }));
  };

  onUploadFile = (info, filedWithObjectInState) => {
    if (info.file.status === 'uploading' && !info.event) {
      if (info.file.type !== 'application/pdf') {
        notification.error({
          message: 'Ошибка! Загрузите, пожалуйста, файл в формате PDF',
        });
        return;
      }
      this.setState({ loading: true });
      const form = new FormData();
      const headers = {
        'Content-Type': 'multipart/form-data',
      };
      form.append('file', info.file.originFileObj);
      uploadFile(form, headers).then((file) => this.setState((prevState) => ({
        loading: true,
        [filedWithObjectInState]: {
          ...prevState[filedWithObjectInState],
          file,
        },
      })));
    }
  };

  onChangeCheckBox = (e) => {
    const { checkboxName, applicationId } = this.props;
    this.setState({ loading: true });
    editApplicationStepReport({
      id: applicationId,
      [checkboxName]: e.target.checked,
    }).then(() => {
      this.setState({ checkboxValue: e.target.checked, loading: false });
      notification.success({ message: 'Сохранено' });
    });
  };

  onChangeExcludingVAT = (e) => {
    this.setState((prevState) => ({
      objectInEdit: {
        ...prevState.objectInEdit,
        excludingVAT: e.target.checked,
      },
    }));
  };

  onChangeisNotDeliveryBill = (e) => {
    this.setState((prevState) => ({
      objectInEdit: {
        ...prevState.objectInEdit,
        isNotDeliveryBill: e.target.checked,
      },
    }));
  };

  onChangeisNoAct = (e) => {
    this.setState((prevState) => ({
      objectInEdit: {
        ...prevState.objectInEdit,
        isNoAct: e.target.checked,
      },
    }));
  };

  onChangeisNoTovN = (e) => {
    this.setState((prevState) => ({
      objectInEdit: {
        ...prevState.objectInEdit,
        isNoTovN: e.target.checked,
      },
    }));
  };

  onChangeisNoSF = (e) => {
    this.setState((prevState) => ({
      objectInEdit: {
        ...prevState.objectInEdit,
        isNoSF: e.target.checked,
      },
    }));
  };

  onChangeisNoUpd = (e) => {
    this.setState((prevState) => ({
      objectInEdit: {
        ...prevState.objectInEdit,
        isNoUpd: e.target.checked,
      },
    }));
  };

  onChangeisNoPP = (e) => {
    this.setState((prevState) => ({
      objectInEdit: {
        ...prevState.objectInEdit,
        isNoPP: e.target.checked,
      },
    }));
  };

  onChangeSupportStaff = (e) => {
    this.setState((prevState) => ({
      objectInEdit: {
        ...prevState.objectInEdit,
        supportStaff: e.target.checked,
      },
    }));
  };

  onSaveModal = (filedWithObjectInState) => {
    const object = this.state[filedWithObjectInState];

    object.formRef.current
      ?.validateFields()
      .then((values) => {
        const { file } = object;
        console.log(object);
        if (!file) {
          notification.error({ message: 'Не сохранено! Загрузите файл!' });
        }
        this.setState({ loading: true });
        const fieldName = filedWithObjectInState.split('InEdit')[0];
        editApplicationCostsDocument({
          ...values,
          file: file.id,
        }).then((id) => {
          notification.success({ message: 'Сохранено!' });
          this.setState((prevState) => {
            console.log(fieldName)
            return {
            loading: false,
            [filedWithObjectInState]: null,
            objectInEdit: {
              ...prevState.objectInEdit,
              [fieldName]:
                fieldName === 'materialPP' || fieldName === 'materialDS' || fieldName === 'materialVUP' || fieldName === 'materialBSN' || fieldName === 'materialPOV' || fieldName === 'materialPOZ' || fieldName === 'materialYPD'
                  ? values.id
                    ? (prevState.objectInEdit[fieldName] || [])?.map((o) => (o.id === values.id ? { ...values, file, id } : o))
                    : [
                      ...(prevState.objectInEdit[fieldName] || []),
                      { ...values, file, id },
                    ]
                  : { ...values, file, id },
            },
          }});
        });
      })
      .catch((err) => {
        console.log(err)
        this.setState({ loading: false });
        notification.error({
          message: 'Не сохранено! Заполните все обязательные поля!',
        });
      });
  };

  onDeleteDocument = (fieldName, id) => {
    let filteredObjects = [];
    if (fieldName === 'materialPP' || fieldName === 'materialDS' || fieldName === 'materialVUP' || fieldName === 'materialBSN' || fieldName === 'materialPOV' || fieldName === 'materialPOZ' || fieldName === 'materialYPD') {
      const grouped = groupBy(this.state.objectInEdit[fieldName], 'id');
      const groupedKeys = Object.keys(grouped);
      groupedKeys.forEach((key) => {
        if ((key === id && grouped[key].length > 1) || key !== id) {
          filteredObjects.push(grouped[key][0]);
        }
      });
    } else {
      filteredObjects = null;
    }
    this.setState((prevState) => ({
      objectInEdit: {
        ...prevState.objectInEdit,
        [fieldName]: filteredObjects,
      },
    }));
  };

  onChangeIsNoAdditionalAgreement = (e) => {
    this.setState((prevState) => ({
      objectInEdit: {
        ...prevState.objectInEdit,
        isNoAdditionalAgreement: e.target.checked,
      },
    }));
  };

  onChangeIsDeliveryBill = (e) => {
    this.setState((prevState) => ({
      objectInEdit: {
        ...prevState.objectInEdit,
        isDeliveryBill: e.target.checked,
      },
    }));
  };

  onSaveDrawer = () => {
    this.formRef.current
      .validateFields()
      .then((values) => {
        const {
          isMaterial, isEquipment, isOther, isPaper, applicationId,
        } = this.props;
        const { objectInEdit } = this.state;
        const {
          materialContract,
          materialPP,
          materialDS,
          materialBSN,
          materialVUP,
          materialPOV,
          materialPOZ,
          materialAct,
          materialYPD,
          materialTN,
          materialTovN,
          materialSF,
          isNoAdditionalAgreement,
          isDeliveryBill,
          deliveryBill,
          isNotDeliveryBill,
          isNoAct,
          isNoTovN,
          isNoSF,
          isNoUpd,
          isNoPP,
          excludingVAT,
          supportStaff,
        } = objectInEdit;

        let error = false;

        if (!materialContract) {
          notification.error({
            message: 'Не сохранено! Заполните карточку Договор!',
          });
          error = true;
        }

        if (
          !isNoAdditionalAgreement
          && (!materialDS || materialDS.length === 0)
        ) {
          notification.error({
            message:
              'Не сохранено! Заполните карточку Дополнительное соглашение!',
          });
          error = true;
        }

        if (isNoAdditionalAgreement && materialDS?.length > 0) {
          notification.error({
            message:
              'При отмеченном пункте "Дополнительное соглашение не заключалось" не должно быть загружено Дополнительное соглашение',
          });
          error = true;
        }

        if (materialPP?.length === 0 && !isNoPP) {
          notification.error({
            message: 'Не сохранено! Заполните карточку Платежное поручение!',
          });
          error = true;
        }

        if (!isDeliveryBill && !supportStaff && !materialYPD && !isNoUpd) {
          notification.error({
            message: 'Не сохранено! Заполните карточку УПД!',
          });
          error = true;
        }

        if (!supportStaff && !materialAct && !isNoAct) {
          notification.error({
            message: 'Не сохранено! Заполните карточки акта!',
          });
          error = true;
        }

        if (!supportStaff && !materialTN && !isNotDeliveryBill) {
          notification.error({
            message: 'Не сохранено! Заполните карточки транспортной накладной!',
          });
          error = true;
        }

        if (!supportStaff && isDeliveryBill && !materialTovN && !isNoTovN) {
          notification.error({
            message: 'Не сохранено! Заполните карточки товарной накладной!',
          });
          error = true;
        }

        if (!supportStaff && isDeliveryBill && !materialSF && !isNoSF && !excludingVAT) {
          notification.error({
            message: 'Не сохранено! Заполните карточки счет-фактуры!',
          });
          error = true;
        }

        if (supportStaff && !materialBSN?.length === 0) {
          notification.error({
            message: 'Не сохранено! Загрузите бухгалтерскую справку о начислениях',
          });
          error = true;
        }

        if (supportStaff && !materialVUP?.length === 0) {
          notification.error({
            message: 'Не сохранено! Загрузите выписку из учетной политики',
          });
          error = true;
        }

        if (supportStaff && !materialPOV?.length === 0) {
          if (!materialPOZ?.length === 0) {
            notification.error({
              message: 'Не сохранено! Загрузите приказ организации о задействовании сотрудника(-ов) в работе по данному грантовому проекту или приказ организации о выплатах в адрес задействованого(-ых) сотрудника(-ов) в работе по данному грантовому проекту',
            });
            error = true;
          }
        }

        if (supportStaff && !materialPOZ?.length === 0) {
          if (!materialPOV?.length === 0) {
            notification.error({
              message: 'Не сохранено! Загрузите приказ организации о задействовании сотрудника(-ов) в работе по данному грантовому проекту или приказ организации о выплатах в адрес задействованого(-ых) сотрудника(-ов) в работе по данному грантовому проекту',
            });
            error = true;
          }
        }

        const hasSupportDocs = materialBSN?.length > 0 || materialVUP?.length > 0 || materialPOV?.length > 0 || materialPOZ?.length > 0 || materialPP?.length > 0;
        const hasBaseDocs = materialPP?.length > 0 || materialYPD?.length > 0 || materialAct?.id || materialTN?.id || materialTovN?.id || materialSF?.id;

        if (!supportStaff && !hasBaseDocs) {
          notification.error({
            message: 'Не сохранено! Требуется хотя бы один закрывающий документ',
          });
          error = true;
        }

        if (supportStaff && !hasSupportDocs) {
          notification.error({
            message: 'Не сохранено! Требуется хотя бы один закрывающий документ',
          });
          error = true;
        }


        if (error) {
          return;
        }

        this.setState({ loading: true });
        editApplicationCosts({
          ...values,
          applicationStepReport_id: applicationId,
          isMaterial,
          isEquipment,
          isOther,
          isPaper,
          materialContract: materialContract.id,
          materialPP: materialPP ? materialPP.map((o) => o.id).join(',') : null,
          materialDS: materialDS ? materialDS.map((o) => o.id).join(',') : null,
          materialBSN: materialBSN ? materialBSN.map((o) => o.id).join(',') : null,
          materialVUP: materialVUP ? materialVUP.map((o) => o.id).join(',') : null,
          materialPOV: materialPOV ? materialPOV.map((o) => o.id).join(',') : null,
          materialPOZ: materialPOZ ? materialPOZ.map((o) => o.id).join(',') : null,
          materialYPD: materialYPD ? materialYPD.map((o) => o.id).join(',') : null,
          materialAct: materialAct ? materialAct.id : null,
          materialTN: materialTN ? materialTN.id : null,
          materialSF: materialSF ? materialSF.id : null,
          materialTovN: materialTovN ? materialTovN.id : null,
          isNoAdditionalAgreement,
          isDeliveryBill,
          deliveryBill,
          isNotDeliveryBill,
          isNoPP,
          isNoAct,
          isNoTovN,
          isNoSF,
          isNoUpd,
          excludingVAT,
          supportStaff,
        }).then((id) => {
          const editApplicationCostsDocuments = [
            { id: materialContract.id },
          ];
          if (materialDS && materialDS.length > 0) {
            editApplicationCostsDocuments.push(
              ...materialDS.map((p) => ({ id: p.id })),
            );
          }

          if (materialPP && materialPP.length > 0) {
            editApplicationCostsDocuments.push(
              ...materialPP.map((p) => ({ id: p.id })),
            );
          }
          if (materialBSN && materialBSN.length > 0) {
            editApplicationCostsDocuments.push(
              ...materialBSN.map((p) => ({ id: p.id })),
            );
          }
          if (materialVUP && materialVUP.length > 0) {
            editApplicationCostsDocuments.push(
              ...materialVUP.map((p) => ({ id: p.id })),
            );
          }
          if (materialPOV && materialPOV.length > 0) {
            editApplicationCostsDocuments.push(
              ...materialPOV.map((p) => ({ id: p.id })),
            );
          }
          if (materialPOZ && materialPOZ.length > 0) {
            editApplicationCostsDocuments.push(
              ...materialPOZ.map((p) => ({ id: p.id })),
            );
          }
          if (materialAct) {
            editApplicationCostsDocuments.push({ id: materialAct.id });
          }
          if (materialYPD && materialYPD.length > 0) {
            editApplicationCostsDocuments.push(
              ...materialYPD.map((p) => ({ id: p.id })),
            );
          }
          if (materialTN) {
            editApplicationCostsDocuments.push({ id: materialTN.id });
          }
          if (materialSF) {
            editApplicationCostsDocuments.push({ id: materialSF.id });
          }
          if (materialTovN) {
            editApplicationCostsDocuments.push({ id: materialTovN.id });
          }
          editApplicationCostsDocument(
            editApplicationCostsDocuments.map((o) => ({
              id: o.id,
              applicationCost_id: id,
            })),
          ).then(() => {
            this.setState((prevState) => ({
              objectInEdit: null,
              loading: false,
              dataSource: values.id
                ? prevState.dataSource.map((d) => (d.id === values.id
                  ? {
                    ...values,
                    applicationStepReport_id: applicationId,
                    isMaterial,
                    isEquipment,
                    isOther,
                    isPaper,
                    materialContract,
                    materialPP,
                    materialDS,
                    materialAct,
                    materialYPD,
                    materialTN,
                    materialTovN,
                    materialSF,
                    materialBSN,
                    materialVUP,
                    materialPOV,
                    materialPOZ,
                    isNoAdditionalAgreement,
                    isDeliveryBill,
                    deliveryBill,
                    isNotDeliveryBill,
                    isNoPP,
                    isNoAct,
                    isNoTovN,
                    isNoSF,
                    isNoUpd,
                    excludingVAT,
                    supportStaff,
                  }
                  : d))
                : [
                  ...prevState.dataSource,
                  {
                    ...values,
                    applicationStepReport_id: applicationId,
                    isMaterial,
                    isEquipment,
                    isOther,
                    isPaper,
                    materialContract,
                    materialPP,
                    materialDS,
                    materialAct,
                    materialYPD,
                    materialTN,
                    materialTovN,
                    materialSF,
                    materialBSN,
                    materialVUP,
                    materialPOV,
                    materialPOZ,
                    isNoAdditionalAgreement,
                    isDeliveryBill,
                    id,
                    deliveryBill,
                    isNotDeliveryBill,
                    isNoPP,
                    isNoAct,
                    isNoAct,
                    isNoTovN,
                    isNoUpd,
                    excludingVAT,
                    supportStaff,
                  },
                ],
            }));
            notification.success({ message: 'Сохранено!' });
          });
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
        notification.error({
          message: 'Не сохранено! Заполните все обязательные поля!',
        });
      });
  };

  onUploadMainFile = (info, fieldName) => {
    this.setState({ loading: true });
    if (info.file.status === 'uploading' && !info.event) {
      const form = new FormData();
      const headers = {
        'Content-Type': 'multipart/form-data',
      };
      form.append('file', info.file.originFileObj);
      uploadFile(form, headers).then((file) => {
        const { applicationId } = this.props;
        editApplicationStepReport({
          id: applicationId,
          [fieldName]: file.id,
        }).then(() => {
          this.setState({ [fieldName]: file, loading: false });
          notification.success({ message: 'Сохранено!' });
        });
      });
    }
  };

  onRemoveMainFile = (fieldName) => {
    const { applicationId } = this.props;
    editApplicationStepReport({ id: applicationId, [fieldName]: null }).then(
      () => {
        this.setState({ [fieldName]: null });
        notification.success({ message: 'Сохранено!' });
      },
    );
  };

  onInputChange = () => {
    const {
      actualCostsMajor,
      actualCostsMinor,
      paperCostsMajor,
      paperCostsMinor,
    } = this.formRef.current.getFieldsValue();

    if (
      typeof actualCostsMajor === 'undefined'
      || typeof actualCostsMinor === 'undefined'
      || typeof paperCostsMajor === 'undefined'
      || typeof paperCostsMinor === 'undefined'
    ) {
      return;
    }

    const floatActual = parseFloat(`${actualCostsMajor}.${actualCostsMinor.toString().length === 1 ? `0${actualCostsMinor}` : actualCostsMinor}`);
    const floatPaper = parseFloat(`${paperCostsMajor}.${paperCostsMinor.toString().length === 1 ? `0${paperCostsMinor}` : paperCostsMinor}`);

    const diff = floatActual - floatPaper;
    const major = diff.toFixed(2).split('.')[0];
    const minor = diff.toFixed(2).split('.')[1];

    this.formRef.current.setFieldsValue({
      deviationsMajor: parseInt(major, 10),
      deviationsMinor: parseInt(minor.length === 1 ? `0${minor}` : minor, 10),
    });

    this.setState((prevState) => ({
      objectInEdit: {
        ...prevState.objectInEdit,
        deviationsMajor: parseInt(major, 10),
        deviationsMinor: parseInt(minor.length === 1 ? `0${minor}` : minor, 10),
      },
    }));
  };

  render() {
    const {
      dataSource,
      objectInEdit,
      loading,
      materialContractInEdit,
      materialActInEdit,
      materialYPDInEdit,
      materialTNInEdit,
      materialSFInEdit,
      materialPPInEdit,
      materialDSInEdit,
      materialBSNInEdit,
      materialVUPInEdit,
      materialPOVInEdit,
      materialPOZInEdit,
      checkboxValue,
      financialPaperPolicyFile,
      financialPaperAccountFile,
      materialTovNInEdit,

    } = this.state;
    const { title, isPaper, isFormDisabled } = this.props;
    return (
      <Col>
        {loading && (
          <Spin
            style={{ position: 'fixed', fontSize: '60px', top: '30%' }}
            indicator={(
              <LoadingOutlined
                style={{ fontSize: '100px', margin: '-80px 0px 0px -60px' }}
                spin
              />
            )}
            tip="Загрузка..."
          >
            <div />
          </Spin>
        )}
        <Table
          dataSource={[
            ...dataSource,
            { id: 'add', isAdd: true },
            this.getTotalRow(dataSource),
          ]}
          columns={getMaterialsColumns(
            this.onEditClick,
            this.onDeleteClick,
            this.onAddClick,
            isPaper,
            checkboxValue || isFormDisabled || loading,
          )}
          pagination={false}
          rowKey="id"
          bordered
        />
        <Checkbox
          defaultChecked={checkboxValue}
          disabled={isFormDisabled || loading}
          style={{ marginTop: '5px', color: '#1890ff' }}
          onChange={this.onChangeCheckBox}
        >
          {title}
        </Checkbox>
        {isPaper && (
          <Col style={{ marginTop: '20px' }}>
            <span style={{ color: '#212121' }}>
              Прикрепите пожалуйста скан-копию в формате pdf выписку из учетной
              политики медицинского учреждения об утвержденном методе
              распределения указанных расходов между видами деятельности
            </span>
            {!financialPaperPolicyFile && (
              <Dragger
                showUploadList={false}
                onChange={(f) => this.onUploadMainFile(f, 'financialPaperPolicyFile')}
                disabled={loading || isFormDisabled}
                multiple
                action={null}
                style={{ width: '100%' }}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Выберите или перенесите файл, чтобы загрузить (не более 15мб).
                  Для прикрепления нескольких файлов осуществляйте их загрузку
                  по очереди, а не одновременно
                </p>
              </Dragger>
            )}
            {financialPaperPolicyFile && (
              <Row style={{ margin: '5px 0px' }} gutter={12}>
                <Col>
                  <DownloadOutlined
                    style={{ cursor: 'pointer' }}
                    onClick={() => downloadFile(
                      financialPaperPolicyFile.urlLink,
                      financialPaperPolicyFile.name,
                    )}
                  />
                </Col>
                {!loading && !isFormDisabled && (
                  <Col>
                    <DeleteOutlined
                      style={{ cursor: 'pointer' }}
                      onClick={() => this.onRemoveMainFile('financialPaperPolicyFile')}
                    />
                  </Col>
                )}
                <Col>{financialPaperPolicyFile.name}</Col>
              </Row>
            )}
          </Col>
        )}
        {isPaper && (
          <Col style={{ marginTop: '20px' }}>
            <span style={{ color: '#212121' }}>
              Прикрепите пожалуйста скан-копию в формате pdf справки бухгалтера
              о распределении накладных и общехозяйственных расходов между
              источниками финансирования за отчетный период
            </span>
            {!financialPaperAccountFile && (
              <Dragger
                showUploadList={false}
                onChange={(f) => this.onUploadMainFile(f, 'financialPaperAccountFile')}
                disabled={loading || isFormDisabled}
                multiple
                action={null}
                style={{ width: '100%' }}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Выберите или перенесите файл, чтобы загрузить (не более 15мб).
                  Для прикрепления нескольких файлов осуществляйте их загрузку
                  по очереди, а не одновременно
                </p>
              </Dragger>
            )}
            {financialPaperAccountFile && (
              <Row style={{ margin: '5px 0px' }} gutter={12}>
                <Col>
                  <DownloadOutlined
                    style={{ cursor: 'pointer' }}
                    onClick={() => downloadFile(
                      financialPaperAccountFile.urlLink,
                      financialPaperAccountFile.name,
                    )}
                  />
                </Col>
                {!loading && !isFormDisabled && (
                  <Col>
                    <DeleteOutlined
                      style={{ cursor: 'pointer' }}
                      onClick={() => this.onRemoveMainFile('financialPaperAccountFile')}
                    />
                  </Col>
                )}
                <Col>{financialPaperAccountFile.name}</Col>
              </Row>
            )}
          </Col>
        )}
        {objectInEdit && (
          <Drawer
            title="Редактирование расхода"
            width="75vw"
            visible
            onClose={this.onCloseDrawer}
            bodyStyle={{
              paddingBottom: 80,
            }}
            extra={(
              <Space>
                <Button onClick={this.onCloseDrawer}>Отменить</Button>
                <Button onClick={this.onSaveDrawer} type="primary">
                  Сохранить
                </Button>
              </Space>
            )}
          >
            <Form initialValues={{ remember: true }} ref={this.formRef}>
              <Col>
                <Form.Item
                  name="id"
                  hidden
                  initialValue={objectInEdit && objectInEdit.id}
                />
                <div className="application-main-box-content-wrapper">
                  <span className="application-main-box-label">
                    Предмет договора:
                  </span>
                  <Form.Item
                    name="name"
                    rules={[{ required: true, message: 'Обязательное поле' }]}
                    initialValue={objectInEdit && objectInEdit.name}
                  >
                    <Input />
                  </Form.Item>
                </div>
                <div className="application-main-box-content-wrapper">
                  <span className="application-main-box-label">
                    Фактические расходы:
                  </span>
                  <Row align="bottom" gutter={8}>
                    <Col>
                      <Form.Item
                        name="actualCostsMajor"
                        rules={[
                          { required: true, message: 'Обязательное поле' },
                        ]}
                        initialValue={
                          objectInEdit && objectInEdit.actualCostsMajor
                        }
                      >
                        <InputNumber
                          onChange={this.onInputChange}
                          style={{ width: '200px' }}
                        />
                      </Form.Item>
                    </Col>
                    <Col>руб.</Col>
                    <Col>
                      <Form.Item
                        name="actualCostsMinor"
                        rules={[
                          { required: true, message: 'Обязательное поле' },
                        ]}
                        initialValue={
                          objectInEdit && objectInEdit.actualCostsMinor
                        }
                      >
                        <InputNumber onChange={this.onInputChange} />
                      </Form.Item>
                    </Col>
                    <Col>коп.</Col>
                  </Row>
                </div>
                <div className="application-main-box-content-wrapper">
                  <span className="application-main-box-label">
                    Кассовые расходы:
                  </span>
                  <Row align="bottom" gutter={8}>
                    <Col>
                      <Form.Item
                        name="paperCostsMajor"
                        rules={[
                          { required: true, message: 'Обязательное поле' },
                        ]}
                        initialValue={
                          objectInEdit && objectInEdit.paperCostsMajor
                        }
                      >
                        <InputNumber
                          onChange={this.onInputChange}
                          style={{ width: '200px' }}
                        />
                      </Form.Item>
                    </Col>
                    <Col>руб.</Col>
                    <Col>
                      <Form.Item
                        name="paperCostsMinor"
                        rules={[
                          { required: true, message: 'Обязательное поле' },
                        ]}
                        initialValue={
                          objectInEdit && objectInEdit.paperCostsMinor
                        }
                      >
                        <InputNumber onChange={this.onInputChange} />
                      </Form.Item>
                    </Col>
                    <Col>коп.</Col>
                  </Row>
                </div>
                <div className="application-main-box-content-wrapper">
                  <span className="application-main-box-label">
                    Отклонение:
                  </span>
                  <Row align="bottom" gutter={8}>
                    <Col>
                      <Form.Item
                        name="deviationsMajor"
                        rules={[
                          { required: true, message: 'Обязательное поле' },
                        ]}
                        initialValue={
                          objectInEdit && objectInEdit.deviationsMajor
                        }
                      >
                        <InputNumber
                          value={objectInEdit?.deviationsMajor}
                          style={{ width: '200px' }}
                        />
                      </Form.Item>
                    </Col>
                    <Col>руб.</Col>
                    <Col>
                      <Form.Item
                        name="deviationsMinor"
                        rules={[
                          { required: true, message: 'Обязательное поле' },
                        ]}
                        initialValue={
                          objectInEdit && objectInEdit.deviationsMinor
                        }
                        value={objectInEdit?.deviationsMinor}
                      >
                        <InputNumber value={objectInEdit?.deviationsMinor} />
                      </Form.Item>
                    </Col>
                    <Col>коп.</Col>
                  </Row>
                </div>
                {isPaper && (
                  <div className="application-main-box-content-wrapper">
                    <span className="application-main-box-label">
                      За счет средств гранта, выделенных на выполнение задания:
                    </span>
                    <Row align="bottom" gutter={8}>
                      <Col>
                        <Form.Item
                          name="grantSumMajor"
                          rules={[
                            { required: true, message: 'Обязательное поле' },
                          ]}
                          initialValue={
                            objectInEdit && objectInEdit.grantSumMajor
                          }
                        >
                          <InputNumber style={{ width: '200px' }} />
                        </Form.Item>
                      </Col>
                      <Col>руб.</Col>
                      <Col>
                        <Form.Item
                          name="grantSumMinor"
                          rules={[
                            { required: true, message: 'Обязательное поле' },
                          ]}
                          initialValue={
                            objectInEdit && objectInEdit.grantSumMinor
                          }
                        >
                          <InputNumber />
                        </Form.Item>
                      </Col>
                      <Col>коп.</Col>
                    </Row>
                  </div>
                )}
                <Divider />
                <div
                  style={{
                    color: '#212121',
                    fontSize: '18px',
                    marginLeft: '20px',
                  }}
                >
                  Документы подтверждающие расходы
                </div>

                <Col style={{ padding: '15px 20px' }}>
                  <div style={{ color: '#212121' }}>Договор:</div>
                  {objectInEdit && !objectInEdit.materialContract && (
                    <Button
                      type="primary"
                      onClick={() => this.setState({
                        materialContractInEdit: {
                          formRef: React.createRef(),
                        },
                      })}
                    >
                      Добавить
                    </Button>
                  )}
                  {objectInEdit && objectInEdit.materialContract && (
                    <Row gutter={5}>
                      <Col
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.setState({
                          materialContractInEdit: {
                            ...objectInEdit.materialContract,
                            formRef: React.createRef(),
                          },
                        })}
                      >
                        <EditOutlined />
                      </Col>
                      <Col style={{ cursor: 'pointer' }}>
                        <Popconfirm
                          title="Вы действительно хотите удалить?"
                          onConfirm={() => this.onDeleteDocument('materialContract')}
                          okText="Да, удалить"
                          cancelText="Нет"
                        >
                          <DeleteOutlined />
                        </Popconfirm>
                      </Col>
                      <Col>{`Договор ${objectInEdit.materialContract.name}`}</Col>
                    </Row>
                  )}
                </Col>
                <Col style={{ padding: '15px 20px' }} span={24}>
                  <Row>
                    <div style={{ color: '#212121' }}>
                      Дополнительное соглашение:
                    </div>
                  </Row>
                  <Row>
                    <Checkbox
                      defaultChecked={objectInEdit.isNoAdditionalAgreement}
                      value={objectInEdit.isNoAdditionalAgreement}
                      onChange={this.onChangeIsNoAdditionalAgreement}
                    >
                      Дополнительное соглашение не заключалось
                    </Checkbox>
                  </Row>
                  {objectInEdit && !objectInEdit.isNoAdditionalAgreement && (
                    <Button
                      type="primary"
                      onClick={() => this.setState({
                        materialDSInEdit: { formRef: React.createRef() },
                      })}
                    >
                      Добавить
                    </Button>
                  )}
                  {objectInEdit
                    && objectInEdit.materialDS
                    && objectInEdit.materialDS.map((m) => (
                      <Row gutter={5}>
                        <Col
                          style={{ cursor: 'pointer' }}
                          onClick={() => this.setState({
                            materialDSInEdit: {
                              ...m,
                              formRef: React.createRef(),
                            },
                          })}
                        >
                          <EditOutlined />
                        </Col>
                        <Col style={{ cursor: 'pointer' }}>
                          <Popconfirm
                            title="Вы действительно хотите удалить?"
                            onConfirm={() => this.onDeleteDocument('materialDS', m.id)}
                            okText="Да, удалить"
                            cancelText="Нет"
                          >
                            <DeleteOutlined />
                          </Popconfirm>
                        </Col>
                        <Col>{`Дополнительное соглашение ${m.name}`}</Col>
                      </Row>
                    ))}
                </Col>

                <Divider />
                <div
                  style={{
                    color: '#212121',
                    fontSize: '18px',
                    marginLeft: '20px',
                  }}
                >
                  Закрывающие документы
                </div>

                <div style={{
                    color: '#212121',
                    fontSize: '14px',
                    marginLeft: '20px',
                    marginTop: '4px'
                  }}>
                    Пожалуйста, предоставьте закрывающие документы, предусмотренные в Договоре
                  </div>

                <Col style={{ padding: '15px 20px' }}>
                
                  <Row style={{ marginBottom: '10px' }}>
                    <Checkbox
                      defaultChecked={objectInEdit.supportStaff}
                      value={objectInEdit.supportStaff}
                      onChange={this.onChangeSupportStaff}
                    >
                      Для обоснования затрат на вспомогательный персонал
                    </Checkbox>
                  </Row>


                  {objectInEdit.supportStaff && (
                    <>
                      <Col style={{ padding: '15px 0' }} span={24}>
                        <Row>
                          <div style={{ color: '#212121' }}>
                            Бухгалтерская справка о начислениях
                          </div>
                        </Row>
                        <Button
                          type="primary"
                          onClick={() => this.setState({
                            materialBSNInEdit: { formRef: React.createRef() },
                          })}
                        >
                          Добавить
                        </Button>
                        {objectInEdit
                          && objectInEdit.materialBSN
                          && objectInEdit.materialBSN?.map((m) => (
                            <Row gutter={5}>
                              <Col
                                style={{ cursor: 'pointer' }}
                                onClick={() => this.setState({
                                  materialDSInEdit: {
                                    ...m,
                                    formRef: React.createRef(),
                                  },
                                })}
                              >
                                <EditOutlined />
                              </Col>
                              <Col style={{ cursor: 'pointer' }}>
                                <Popconfirm
                                  title="Вы действительно хотите удалить?"
                                  onConfirm={() => this.onDeleteDocument('materialBSN', m.id)}
                                  okText="Да, удалить"
                                  cancelText="Нет"
                                >
                                  <DeleteOutlined />
                                </Popconfirm>
                              </Col>
                              <Col>{`Бухгалтерская справка о начислениях ${m.name}`}</Col>
                            </Row>
                          ))}
                      </Col>

                      <Col style={{ padding: '15px 0' }} span={24}>
                        <Row>
                          <div style={{ color: '#212121' }}>
                            Выписка из учетной политики
                          </div>
                        </Row>
                        <Button
                          type="primary"
                          onClick={() => this.setState({
                            materialVUPInEdit: { formRef: React.createRef() },
                          })}
                        >
                          Добавить
                        </Button>
                        {objectInEdit
                          && objectInEdit.materialVUP
                          && objectInEdit.materialVUP?.map((m) => (
                            <Row gutter={5}>
                              <Col
                                style={{ cursor: 'pointer' }}
                                onClick={() => this.setState({
                                  materialDSInEdit: {
                                    ...m,
                                    formRef: React.createRef(),
                                  },
                                })}
                              >
                                <EditOutlined />
                              </Col>
                              <Col style={{ cursor: 'pointer' }}>
                                <Popconfirm
                                  title="Вы действительно хотите удалить?"
                                  onConfirm={() => this.onDeleteDocument('materialVUP', m.id)}
                                  okText="Да, удалить"
                                  cancelText="Нет"
                                >
                                  <DeleteOutlined />
                                </Popconfirm>
                              </Col>
                              <Col>{`Выписка из учетной политики ${m.name}`}</Col>
                            </Row>
                          ))}
                      </Col>
                    </>
                  )}

                  <div style={{ color: '#212121' }}>Платежное поручение</div>
                {objectInEdit && !objectInEdit.isNoPP && (
                  <Button
                    type="primary"
                    onClick={() => this.setState({
                      materialPPInEdit: { formRef: React.createRef() },
                    })}
                  >
                    Добавить
                  </Button>
                )}
                <Row>
                  <Checkbox
                    defaultChecked={objectInEdit.isNoPP}
                    value={objectInEdit.isNoPP}
                    onChange={this.onChangeisNoPP}
                  >
                    Не предусмотрено Договором
                  </Checkbox>
                </Row>
                {objectInEdit
                  && objectInEdit.materialPP
                  && objectInEdit.materialPP.map((m) => (
                    <Row gutter={5}>
                      <Col
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.setState({
                          materialPPInEdit: {
                            ...m,
                            formRef: React.createRef(),
                          },
                        })}
                      >
                        <EditOutlined />
                      </Col>
                      <Col style={{ cursor: 'pointer' }}>
                        <Popconfirm
                          title="Вы действительно хотите удалить?"
                          onConfirm={() => this.onDeleteDocument('materialPP', m.id)}
                          okText="Да, удалить"
                          cancelText="Нет"
                        >
                          <DeleteOutlined />
                        </Popconfirm>
                      </Col>
                      <Col>{`Платежное поручение ${m.name}`}</Col>
                    </Row>
                  ))}
              </Col>

              {objectInEdit.supportStaff && (
                <>
                  <Col style={{ padding: '15px 20px' }} span={24}>
                    <Row>
                      <div style={{ color: '#212121' }}>
                        Приказ организации о задействовании сотрудника(-ов) в работе по данному грантовому проекту
                      </div>
                    </Row>
                    <Button
                      type="primary"
                      onClick={() => this.setState({
                        materialPOZInEdit: { formRef: React.createRef() },
                      })}
                    >
                      Добавить
                    </Button>
                    {objectInEdit
                      && objectInEdit.materialPOZ
                      && objectInEdit.materialPOZ?.map((m) => (
                        <Row gutter={5}>
                          <Col
                            style={{ cursor: 'pointer' }}
                            onClick={() => this.setState({
                              materialDSInEdit: {
                                ...m,
                                formRef: React.createRef(),
                              },
                            })}
                          >
                            <EditOutlined />
                          </Col>
                          <Col style={{ cursor: 'pointer' }}>
                            <Popconfirm
                              title="Вы действительно хотите удалить?"
                              onConfirm={() => this.onDeleteDocument('materialPOZ', m.id)}
                              okText="Да, удалить"
                              cancelText="Нет"
                            >
                              <DeleteOutlined />
                            </Popconfirm>
                          </Col>
                          <Col>{`Приказ организации о задействовании сотрудника(-ов) в работе по данному грантовому проекту ${m.name}`}</Col>
                        </Row>
                      ))}
                  </Col>

                  <Col style={{ padding: '15px 20px' }} span={24}>
                    <Row>
                      <div style={{ color: '#212121' }}>
                        Приказ организации о выплатах в адрес задействованого(-ых) сотрудника(-ов) в работе по данному грантовому проекту
                      </div>
                    </Row>
                    <Button
                      type="primary"
                      onClick={() => this.setState({
                        materialPOVInEdit: { formRef: React.createRef() },
                      })}
                    >
                      Добавить
                    </Button>
                    {objectInEdit
                      && objectInEdit.materialPOV
                      && objectInEdit.materialPOV?.map((m) => (
                        <Row gutter={5}>
                          <Col
                            style={{ cursor: 'pointer' }}
                            onClick={() => this.setState({
                              materialDSInEdit: {
                                ...m,
                                formRef: React.createRef(),
                              },
                            })}
                          >
                            <EditOutlined />
                          </Col>
                          <Col style={{ cursor: 'pointer' }}>
                            <Popconfirm
                              title="Вы действительно хотите удалить?"
                              onConfirm={() => this.onDeleteDocument('materialPOV', m.id)}
                              okText="Да, удалить"
                              cancelText="Нет"
                            >
                              <DeleteOutlined />
                            </Popconfirm>
                          </Col>
                          <Col>{`Приказ организации о выплатах в адрес задействованого(-ых) сотрудника(-ов) в работе по данному грантовому проекту ${m.name}`}</Col>
                        </Row>
                      ))}
                  </Col>
                </>
              )}

              {!objectInEdit.supportStaff && (
                <>

              <Col style={{ padding: '15px 20px' }}>
                <div style={{ color: '#212121' }}>Акт:</div>
                {objectInEdit && !objectInEdit.materialAct && !objectInEdit.isNoAct && (
                  <Button
                    type="primary"
                    onClick={() => this.setState({
                      materialActInEdit: { formRef: React.createRef() },
                    })}
                  >
                    Добавить
                  </Button>
                )}
                <Row>
                  <Checkbox
                    defaultChecked={objectInEdit.isNoAct}
                    value={objectInEdit.isNoAct}
                    onChange={this.onChangeisNoAct}
                  >
                    Не предусмотрено Договором
                  </Checkbox>
                </Row>
                {objectInEdit && objectInEdit.materialAct && (
                  <Row gutter={5}>
                    <Col
                      style={{ cursor: 'pointer' }}
                      onClick={() => this.setState({
                        materialActInEdit: {
                          ...objectInEdit.materialAct,
                          formRef: React.createRef(),
                        },
                      })}
                    >
                      <EditOutlined />
                    </Col>
                    <Col style={{ cursor: 'pointer' }}>
                      <Popconfirm
                        title="Вы действительно хотите удалить?"
                        onConfirm={() => this.onDeleteDocument('materialAct')}
                        okText="Да, удалить"
                        cancelText="Нет"
                      >
                        <DeleteOutlined />
                      </Popconfirm>
                    </Col>
                    <Col>{`Акт ${objectInEdit.materialAct.name}`}</Col>
                  </Row>
                )}
              </Col>
              <Col style={{ padding: '15px 20px' }}>
                <div style={{ color: '#212121' }}>УПД:</div>
                {objectInEdit && !objectInEdit.isDeliveryBill && !objectInEdit.isNoUpd && (
                    <Button
                      type="primary"
                      onClick={() => this.setState({
                        materialYPDInEdit: { formRef: React.createRef() },
                      })}
                    >
                      Добавить
                    </Button>
                  )}
                                  <Row>
                  <Checkbox
                    defaultChecked={objectInEdit.isNoUpd}
                    value={objectInEdit.isNoUpd}
                    onChange={this.onChangeisNoUpd}
                  >
                    Не предусмотрено Договором
                  </Checkbox>
                </Row>
                <Row>
                  <Checkbox
                    defaultChecked={objectInEdit.isDeliveryBill}
                    value={objectInEdit.isDeliveryBill}
                    onChange={this.onChangeIsDeliveryBill}
                  >
                    Предусмотрена товарная накладная
                  </Checkbox>
                </Row>
                {objectInEdit
                      && objectInEdit.materialYPD
                      && objectInEdit.materialYPD?.map((m) => (
                        <Row gutter={5}>
                          <Col
                            style={{ cursor: 'pointer' }}
                            onClick={() => this.setState({
                              materialDSInEdit: {
                                ...m,
                                formRef: React.createRef(),
                              },
                            })}
                          >
                            <EditOutlined />
                          </Col>
                          <Col style={{ cursor: 'pointer' }}>
                            <Popconfirm
                              title="Вы действительно хотите удалить?"
                              onConfirm={() => this.onDeleteDocument('materialYPD', m.id)}
                              okText="Да, удалить"
                              cancelText="Нет"
                            >
                              <DeleteOutlined />
                            </Popconfirm>
                          </Col>
                          <Col>{`УПД ${m.name}`}</Col>
                        </Row>
                      ))}
              </Col>

              {objectInEdit && objectInEdit.isDeliveryBill && (
                <Col style={{ padding: '15px 20px' }}>
                  <div style={{ color: '#212121' }}>Счет-фактура:</div>
                  {objectInEdit
                    && !objectInEdit.materialSF
                    && !objectInEdit.excludingVAT && !objectInEdit.isNoSF && (
                      <Button
                        type="primary"
                        onClick={() => this.setState({
                          materialSFInEdit: { formRef: React.createRef() },
                        })}
                      >
                        Добавить
                      </Button>
                    )}
                    <Row>
                    <Checkbox
                      defaultChecked={objectInEdit.isNoSF}
                      value={objectInEdit.isNoSF}
                      onChange={this.onChangeisNoSF}
                    >
                      Не предусмотрено Договором
                    </Checkbox>
                  </Row>
                  <Row>
                    <Checkbox
                      defaultChecked={objectInEdit.excludingVAT}
                      value={objectInEdit.excludingVAT}
                      onChange={this.onChangeExcludingVAT}
                    >
                      Не облагается НДС
                    </Checkbox>
                  </Row>
                  {objectInEdit && objectInEdit.materialSF && (
                    <Row gutter={5}>
                      <Col
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.setState({
                          materialSFInEdit: {
                            ...objectInEdit.materialSF,
                            formRef: React.createRef(),
                          },
                        })}
                      >
                        <EditOutlined />
                      </Col>
                      <Col style={{ cursor: 'pointer' }}>
                        <Popconfirm
                          title="Вы действительно хотите удалить?"
                          onConfirm={() => this.onDeleteDocument('materialSF')}
                          okText="Да, удалить"
                          cancelText="Нет"
                        >
                          <DeleteOutlined />
                        </Popconfirm>
                      </Col>
                      <Col>{`СФ ${objectInEdit.materialSF.name}`}</Col>
                    </Row>
                  )}
                </Col>
              )}

              {objectInEdit && objectInEdit.isDeliveryBill && (
                <Col style={{ padding: '15px 20px' }}>
                  <div style={{ color: '#212121' }}>Товарная накладная:</div>
                  {objectInEdit && !objectInEdit.materialTovN && !objectInEdit.isNoTovN && (
                    <Button
                      type="primary"
                      onClick={() => this.setState({
                        materialTovNInEdit: { formRef: React.createRef() },
                      })}
                    >
                      Добавить
                    </Button>
                  )}
                 <Row>
                  <Checkbox
                    defaultChecked={objectInEdit.isNoTovN}
                    value={objectInEdit.isNoTovN}
                    onChange={this.onChangeisNoTovN}
                  >
                    Не предусмотрено Договором
                  </Checkbox>
                </Row>
                  {objectInEdit && objectInEdit.materialTovN && (
                    <Row gutter={5}>
                      <Col
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.setState({
                          materialTovNInEdit: {
                            ...objectInEdit.materialTovN,
                            formRef: React.createRef(),
                          },
                        })}
                      >
                        <EditOutlined />
                      </Col>
                      <Col style={{ cursor: 'pointer' }}>
                        <Popconfirm
                          title="Вы действительно хотите удалить?"
                          onConfirm={() => this.onDeleteDocument('materialTovN')}
                          okText="Да, удалить"
                          cancelText="Нет"
                        >
                          <DeleteOutlined />
                        </Popconfirm>
                      </Col>
                      <Col>{`СФ ${objectInEdit.materialTovN?.name}`}</Col>
                    </Row>
                  )}
                </Col>
              )}

              <Col style={{ padding: '15px 20px' }}>
                <div style={{ color: '#212121' }}>
                  Транспортная накладная:
                </div>
                {objectInEdit
                  && !objectInEdit.materialTN
                  && !objectInEdit.isNotDeliveryBill && (
                    <Button
                      type="primary"
                      onClick={() => this.setState({
                        materialTNInEdit: { formRef: React.createRef() },
                      })}
                    >
                      Добавить
                    </Button>
                  )}
                <Row>
                  <Checkbox
                    defaultChecked={objectInEdit.isNotDeliveryBill}
                    value={objectInEdit.isNotDeliveryBill}
                    onChange={this.onChangeisNotDeliveryBill}
                  >
                    Не предусмотрено Договором
                  </Checkbox>
                </Row>
                {objectInEdit && objectInEdit.materialTN && (
                  <Row gutter={5}>
                    <Col
                      style={{ cursor: 'pointer' }}
                      onClick={() => this.setState({
                        materialTNInEdit: {
                          ...objectInEdit.materialTN,
                          formRef: React.createRef(),
                        },
                      })}
                    >
                      <EditOutlined />
                    </Col>
                    <Col style={{ cursor: 'pointer' }}>
                      <Popconfirm
                        title="Вы действительно хотите удалить?"
                        onConfirm={() => this.onDeleteDocument('materialTN')}
                        okText="Да, удалить"
                        cancelText="Нет"
                      >
                        <DeleteOutlined />
                      </Popconfirm>
                    </Col>
                    <Col>{`ТН ${objectInEdit.materialTN.name}`}</Col>
                  </Row>
                )}
              </Col>
              </>)}
            </Col>
          </Form>
          </Drawer>
    )
  }
        {
  materialContractInEdit && (
    <MaterialsContract
      object={materialContractInEdit}
      onClose={() => this.setState({ materialContractInEdit: null })}
      onRemoveFile={this.onRemoveFile}
      onUploadFile={this.onUploadFile}
      onSave={this.onSaveModal}
    />
  )
}
{
  materialActInEdit && (
    <MaterialsAct
      object={materialActInEdit}
      onClose={() => this.setState({ materialActInEdit: null })}
      onRemoveFile={this.onRemoveFile}
      onUploadFile={this.onUploadFile}
      onSave={this.onSaveModal}
    />
  )
}
{
  materialYPDInEdit && (
    <MaterialsYPD
      object={materialYPDInEdit}
      onClose={() => this.setState({ materialYPDInEdit: null })}
      onRemoveFile={this.onRemoveFile}
      onUploadFile={this.onUploadFile}
      onSave={this.onSaveModal}
    />
  )
}
{
  materialTNInEdit && (
    <MaterialsTN
      object={materialTNInEdit}
      onClose={() => this.setState({ materialTNInEdit: null })}
      onRemoveFile={this.onRemoveFile}
      onUploadFile={this.onUploadFile}
      onSave={this.onSaveModal}
    />
  )
}
{
  materialTovNInEdit && (
    <MaterialsTovN
      object={materialTovNInEdit}
      onClose={() => this.setState({ materialTovNInEdit: null })}
      onRemoveFile={this.onRemoveFile}
      onUploadFile={this.onUploadFile}
      onSave={this.onSaveModal}
    />
  )
}
{
  materialSFInEdit && (
    <MaterialsSF
      object={materialSFInEdit}
      onClose={() => this.setState({ materialSFInEdit: null })}
      onRemoveFile={this.onRemoveFile}
      onUploadFile={this.onUploadFile}
      onSave={this.onSaveModal}
    />
  )
}
{
  materialPPInEdit && (
    <MaterialsPP
      object={materialPPInEdit}
      onClose={() => this.setState({ materialPPInEdit: null })}
      onRemoveFile={this.onRemoveFile}
      onUploadFile={this.onUploadFile}
      onSave={this.onSaveModal}
    />
  )
}
{
  materialDSInEdit && (
    <MaterialsDS
      object={materialDSInEdit}
      onClose={() => this.setState({ materialDSInEdit: null })}
      onRemoveFile={this.onRemoveFile}
      onUploadFile={this.onUploadFile}
      onSave={this.onSaveModal}
    />
  )
}

{
  materialBSNInEdit && (
    <MaterialsBSN
      object={materialBSNInEdit}
      onClose={() => this.setState({ materialBSNInEdit: null })}
      onRemoveFile={this.onRemoveFile}
      onUploadFile={this.onUploadFile}
      onSave={this.onSaveModal}
    />
  )
}

{
  materialVUPInEdit && (
    <MaterialsVUP
      object={materialVUPInEdit}
      onClose={() => this.setState({ materialVUPInEdit: null })}
      onRemoveFile={this.onRemoveFile}
      onUploadFile={this.onUploadFile}
      onSave={this.onSaveModal}
    />
  )
}

{
  materialPOVInEdit && (
    <MaterialsPOV
      object={materialPOVInEdit}
      onClose={() => this.setState({ materialPOVInEdit: null })}
      onRemoveFile={this.onRemoveFile}
      onUploadFile={this.onUploadFile}
      onSave={this.onSaveModal}
    />
  )
}

{
  materialPOZInEdit && (
    <MaterialsPOZ
      object={materialPOZInEdit}
      onClose={() => this.setState({ materialPOZInEdit: null })}
      onRemoveFile={this.onRemoveFile}
      onUploadFile={this.onUploadFile}
      onSave={this.onSaveModal}
    />
  )
}
      </Col >
    );
  }
}

Materials.propTypes = {
  title: PropTypes.string.isRequired,
  applicationId: PropTypes.string.isRequired,
  dataSource: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  checkboxName: PropTypes.string.isRequired,
  checkboxValue: PropTypes.bool,
  isMaterial: PropTypes.bool,
  isEquipment: PropTypes.bool,
  isOther: PropTypes.bool,
  isPaper: PropTypes.bool,
  financialPaperPolicyFile: PropTypes.shape(),
  financialPaperAccountFile: PropTypes.shape(),
  isFormDisabled: PropTypes.bool.isRequired,
};

Materials.defaultProps = {
  checkboxValue: null,
  isMaterial: null,
  isEquipment: null,
  isOther: null,
  isPaper: null,
  financialPaperPolicyFile: null,
  financialPaperAccountFile: null,
};

export default Materials;

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  Drawer, Form, Input, Button, Select,
} from 'antd';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import locales from '../../../../../../../utils/i18n/messages/texts';
import getUserId from '../../../../../../../utils/localeStorage/getUserId';
import usersRoles from '../usersRoles';
import { routeGenerator } from '../../../../../../../utils/routes';


const { Option } = Select;
const EditUserDrawer = ({
  selectedUser, onClose, onSave, allApplications,
}) => {
  const [form] = Form.useForm();
  const [role, setRole] = useState();
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    setRole(selectedUser.role);
  }, []);

  const onOk = () => {
    form.submit();
  };

  const onCancel = () => setIsEdit(false);
  const onEdit = () => setIsEdit(true);

  const onGeneratePassword = () => {
    form.setFieldsValue({ passwordView: uuidv4().substring(0, 8) });
  };

  const isNewUser = !selectedUser.id;

  useEffect(() => {
    if (isNewUser) {
      setIsEdit(true);
    }
  }, []);

  const handleEmailChange = () => {
    if (isNewUser) {
      form.setFieldsValue({ id: form.getFieldValue('email') });
    }
  };

  return (
    <Drawer
      title={<FormattedMessage id={locales.editUserDrawerTitle.id} />}
      placement="right"
      width={500}
      destroyOnClose
      closable={false}
      maskClosable={false}
      onClose={onClose}
      visible
      footer={isEdit ? (
        <div style={{ textAlign: 'right' }}>
          <Button onClick={onCancel} style={{ marginRight: 8 }}>
            Отмена
          </Button>
          <Button onClick={onOk} type="primary">
            Сохранить
          </Button>
        </div>
      ) : (
        <div style={{ textAlign: 'right' }}>
          <Button onClick={onClose} style={{ marginRight: 8 }}>
            Закрыть
          </Button>
          <Button onClick={onEdit} type="primary">
            Редактировать
          </Button>
        </div>
      )}
    >
      <Form form={form} layout="vertical" name="editUserForm" onFinish={onSave}>
        <Form.Item
          label="Логин пользователя (заполняется автоматически)"
          name="id"
          initialValue={selectedUser.id}
        >
          <Input style={{ marginBottom: '20px' }} disabled />
        </Form.Item>
        {(getUserId() === 'КузнецоваАМ' || selectedUser.role !== 'bigAdmin') && (
        <Form.Item
          label="Роль"
          name="role"
          initialValue={selectedUser.role}
          rules={[{ required: true }]}
        >
          <Select style={{ marginBottom: '20px' }} onChange={setRole} disabled={!isEdit}>
            {getUserId() === 'КузнецоваАМ'
              ? usersRoles.map((u) => <Option key={u.value} value={u.value}>{u.label}</Option>)
              : usersRoles.filter((e) => e.value !== 'bigAdmin').map((u) => <Option key={u.value} value={u.value}>{u.label}</Option>)}
          </Select>
        </Form.Item>
        )}
        <Form.Item
          label="Название учреждения (или ФИО полностью)"
          name="shortName"
          initialValue={selectedUser.shortName}
          rules={[{ required: true }]}
        >
          <Input style={{ marginBottom: '20px' }} disabled={!isEdit} />
        </Form.Item>
        <Form.Item
          label={(
            <div style={{ display: 'flex' }}>
              <div style={{ marginRight: '10px' }}>Пароль</div>
              {isEdit && <a onClick={onGeneratePassword}>Сгенерировать случайный</a>}
            </div>
)}
          name="passwordView"
          initialValue={selectedUser.passwordView}
          rules={[{ required: true }]}
        >
          <Input style={{ marginBottom: '20px' }} disabled={!isEdit} />
        </Form.Item>
        <Form.Item
          label="Полное название организации"
          name="company"
          initialValue={selectedUser.company}
        >
          <Input style={{ marginBottom: '20px' }} disabled={!isEdit} />
        </Form.Item>
        <Form.Item
          label="Адрес организации (для корреспонденции)"
          name="legalAddress"
          initialValue={selectedUser.legalAddress}
        >
          <Input style={{ marginBottom: '20px' }} disabled={!isEdit} />
        </Form.Item>
        <div style={{ fontSize: '18px' }}>Руководитель</div>
        <Form.Item
          label="Фамилия"
          name="lastName"
          initialValue={selectedUser.lastName}
        >
          <Input style={{ marginBottom: '20px' }} disabled={!isEdit} />
        </Form.Item>
        <Form.Item
          label="Имя"
          name="firstName"
          initialValue={selectedUser.firstName}
        >
          <Input style={{ marginBottom: '20px' }} disabled={!isEdit} />
        </Form.Item>
        <Form.Item
          label="Отчетство"
          name="middleName"
          initialValue={selectedUser.middleName}
        >
          <Input style={{ marginBottom: '20px' }} disabled={!isEdit} />
        </Form.Item>
        <Form.Item
          label="Должность"
          name="position"
          initialValue={selectedUser.position}
        >
          <Input style={{ marginBottom: '20px' }} disabled={!isEdit} />
        </Form.Item>
        <Form.Item
          label="Телефон"
          name="phone"
          initialValue={selectedUser.phone}
        >
          <Input style={{ marginBottom: '20px' }} disabled={!isEdit} />
        </Form.Item>
        <Form.Item
          label="E-mail"
          name="email"
          initialValue={selectedUser.email}
          rules={[{ required: true }]}
        >
          <Input style={{ marginBottom: '20px' }} onChange={handleEmailChange} disabled={!isEdit} />
        </Form.Item>
        <div style={{ fontSize: '18px' }}>Ответственный сотрудник</div>
        <Form.Item
          label="Фамилия"
          name="contactLastName"
          initialValue={selectedUser.contactLastName}
        >
          <Input style={{ marginBottom: '20px' }} disabled={!isEdit} />
        </Form.Item>
        <Form.Item
          label="Имя"
          name="contactFirstName"
          initialValue={selectedUser.contactFirstName}
        >
          <Input style={{ marginBottom: '20px' }} disabled={!isEdit} />
        </Form.Item>
        <Form.Item
          label="Отчетство"
          name="contactMiddleName"
          initialValue={selectedUser.contactMiddleName}
        >
          <Input style={{ marginBottom: '20px' }} disabled={!isEdit} />
        </Form.Item>
        <Form.Item
          label="Должность"
          name="contactPosition"
          initialValue={selectedUser.contactPosition}
        >
          <Input style={{ marginBottom: '20px' }} disabled={!isEdit} />
        </Form.Item>
        <Form.Item
          label="Телефон"
          name="contactPhone"
          initialValue={selectedUser.contactPhone}
        >
          <Input style={{ marginBottom: '20px' }} disabled={!isEdit} />
        </Form.Item>
        <Form.Item
          label="E-mail"
          name="contactEmail"
          initialValue={selectedUser.contactEmail}
        >
          <Input style={{ marginBottom: '20px' }} disabled={!isEdit} />
        </Form.Item>
        {(role !== 'expertDZM' && role !== 'expertFederal') && (
          <div style={{ fontSize: '18px' }}>Заявки</div>
        )}
        {(role !== 'expertDZM' && role !== 'expertFederal') && !isEdit && (
          <div>
            <div>Заявки 2022</div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {allApplications
                .filter((app) => !app.isGrants2023 && selectedUser?.applications2022?.map((a) => a.id).includes(app.id))
                .map((u) => <a onClick={() => window.open(routeGenerator.applicationLink(u.id), '_blank')}>{u.displayID}</a>)}
            </div>
          </div>
        )}
        {(role !== 'expertDZM' && role !== 'expertFederal') && !isEdit && (
        <div style={{ marginTop: '20px' }}>
          <div>Заявки 2023</div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {allApplications
              .filter((app) => app.isGrants2023 && selectedUser?.applications2023?.map((a) => a.id).includes(app.id))
              .map((u) => <a onClick={() => window.open(routeGenerator.applicationLink(u.id), '_blank')}>{u.displayID}</a>)}
          </div>
        </div>
        )}
        {(role !== 'expertDZM' && role !== 'expertFederal') && isEdit && (
          <Form.Item
            label="Заявки 2022"
            name="applications2022"
            initialValue={selectedUser?.applications2022?.map((app) => app.id)}
          >
            <Select mode="multiple" style={{ marginBottom: '20px' }}>
              {allApplications.filter((app) => !app.isGrants2023).map((u) => <Option key={u.id} value={u.id}>{u.displayID}</Option>)}
            </Select>
          </Form.Item>
        )}
        {(role !== 'expertDZM' && role !== 'expertFederal') && isEdit && (
        <Form.Item
          label="Заявки 2023"
          name="applications2023"
          initialValue={selectedUser?.applications2023?.map((app) => app.id)}
        >
          <Select mode="multiple" style={{ marginBottom: '20px' }}>
            {allApplications.filter((app) => app.isGrants2023).map((u) => <Option key={u.id} value={u.id}>{u.displayID}</Option>)}
          </Select>
        </Form.Item>
        )}
      </Form>
    </Drawer>
  );
};

EditUserDrawer.propTypes = {
  selectedUser: PropTypes.shape().isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  allApplications: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default EditUserDrawer;

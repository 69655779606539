/* eslint-disable consistent-return */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
/* eslint-disable max-len */
import React, { useRef, useState, useEffect } from 'react';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import PropTypes from 'prop-types';
import {
  Col,
  Button,
  Form,
  Input,
  Row,
  Radio,
  Checkbox,
  notification,
  DatePicker,
  Modal,
  Popconfirm,
} from 'antd';
import { PrinterOutlined } from '@ant-design/icons';
import moment from 'moment';
import getUserRole from '../../../../utils/localeStorage/getUserRole';
import {
  additionalFiles, checkBoxOptions, radioOptions, validateForm,
} from './consts';
import { getApplicationByID, getApplicationStepReportById } from '../../../../utils/http';
import ProjectTextArea from './ProjectTextArea';
import fileDownload from '../../../../utils/fileDownload';
import ApplicationsPlan from '../Other/ApplicationsPlan';
import PlanFactTable from '../Other/PlanFactTable';
import { planFactFields } from '../Other/planFactFields';
import { getAnnotationFiles } from '../../../../components/AnnotationFiles/AnnotationFiles';


const { TextArea } = Input;
function ProjectBody({
  application, disabled, onSave, onPrint, onUpload, isShowSuccessNotification, onClearSopd, onDeleteProject,
}) {
  const formRef = useRef();
  const [isInit, setIsInit] = useState(true);
  const [isShowOtherState, setIsShowOtherState] = useState(false);
  const [isShowModal, setIsShowModal] = useState(isShowSuccessNotification);
  const [formValues, setFormValues] = useState(application);
  const [financeSum, setFinanceSum] = useState(application.financeSum);

  const isDZM = getUserRole() === 'expertDZM';
  const isAdmin = getUserRole() === 'bigAdmin';

  const DELAY_ML = 3000;

  useEffect(() => {
    setIsShowOtherState(application.isValueOtherText);
  }, []);

  const onCheckBoxChange = (value, field) => {
    if (field === 'isValueOther') {
      setIsShowOtherState(value);
      if (!value) {
        formRef.current.setFieldsValue({ isValueOtherText: '' });
      }
    }
  };

  const fixApplicationsPlan = (values) => {
    const result = {
      values: { ...values, financeSum },
      applicationsPlan: { id: application.applicationsExpertPlan_id, steps: application.applicationsPlan.steps },
    };
    Array(application.applicationsPlan.steps).fill(0).forEach((el, index) => {
      const field1 = `n${index + 1}`;
      const field2 = `d${index + 1}`;
      const field3 = `w${index + 1}`;
      const field4 = `r${index + 1}`;

      result.applicationsPlan[field1] = values[field1];
      result.applicationsPlan[field2] = values[field2];
      result.applicationsPlan[field3] = values[field3];
      result.applicationsPlan[field4] = values[field4];

      delete result.values[field1];
      delete result.values[field2];
      delete result.values[field3];
      delete result.values[field4];
    });

    planFactFields.forEach((f) => {
      let tempText = '';
      Array(application[`${f.name}Fact`] || 1).fill(0).forEach((el, index) => {
        const field = `${f.name}Comment_${index + 1}`;
        tempText += `$${result.values[field] || ''}`;
        delete result.values[field];
      });
      result.values[`${f.name}Comment`] = tempText.substring(1);
      result.values[`${f.name}Text`] = result.values[`${f.name}Comment`].split('$').map((e, i) => `${f.title} ${i + 1}\n${e || ''}`).join('\n\n');
    });

    result.values.projectPatientsText = result.values.projectPatientsComment;

    return result;
  };

  const save = () => {
    const values = formRef.current.getFieldsValue();
    onSave(fixApplicationsPlan(values), false);
  };

  // useEffect(() => {
  //   if (isInit) {
  //     setIsInit(false);
  //   } else {
  //     const autoSaveTimer = setTimeout(save, DELAY_ML);
  //     return () => clearTimeout(autoSaveTimer);
  //   }
  // }, [formValues]);

  const print = () => {
    const values = formRef.current.getFieldsValue();
    const errorFields = validateForm({ ...values, financeSum }, application);
    if (errorFields.length > 0) {
      notification.error({ message: 'Экспертное заключение не может быть распечатано по причине:', description: <div style={{ height: '100vh', overflow: 'scroll' }}>{errorFields.map((field) => <p>{field}</p>)}</div> });
      return;
    }
    onSave(values, true);
    onPrint();
  };

  const addFilesToFolder = async (files, folder) => {
    // eslint-disable-next-line no-unused-vars
    for (const [index, fileObj] of files.entries()) {
      const file = await fetch(fileObj.urlLink)
        .then((r) => r.blob()).then((blobFile) => new File([blobFile], fileObj.name, { type: blobFile.type }));
      folder.file(`${fileObj.name}.pdf`, file, { base64: true });
    }
  };

  const getNirApplicationFiles = async () => {
    const zip = new JSZip();
    const reqs = application.nirApplications.map((el) => getApplicationByID(el.id));
    const nirApps = await Promise.all(reqs);
    const files = [];
    const stepFiles = [];

    for (const nirApp of nirApps) {
      for (const file of nirApp.applicationsFiles_ids) {
        if (file?.letterFile_id?.urlLink) {
          files.push({ urlLink: file?.letterFile_id?.urlLink, name: file?.letterFile_id?.name });
        }
        // if (file?.wordFile_id?.urlLink) {
        //   files.push({ urlLink: file?.wordFile_id?.urlLink, name: file?.wordFile_id?.name });
        // }
        if (file?.pdfFile_id?.urlLink) {
          files.push({ urlLink: file?.pdfFile_id?.urlLink, name: file?.pdfFile_id?.name });
        }
      }

      if (nirApp?.nirFile_id) {
        files.push({ urlLink: nirApp?.nirFile_id?.urlLink, name: nirApp?.nirFile_id?.name });
      }

      if (nirApp.applicationStepReports) {
        const stepsReqs = nirApp.applicationStepReports.map((el) => getApplicationStepReportById(el.id));
        const steps = await Promise.all(stepsReqs);

        for (const step of steps) {
          // Акты
          // if (step?.actReport_id?.urlLink) {
          //   stepFiles.push({ urlLink: step?.actReport_id?.urlLink, name: step?.actReport_id?.name });
          // }

          // Отчеты
          if (step?.nirReport_ids) {
            for (const file of step.nirReport_ids) {
              if (file.name.includes('.docx') || file.name.includes('.doc')) {
                // eslint-disable-next-line no-continue
                continue;
              }
              stepFiles.push({ urlLink: file?.urlLink, name: file?.name });
            }
          }
        }
      }
    }

    await addFilesToFolder([...files, ...stepFiles].filter((e) => !!e), zip);

    zip.generateAsync({ type: 'blob' }).then((content) => {
      saveAs(content, `Файлы заявки №${application.displayID} - соисполнители.zip`);
    });
  };

  const downloadAnnotationFiles = () => {
    const applicationStepReport = application.applicationStepReports.find((e) => Number(e.stepNumber) === application.maxStepsNumber);
    getApplicationStepReportById(applicationStepReport.id)
      .then((asr) => getAnnotationFiles(asr));
  };

  const onDownloadAllFiles = async () => {
    downloadAnnotationFiles();
    const parentZip = new JSZip();
    const files = additionalFiles.map((file) => {
      const urlLink = application[file.field]?.urlLink;
      const { name } = file;
      return { urlLink, name };
    });
    files.push(...application.linkedApplicationFiles.map((e) => e.file_id));
    files.push(...application.applicationsFiles_ids.map((e) => e.file_id));
    files.push(...application.grantAgreementScan_ids);

    if (application.nirApplications) {
      getNirApplicationFiles();
    }

    application.neDZMAdditionalFilesApplications_ids.forEach((e) => {
      if (e?.letterFile_id?.urlLink) {
        files.push({ urlLink: e?.letterFile_id?.urlLink, name: e?.letterFile_id?.name });
      }
      // if (e?.wordFile_id?.urlLink) {
      //   files.push({ urlLink: e?.wordFile_id?.urlLink, name: e?.wordFile_id?.name });
      // }
      if (e?.pdfFile_id?.urlLink) {
        files.push({ urlLink: e?.pdfFile_id?.urlLink, name: e?.pdfFile_id?.name });
      }
    });

    await addFilesToFolder(files.filter((e) => !!e), parentZip);

    parentZip.generateAsync({ type: 'blob' }).then((content) => {
      saveAs(content, `Файлы заявки №${application.displayID}.zip`);
    });
  };

  console.log(application)

  return (
    <div style={{ zIndex: 1 }}>

      <Modal title="Благодарим Вас!" open={isShowModal} footer={[<Button type="primary" onClick={() => setIsShowModal(false)}>Перейти к экспертизе</Button>]} maskClosable={false}>
        <p>
          Информируем, что регламентированный срок формирования экспертного заключения по Проекту -
          <b> 14 календарных дней</b>
        </p>
      </Modal>

      <Form ref={formRef} onValuesChange={(e) => setFormValues(e)}>
        <div className="application-main-wrapper">
          <div className="application-main-box-wrapper" style={{ marginBottom: '20px' }}>
            <div className="application-main-box-mask" />
            <div className="application-main-box-content-wrapper">
              <div style={{ zIndex: 10, display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ fontSize: '20px' }}>Файлы проекта</div>
                <Button type="primary" onClick={onDownloadAllFiles} style={{ marginRight: '10px' }}>Скачать все</Button>
              </div>
              {application && additionalFiles.map((file) => (
                <Row style={{ padding: '10px 0px', width: '100%' }} justify="space-between" key={file.name}>
                  <Col><span style={{ fontSize: '16px' }}>{file.name}</span></Col>
                  <Col>
                    <Row gutter={15}>
                      <Col><Button type="primary" onClick={() => fileDownload(application[file.field]?.urlLink, application[file.field]?.name)} style={{ marginRight: '10px' }}>Скачать</Button></Col>
                    </Row>
                  </Col>
                </Row>
              ))}
              <Row style={{ padding: '10px 0px', width: '100%' }} justify="space-between">
                <Col><span style={{ fontSize: '16px' }}>Приложения к итоговому аннотированному отчету</span></Col>
                <Col>
                  <Row gutter={15}>
                    <Col><Button type="primary" onClick={downloadAnnotationFiles} style={{ marginRight: '10px' }}>Скачать</Button></Col>
                  </Row>
                </Col>
              </Row>

              <Row style={{ padding: '10px 0px', width: '100%' }} justify="space-between">
                <Col><span style={{ fontSize: '16px' }}>Отчеты соисполнителей с приложениями</span></Col>
                <Col>
                  <Row gutter={15}>
                    <Col><Button type="primary" onClick={() => application.linkedApplicationFiles.forEach((e) => fileDownload(e?.file_id?.urlLink, e?.file_id?.name))} style={{ marginRight: '10px' }}>Скачать</Button></Col>
                  </Row>
                </Col>
              </Row>

              <Row style={{ padding: '10px 0px', width: '100%' }} justify="space-between">
                <Col><span style={{ fontSize: '16px' }}>Грантовые соглашения</span></Col>
                <Col>
                  <Row gutter={15}>
                    <Col>
                      <Button
                        type="primary"
                        onClick={() => application.grantAgreementScan_ids.forEach((e) => fileDownload(e?.urlLink, e?.name))}
                        style={{ marginRight: '10px' }}
                      >
                        Скачать
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row style={{ padding: '10px 0px', width: '100%' }} justify="space-between">
                <Col><span style={{ fontSize: '16px' }}>Дополнительные соглашения</span></Col>
                <Col>
                  <Row gutter={15}>
                    <Col>
                      <Button
                        type="primary"
                        onClick={() => application.neDZMAdditionalFilesApplications_ids.forEach((e) => {
                          if (e?.letterFile_id?.urlLink) {
                            fileDownload(e?.letterFile_id?.urlLink, e?.letterFile_id?.name);
                          }
                          if (e?.wordFile_id?.urlLink) {
                            fileDownload(e?.wordFile_id?.urlLink, e?.wordFile_id?.name);
                          }
                          if (e?.pdfFile_id?.urlLink) {
                            fileDownload(e?.pdfFile_id?.urlLink, e?.pdfFile_id?.name);
                          }
                        })}
                        style={{ marginRight: '10px' }}
                      >
                        Скачать
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row style={{ padding: '10px 0px', width: '100%' }} justify="space-between">
                <Col><span style={{ fontSize: '16px' }}>Дополнительные файлы</span></Col>
                <Col>
                  <Row gutter={15}>
                    <Col><Button type="primary" onClick={() => application.applicationsFiles_ids.forEach((e) => fileDownload(e?.file_id?.urlLink, e?.file_id?.name))} style={{ marginRight: '10px' }}>Скачать</Button></Col>
                  </Row>
                </Col>
              </Row>

            </div>
          </div>
        </div>

        <div className="application-main-wrapper">

          <div className="application-main-box-wrapper" style={{ marginBottom: '20px', paddingBottom: '40px' }}>
            <div className="application-main-box-mask" />

            <Form.Item
              name="id"
              initialValue={application.id}
              hidden
            />


            <div className="application-main-box-content-wrapper" style={{ display: isAdmin ? 'flex' : 'none' }}>
              <span className="application-main-box-label">Дата направления материалов Проекта:</span>
              <Form.Item
                name="materialDate"
                initialValue={application.materialDate || moment()}
              >
                <DatePicker disabled={disabled} />
              </Form.Item>
            </div>

            <div className="application-main-box-content-wrapper" style={{ display: isAdmin ? 'flex' : 'none' }}>
              <span className="application-main-box-label">Дата формирования экспертного заключения:</span>
              <Form.Item
                name="conclusionDate"
                initialValue={application.conclusionDate || moment()}
              >
                <DatePicker disabled={disabled} autoSize />
              </Form.Item>
            </div>

            <div className="application-main-box-content-wrapper">
              <span className="application-main-box-label" style={{ fontWeight: 'bold', fontSize: '16px' }}>Информация о научно-практическом Проекте</span>
              <span className="application-main-box-label">Тема Проекта:</span>
              <TextArea disabled autoSize defaultValue={application.theme} />
            </div>

            <div className="application-main-box-content-wrapper">
              <span className="application-main-box-label">Научный руководитель:</span>
              <TextArea disabled autoSize defaultValue={application.scientificDirectorFullName} />
            </div>

            <div className="application-main-box-content-wrapper">
              <span className="application-main-box-label">Количество этапов:</span>
              <TextArea disabled autoSize defaultValue={application.projectTerm} />
            </div>

            <div className="application-main-box-content-wrapper">
              <span className="application-main-box-label" style={{ fontWeight: 'bold', fontSize: '16px' }}>1. Оценка фактического выполнения плана работ в отчетном периоде</span>
            </div>

            <ApplicationsPlan
              loading={disabled}
              visible
              applicationsPlan={Object.assign(application?.applicationsExpertPlan, application.applicationsPlan)}
              steps={application.applicationsPlan.steps}
            />

            <div className="application-main-box-content-wrapper">
              <span className="application-main-box-label" style={{ fontWeight: 'bold' }}>Вывод:</span>
              <Form.Item
                initialValue={application.planResult}
                name="planResult"
                noStyle
              >
                <Radio.Group disabled={disabled}>
                  <Radio value="Состав/содержание выполненных работ и полученные результаты соответствуют установленным Соглашением.">Состав/содержание выполненных работ и полученные результаты соответствуют установленным Соглашением.</Radio>
                  <Radio value="Состав/содержание выполненных работ и полученные результаты не соответствуют установленным Соглашением.">Состав/содержание выполненных работ и полученные результаты не соответствуют установленным Соглашением.</Radio>
                </Radio.Group>
              </Form.Item>
            </div>

            <ProjectTextArea
              title=""
              label="Обоснование оценки"
              fieldName="planReasonDescription"
              placeholder="Минимальный объем - 500 знаков (с пробелами)"
              defaultValue={application.planReasonDescription}
              isShowCounter
              hint=""
            />

            <ProjectTextArea
              title=""
              label="1.2. Оценка влияния возникших в ходе реализации проекта различных объективных обстоятельств на выполнение плана-графика работ по Соглашению."
              fieldName="planInfluence"
              placeholder="Оценивается на основе перечня работ Плана-графика реализации проекта, которые не были выполнены в связи с объективными обстоятельствами"
              defaultValue={application.planInfluence}
              isShowCounter
              hint=""
            />

            <PlanFactTable
              loading={disabled}
              application={application || {}}
            />

            <div className="application-main-box-content-wrapper">
              <span className="application-main-box-label" style={{ fontWeight: 'bold' }}>Выводы:</span>
              <span className="application-main-box-label" style={{ fontWeight: 'bold' }}>Оценка достижения заявленных показателей эффективности реализации проекта в отчетном периоде:</span>
              <Form.Item
                initialValue={application.planFactEvaluation}
                name="planFactEvaluation"
                noStyle
              >
                <Radio.Group disabled={disabled}>
                  <Radio value="Полученные показатели эффективности проекта соответствуют планируемым показателям, указанным в Соглашении, и достигнуты в полном объеме.">Полученные показатели эффективности проекта соответствуют планируемым показателям, указанным в Соглашении, и достигнуты в полном объеме.</Radio>
                  <Radio value="Показатели эффективности реализации проекта, указанные в Соглашении, не достигнуты.">Показатели эффективности реализации проекта, указанные в Соглашении, не достигнуты.</Radio>
                </Radio.Group>
              </Form.Item>
            </div>

            <ProjectTextArea
              title=""
              label="Обоснование оценки"
              fieldName="planFactEvaluationDescription"
              placeholder="Минимальный объем - 500 знаков (с пробелами)"
              defaultValue={application.planFactEvaluationDescription}
              isShowCounter
              hint=""
            />

            <div className="application-main-box-content-wrapper">
              <span className="application-main-box-label" style={{ fontWeight: 'bold', fontSize: '16px' }}>3. Актуальность и значимость Проекта для системы здравоохранения и научно-практическая новизна</span>
            </div>

            <ProjectTextArea
              title=""
              label="3.1. Актуальность результатов Проекта"
              fieldName="relevance"
              placeholder="Минимальный объем - 700 знаков (с пробелами)"
              defaultValue={application.relevance}
              isShowCounter
              hint=""
            />

            <ProjectTextArea
              title=""
              label="3.2. Научная новизна и значимость полученных результатов Проекта для системы здравоохранения"
              fieldName="novelty"
              placeholder="Минимальный объем - 700 знаков (с пробелами)"
              defaultValue={application.novelty}
              isShowCounter
              hint=""
            />

            <div className="application-main-box-content-wrapper">
              <span className="application-main-box-label">3.3. Потенциал практического применения результатов Проекта в сфере здравоохранения и медицины</span>
              <span className="project-hint">
                Можно выбрать несколько вариантов
              </span>
              <Col style={{ border: '1px solid #212121' }}>
                {checkBoxOptions.map((option) => (
                  <Row style={{ borderBottom: '1px solid #212121' }} key={option.name}>
                    <Col className="project-cell-label" style={{ wordBreak: 'keep-all' }}>
                      {option.name}
                    </Col>
                    <Col className="project-cell-checkbox">
                      <Form.Item
                        valuePropName="checked"
                        name={option.field}
                        initialValue={application[option.field]}
                      >
                        <Checkbox style={{ width: 'fit-content' }} onChange={(e) => onCheckBoxChange(e.target.checked, option.field)} />
                      </Form.Item>
                    </Col>
                  </Row>
                ))}
              </Col>
            </div>

            {isShowOtherState && (
            <div className="application-main-box-content-wrapper">
              <Form.Item
                name="isValueOtherText"
                initialValue={application.isValueOtherText}
              >
                <TextArea disabled={disabled} autoSize placeholder="Пожалуйста, введите текст" />
              </Form.Item>
            </div>
            )}

            <ProjectTextArea
              title=""
              label="Аргументация выбранного(-ых) варианта(-ов) практического(-их) потенциала(-ов) внедрения результатов Проекта:"
              fieldName="isValueComment"
              placeholder="Минимальный объем - 500 знаков (с пробелами)
Пример:
Результаты, полученные при реализации научно-практического Проекта, могут служить основой для применения в клинической практике: при проведении профессиональных медицинских обследований и диспансеризации населения в медицинских учреждениях Москвы и Московской области.
Более того, результаты, полученные в рамках Проекта, могут использоваться в силовых структурах РФ для оперативной и точной диагностики на этапе госпитализации, предоставляя дополнительную возможность для уточнения диагноза и подбора наиболее эффективного курса лечения и реабилитации.
Разработанная методика также может применяться в рамках амбулаторного наблюдения за пациентом для мониторинга процесса его социальной и профессиональной адаптации после восстановления, а также прогнозирования риска рецидива заболевания.
Кроме того, страховые компании могут применять результаты Проекта для определения уровня риска заболеваний среди своих клиентов с высокой точностью. Это даст возможность страховщикам адаптировать предлагаемые страховые продукты (тарифные планы, условия) и формировать более подходящие и конкурентоспособные страховые планы для каждого потребителя, что привлечет новых клиентов и позволит оптимизировать затраты на выплату страховых возмещений, учитывая возможность прогнозирования заболеваний среди застрахованного контингента.
"
              defaultValue={application.isValueComment}
              isShowCounter
              hint=""
            />

            <ProjectTextArea
              title=""
              label="3.4. Потенциальное влияние Проекта на существующую клиническую практику в городе Москве и/или в других городах Российской Федерации"
              fieldName="influence"
              placeholder="Минимальный объем - 500 знаков (с пробелами)"
              defaultValue={application.influence}
              isShowCounter
              hint=""
            />

            <ProjectTextArea
              title=""
              label="3.5. Действия, объективно необходимые для внедрения / масштабирования внедрения результатов Проекта в практическую деятельность со стороны грантополучателя и/или Департамента здравоохранения города Москвы"
              fieldName="actions"
              placeholder="Минимальный объем - 100 знаков (с пробелами)
Привести описание дальнейших действий, необходимых для внедрения / масштабирования внедрения результатов работ по Проекту в практическую деятельность.
Пример:
Создание методических рекомендаций по внедрению нового метода диагностики, создание НПА по маршрутизации пациентов, проведение клинических испытаний или иных дополнительных исследований, получение регистрационного удостоверения, разработка стандартов, регламентов, сертификация, обучение персонала, поиск производственных площадок, модернизация инфраструктуры и прочее
"
              defaultValue={application.actions}
              isShowCounter
              hint=""
            />

            <ProjectTextArea
              title=""
              label="3.6. Предполагаемые организации для осуществления внедрения и масштабирования результатов реализации Проекта"
              fieldName="organisations"
              placeholder="Минимальный объем - 100 знаков (с пробелами)"
              defaultValue={application.organisations}
              isShowCounter
              hint=""
            />


            {/* <div className="application-main-box-content-wrapper">
              <span className="application-main-box-label" style={{ fontWeight: 'bold', fontSize: '16px' }}>4. Оценка адекватности финансового обеспечения реализации Проекта</span>
            </div>

            <div className="application-main-box-content-wrapper">
              <span className="application-main-box-label" style={{ fontWeight: 'bold' }}>Выводы:</span>
              <span className="application-main-box-label" style={{ fontWeight: 'bold' }}>Оценка достижения заявленных показателей эффективности реализации проекта в отчетном периоде:</span>
              <Form.Item
                initialValue={application.financeOption}
                name="financeOption"
                noStyle
              >
                <Radio.Group disabled={disabled}>
                  <Radio value="Объём финансового обеспечения проекта полностью соответствует уровню достигнутых результатов реализации проекта">Объём финансового обеспечения проекта полностью соответствует уровню достигнутых результатов реализации проекта</Radio>
                  <Radio value="Объём финансового обеспечения проекта является недостаточным для успешного достижения заявленных результатов реализации проекта">Объём финансового обеспечения проекта является недостаточным для успешного достижения заявленных результатов реализации проекта</Radio>
                  <Radio value="Объём финансового обеспечения проекта явно завышен">
                    Объём финансового обеспечения проекта явно завышен, необходимая сумма оценивается в
                    <Input
                      style={{
                        width: '100px',
                        margin: '0px 5px',
                        border: 'none',
                        borderBottom: '1px solid #d9d9d9',
                        padding: '0',
                      }}
                      defaultValue={financeSum}
                      onChange={(e) => setFinanceSum(e.target.value)}
                    />
                    руб
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </div>

            <ProjectTextArea
              title=""
              label="Обоснование оценки"
              fieldName="financeReason"
              placeholder="Обоснование оценки (оценивается адекватность соответствия объема финансового обеспечения Проекта относительно уровня полученных результатам в рамках реализации Проекта, масштабности проводимых работ, перспектив масштабирования и внедрения в реальный сектор экономики)"
              defaultValue={application.financeReason}
              isShowCounter={false}
              hint=""
            /> */}

            <div className="application-main-box-content-wrapper">
              <span className="application-main-box-label" style={{ fontWeight: 'bold', fontSize: '16px' }}>4. Оценка успешности реализации проекта</span>
              <Col style={{ marginBottom: 20 }}>
                <Form.Item
                  initialValue={application.totalResultText}
                  name="totalResultText"
                  noStyle
                >
                  <Radio.Group disabled={disabled}>
                    <Row style={{ border: '1px solid #212121' }}>
                      {radioOptions.map((radio) => (
                        <Col
                          key={radio.value}
                          className="project-cell-radio"
                        >
                          <Radio value={radio.value}>{radio.value}</Radio>
                        </Col>
                      ))}
                    </Row>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </div>

            <Row
              gutter={15}
            >
              <Col>
                <Button onClick={save} type="primary" style={{ backgroundColor: '#237804', borderColor: '#237804' }}>
                  Сохранить
                </Button>
              </Col>
              <Col>
                <Button onClick={print} icon={<PrinterOutlined />} style={{ backgroundColor: '#e3a134', color: '#fff' }}>
                  Распечатать экспертное заключение
                </Button>
              </Col>
              <Col>
                <Button type="primary" onClick={onUpload}>
                  Загрузить подписанную скан-копию
                </Button>
              </Col>
              {isAdmin && (
              <Col>
                <Popconfirm
                  title="Вы уверены, что хотите удалить откатить согласие?"
                  onConfirm={onClearSopd}
                  okText="Да"
                  cancelText="Нет"
                >
                  <Button type="danger">
                    Откатить согласие
                  </Button>
                </Popconfirm>
              </Col>
              )}
              {isAdmin && (
              <Col>
                <Popconfirm
                  title="Вы уверены, что хотите удалить проект?"
                  onConfirm={onDeleteProject}
                  okText="Да"
                  cancelText="Нет"
                >
                  <Button type="danger">
                    Удалить проект
                  </Button>
                </Popconfirm>
              </Col>
              )}
            </Row>
          </div>
        </div>
      </Form>
    </div>
  );
}

ProjectBody.propTypes = {
  application: PropTypes.shape().isRequired,
  disabled: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onPrint: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
  isShowSuccessNotification: PropTypes.bool.isRequired,
  onClearSopd: PropTypes.func.isRequired,
  onDeleteProject: PropTypes.func.isRequired,
};

export default ProjectBody;
